import {Component, Input, OnInit} from '@angular/core';
import {ItemDetalhado} from 'src/app/persistences/item-detalhado';
import {ItemService} from 'src/app/services/item.service';
import {JwtService} from 'src/app/jwt.service';

@Component({
  selector: 'app-item-carrinho',
  templateUrl: './item-carrinho.component.html',
  styleUrls: ['./item-carrinho.component.css']
})
export class ItemCarrinhoComponent implements OnInit {

	@Input()
	public itemDetalhado: ItemDetalhado;
	public imagem = "";

	constructor(
		private itemService: ItemService,
		private jwtService: JwtService,
	) {
	}

	ngOnInit() {
		if(this.itemService.idsItensNoCarrinho.includes(this.itemDetalhado.itemDetalhes.idItem)){
			return
		} else {
			this.itemService.idsItensNoCarrinho.push(this.itemDetalhado.itemDetalhes.idItem)
			this.itemService.getImagem(this.itemDetalhado.itemDetalhes.idItem, true)
			.subscribe((imagem: string) => {
				if (imagem == "") {
					this.imagem = "";
				}
				else {
					this.imagem = "data:image/jpeg;base64," + imagem;
				}
			},
			(error) => this.jwtService.validarAcessToken(error));
		}
		
	}
}
