import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtService } from '../jwt.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TitulosService {

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private jwtService: JwtService
  ) { }

  getTitulos(){
    this.jwtService.validarAcessToken();
    return this.httpClient.get(
      this.configService.getEndereco() + '/titulos/todos',
      {
        headers: this.configService.getHeaders()
      }
    ) 
  }

  getBoleto(id: number){
    this.jwtService.validarAcessToken();
    return this.httpClient.get(
      this.configService.getEndereco() + '/titulos/getBoletos',
      {
        headers: this.configService.getHeaders(),
        params: new HttpParams().set('id', id)
      }
    ) 
  }

  getTitulosComFiltro(tipo: string,dataInicial: string, dataFinal: string, credito: boolean){

		this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
		httpParams = httpParams
			.append('ini', dataInicial)
			.append('fin', dataFinal)
      .append('tipo', tipo)
      .append('credito', credito)
		return this.httpClient.get(
			this.configService.getEndereco() + '/titulos/intervalo',
			{ headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
	}
}
