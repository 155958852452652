import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemDetalhes } from 'src/app/persistences/item-detalhes';
import { ItemPedido } from 'src/app/persistences/item-pedido';
import { PedidoVenda } from 'src/app/persistences/pedido-venda';
import { ItemService } from 'src/app/services/item.service';
import { PedidoService } from 'src/app/services/pedido.service';
import { ConfigService } from '../services/config.service';
import { CartSingletonService } from '../singletons/cart-singleton.service';
import { NumberUtils } from '../utils/utils/number-utils';
import { PesquisaService } from './../services/pesquisa.service';
import { ClienteManager } from './cliente-manager';
import { ConverterCarrinhoManager } from './converter-carrinho.manager';
import { ItemManager } from './item-manager';
import { Carrinho } from '../persistences/carrinho';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';
import { VendaPerdida } from '../persistences/venda-perdida';
import { FormaCondPgto } from '../persistences/forma-cond-pgto';
import { JwtService } from './../jwt.service';
import { FormaCondService } from '../services/forma-cond.service';

@Injectable({
  providedIn: 'root',
})
export class PedidoManager {
  public pedidoVenda: PedidoVenda;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  private desabilitaControlesSubject = new BehaviorSubject<boolean>(true);
  desabilitaControles = this.desabilitaControlesSubject.asObservable();

  constructor(
    private router: Router,
    private cartSingletonService: CartSingletonService,
    private clienteManager: ClienteManager,
    private clienteSingleton: ClienteSingletonService,
    private pedidoService: PedidoService,
    private itemService: ItemService,
    private snackBarManager: SnackbarManager,
    private itemManager: ItemManager,
    private converterCarrinhoManager: ConverterCarrinhoManager,
    private configService: ConfigService,
    private pesquisaService: PesquisaService,
    private clienteSingletonService: ClienteSingletonService,
    private jwtService: JwtService,
    private formaCondService: FormaCondService
  ) { }

  promiseBuscarPedidoEdicao(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.pedidoService
          .pedidoVendaPendenteBusiness(
            this.clienteSingletonService.getClienteEstatico().getCdCliente
          )
          .subscribe((response) => {
            /* PROVISORIO REVER SE NAO DEIXAMOS ISSO DENTRO DO CART-SINGLETON*/
            this.cartSingletonService.atualizarPedido(response);
            resolve(true);
          });
      } catch (error) {
        this.snackBarManager.openSnackBar("Erro ao recuperar pedido para edição. " + error.error, "Ok", 5000);
        reject(false);
      }
    });
  }


  removerItemCarrinhoPedido(item: ItemDetalhado): Promise<boolean> { 
    return new Promise((resolve, reject) => {
      try {
        if(this.configService.getConfig().getCdMotivo.trim() != ''){
          const vendaPerdida = new VendaPerdida;
          const cliente = this.clienteSingleton.getClienteEstatico();
          vendaPerdida.idPedidoVenda = this.cartSingletonService.getPedidoVenda().getIdGeral;
          vendaPerdida.cdCliente =cliente.getCdCliente;
          vendaPerdida.cdFilial = cliente.getClienteFilial.getCdFilial;
          vendaPerdida.cdMotivo = parseInt(this.configService.getConfig().getCdMotivo);
          vendaPerdida.cdUsuario = null;
          vendaPerdida.dtLancamento = new Date().toLocaleDateString('pt-BR');
          vendaPerdida.flOrigemCorte = 'A';
          vendaPerdida.idItem = item.itemDetalhes.idItem;
          vendaPerdida.idItemSubstituido = null;
          vendaPerdida.idTabelaPrecoProduto = item.itemPedido.idTabelaPreco;
          vendaPerdida.nmMotivo = null;
          vendaPerdida.qtItem = item.itemPedido.quantidade;
          vendaPerdida.unMedida = item.itemPedido.unMedVenda;
          vendaPerdida.vlVenda = item.itemPedido.total;
          this.pedidoService.gravarVendaPerdida(vendaPerdida).subscribe()
        }
        item.itemPedido.quantidade=0;
        this.itemManager.getValor(item);  
        this.cartSingletonService.removerItemCarrinho(item);        
        this.atualizarPedidoEdicao().then(() => {
            resolve(true)
        });
        this.itemService.idsItensNoCarrinho = [];
        const itens = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho;
        itens.forEach(p =>{
            this.itemService.idsItensNoCarrinho.push(p.itemDetalhes.idItem)
        })
      } catch (error) {
        this.snackBarManager.openSnackBar("Erro ao Remover Item do Pedido. " + error.error, "Ok", 5000);
        reject(false);
      }
    });  
  }

  removerQuantidadeItemCarrinho(item: ItemDetalhado): Promise<boolean> { 
    return new Promise((resolve, reject) => {
      try {
        this.itemManager.getValor(item);  
        this.cartSingletonService.removerItemCarrinho(item);        
        this.atualizarPedidoEdicao().then(() => {
            resolve(true)
        });
        this.itemService.idsItensNoCarrinho = [];
        const itens = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho;
        itens.forEach(p =>{
            this.itemService.idsItensNoCarrinho.push(p.itemDetalhes.idItem)
        })
      } catch (error) {
        this.snackBarManager.openSnackBar("Erro ao Remover Item do Pedido. " + error.error, "Ok", 5000);
        reject(false);
      }
    });  
  }

  gravarItemCarrinhoPedido(item: ItemDetalhado): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        /*if (this.cartSingletonService.itemEstaNoCarrinho(item)) {           
          NAO ENTENDI O MOTIVO DESSE IF AQUI, PRECISO REVER /\, POIS SO VAI ADICIONAR ITENS QUE JA ESTAO NO CARRINHO, 
          SENDO QUE ESTAMOS MIGRANDO PRA ESSE METODO SER O CENTRALIZADOR PARA ADICIONAR NO CARRINHO E NA WEB.
        }  */                
        if (!this.validarQuantidadeItem(item)) {
          reject(false);
        }
        this.itemManager.atualizarQuantidadeItem(item);
        this.itemManager.recalculaItem(item);  
        if(item.itemNovo)      
          this.itemManager.calcularVlPrecoTabelaAntigo(item);
        this.cartSingletonService.addItemCarrinho(item);        
        this.atualizarPedidoEdicao().then(() => {
            resolve(true)
        });     
      } catch (error) {
        this.snackBarManager.openSnackBar("Erro ao Gravar Item ao Pedido. " + error.error, "Ok", 5000);
        reject(false);
      }
    });  
  }

  validarQuantidadeItem(item: ItemDetalhado): boolean {
    const minimo = this.itemManager.getMinimo(item);    
    if (item.itemPedido.quantidade > 0 && item.itemPedido.quantidade < minimo.minimoControle) {
      this.snackBarManager.openSnackBar(
        'Quantidade minima de venda para este item é: ' + 
        minimo.minimoControle.toString(),
        'Ok',
        3000
      );   
      return false;
    }

    if (!this.itemManager.ignorarValidacaoDeMultiplo(item)) {
      if (this.itemManager.calcularResto(item) !== 0) {
        this.snackBarManager.openSnackBar(
          'Use apenas valores multiplos de: ' + this.itemManager.getMultiplo(item).toString(),
          'Ok',
          3000
        );
        return false;
      }
    }

    if (item.itemPedido.quantidade > item.itemDetalhes.qtDisponivel) {
      this.snackBarManager.openSnackBar(
        'Quantidade máxima: ' + (item.itemDetalhes.qtDisponivel),
        'Ok',
        3000
      );
      return false;
    } 

    return true;
  }

  carregarInformacaoPedidoEdicao() {
    const pedidoVenda: PedidoVenda = this.cartSingletonService.getPedidoVenda();
    if (pedidoVenda.getItens.length == 0) {
      this.router.navigateByUrl('/home');
      return;
    }       

    const idsItensDoCarrinho = pedidoVenda.getItens.map(item => item.getIdItem);
    const itensPedido = new Map<number, ItemPedido>();
    for (const item of pedidoVenda.getItens) {
      const itemPedido = new ItemPedido();
      itemPedido.idItem = item.getIdItem;
      itemPedido.quantidade = item.getQtVenda;
      itemPedido.quantidadeEstoque = item.getQuantidadeEstoque;
      itemPedido.preco = NumberUtils.roundTo(item.getPreco, 2);
      itemPedido.idTabelaPreco = item.getIdTabelaPrecoProduto;
      itemPedido.vlPrecoTabelaAntigo = NumberUtils.roundTo(item.getVlBruto *
        (1 + this.clienteSingleton.getClienteEstatico().getPcMarkup / 100) *
        (1 - item.getPcDesconto / 100),
        2 ); 
      itemPedido.vlPrecoTabelaNovo = NumberUtils.roundTo(itemPedido.preco, 2);
      itemPedido.vlIpi =
        item.getIpiValor != undefined ? NumberUtils.roundTo(item.getIpiValor, 2) : 0;
      itemPedido.vlIcmsSt =
        item.getIcmsSubstValor != undefined ? item.getIcmsSubstValor : 0;
      itemPedido.total = NumberUtils.roundTo(item.getVlLiquido, 2);
      itemPedido.flPromocao = item.getFlPromocao == 'S' ? true : false;
      itemPedido.valorLiquido = NumberUtils.roundTo(item.getVlLiquido, 2);
      const valorLiquidoIpiSt: number =
        itemPedido.vlIpi + itemPedido.vlIcmsSt + item.getVlLiquido;
      itemPedido.valorLiquidoIpiSt = NumberUtils.roundTo(valorLiquidoIpiSt, 2);
      itemPedido.pcDesconto = item.getPcDesconto;
      itemPedido.pcMarkup = pedidoVenda.getPcMarkupEcommerce == undefined
          ? this.clienteSingleton.getClienteEstatico().getPcMarkup
          : 0;
      itemPedido.unMedVenda = item.getUnVenda;
      itensPedido.set(itemPedido.idItem, itemPedido);
    }
    
    this.pesquisaService
      .carregaItensByIds(idsItensDoCarrinho)
      .subscribe((retornoItems) => {
        retornoItems.forEach(itemRetornado => {
          const itemDetalhado = new ItemDetalhado();
          itemDetalhado.itemPedido = itensPedido.get(itemRetornado.idItem);
          itemDetalhado.itemDetalhes = this.itemManager.converterItemParaItemDetalhes(itemRetornado);
          this.itemManager.getValor(itemDetalhado);
          this.promiseAdicionarPedidoEdicaoNoCarrinho(itemDetalhado).then(
            () => {
              this.router.navigateByUrl('/home');
            },
          ).catch((error) => {
            this.router.navigateByUrl('/home');
            this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
          });
        })
      });
  }

  promiseAdicionarPedidoEdicaoNoCarrinho(
    itemDetalhado: ItemDetalhado
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.cartSingletonService.addItemCarrinho(itemDetalhado);
        resolve(true);
      } catch (error) {
        this.snackBarManager.openSnackBar("Erro ao adicionar item ao carrinho. " + error.error, "Ok", 5000);
        reject(false);
      }
    });
  }

  promiseGetItemDetalhado(itemPedido: ItemPedido): Promise<ItemDetalhado> {
    return new Promise((resolve, reject) => {
      try {
        this.itemService
          .getItemDetalhes(itemPedido.idItem)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: ItemDetalhes) => {
            const itemDetalhado = new ItemDetalhado();
            itemDetalhado.itemDetalhes = res;
            itemDetalhado.itemPedido = itemPedido;
            resolve(itemDetalhado);
          });
      } catch (error) {
        reject('Erro ao recuperar informações do produto.' + error.error);
      }
    });
  }

  promiseNovoPedidoVenda(): Promise<boolean> {
    const pedido = this.converterCarrinhoManager.converterCarrinhoParaPedido(false, '', false);
    pedido.cdSituacao = 9;
    pedido.cdTpEntrega = this.getTipoEntregaPadrao();
    return new Promise((resolve, reject) => {
      try {
        this.pedidoService.novoPedidoVenda(pedido).subscribe((response) => {          
          this.cartSingletonService.atualizarPedido(response);
          resolve(true);
        },
          (error) => {
            this.snackBarManager.openSnackBar("Erro ao criar um novo pedido de venda. " + error.error, "Ok", 5000);
            reject(false);
          });
      } catch (error) {
        this.snackBarManager.openSnackBar("Erro ao criar um novo pedido de venda. " + error.error, "Ok", 5000);
        reject(false);
      }
    });
  }

  atualizarPedidoEdicao(): Promise<boolean> {
    return new Promise((resolve) => {
      this.desabilitaControlesSubject.next(false);
      const carrinho: Carrinho = this.cartSingletonService.getCarrinhoEstatico();
      const pedidoVenda: PedidoVenda = this.cartSingletonService.getPedidoVenda();
      const pedido = this.converterCarrinhoManager.converterCarrinhoParaPedido(false, carrinho.getObs, false);
      pedido.cdSituacao = 9;          

      pedido.cdCondPgto === undefined ? pedido.cdCondPgto = carrinho.getCdCondPgto : pedido.cdCondPgto;
      pedido.cdFormaPgto === undefined ? pedido.cdFormaPgto = carrinho.getCdFormaPgto : pedido.cdFormaPgto;
      pedido.cdTpEntrega === undefined ? pedido.cdTpEntrega = carrinho.getCdTpEntrega : pedido.cdTpEntrega;
      pedido.ObservacoesInterna === undefined ? pedido.ObservacoesInterna = carrinho.getObs : pedido.ObservacoesInterna;

      this.pedidoService.gravarPedido(pedido).subscribe((res) => {
        if (res['success'] == true) {
          let idGeral = pedidoVenda.getIdGeral;
          let versaoRegistro = pedidoVenda.getVersaoRegistro;
          if (
            idGeral == undefined ||
            idGeral == 0
          ) {
            idGeral = res['idPedido'];
          }
          if (
            versaoRegistro == undefined ||
            versaoRegistro <
            res['versaoRegistro']
          ) {
            versaoRegistro = res['versaoRegistro'];
          }
          this.desabilitaControlesSubject.next(true);
          this.cartSingletonService.atualizarControlePedido(idGeral, versaoRegistro);
        }
        else {
          this.snackBarManager.openSnackBar(res['mensagem'], 'Ok', 3000);
          this.clienteManager.getClientefromServico();
        }        
        resolve(true);
      },
      (error) => {
        this.clienteManager.getClientefromServico();
        if(error.error !== "O pedido não está mais disponível!"){
          this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
        }
      });
    });
  }

  getTipoEntregaPadrao(): number{
    const cliente = this.clienteSingleton.getClienteEstatico();
    const carrinho = this.cartSingletonService.getCarrinhoEstatico();
    const cdTpEntrega = cliente.getCdTipoEntrega != null ?
      cliente.getCdTipoEntrega : this.configService.getConfig().getCdTpEntrega;
    this.cartSingletonService.atualizarDadosCabecalho(carrinho.getCdFormaPgto, carrinho.getCdCondPgto, cdTpEntrega, carrinho.getObs);
    return cdTpEntrega;
  }  

  pedidoIndisponivel(){
    this.desabilitaControlesSubject.next(true);
    this.promiseBuscarPedidoEdicao()
      .then(() => {
          this.pedidoVenda = this.cartSingletonService.getPedidoVenda();
          if (this.pedidoVenda.getIdGeral != undefined) {
            let obs = '';

            if(this.pedidoVenda.getObservacoesInterna != undefined && this.pedidoVenda.getObservacoesInterna.trim() != ''){
                obs = this.pedidoVenda.getObservacoesInterna
            } 
            this.cartSingletonService.atualizarDadosCabecalho(this.pedidoVenda.getCdFormaPgto, this.pedidoVenda.getCdCondPgto, this.pedidoVenda.getCdTpEntrega, obs);
            this.carregarInformacaoPedidoEdicao();
          } else { 
            const cliente  = this.clienteSingletonService.getClienteEstatico();  
            this.cartSingletonService.novoCarrinho(cliente.getCdFormaPadrao, cliente.getCdCondPadrao, cliente.getCdTipoEntrega,  '');
            this.formaCondService.getFormaCondPgto(cliente.getCdCliente)
            .subscribe((response) => {
              if(response.length == 0){
                this.jwtService.logout();
                this.snackBarManager.openSnackBar("O usuário não possui perfil comercial com forma e condição de pagamento","Ok", 5000);
                return
              }
              const formasAgrupadas: FormaCondPgto[] = [];
              response.forEach( forma => {
                const cdForma = forma.cdFormaPgto;
                if(!formasAgrupadas.some(item => item.cdFormaPgto === cdForma)){
                  formasAgrupadas.push(forma)
                } else{
                  forma.condicoes.forEach(cond => {
                      const formaAtual = formasAgrupadas.find(forma => forma.cdFormaPgto === cdForma)
                      if(!formaAtual.condicoes.some(item => item.cdCondPgto === cond.cdCondPgto)){
                          formasAgrupadas.forEach(forma => {
                              if(forma.cdFormaPgto === cdForma){
                                  forma.condicoes.push(cond);
                              }
                          })
                      }
                  })
                }
              })
              this.cartSingletonService.setFormaECondicaoDePagamento(formasAgrupadas);                                        
              this.clienteSingletonService.atualizaFormaCodPadrao(formasAgrupadas);                                   
              this.promiseNovoPedidoVenda().then(
                () => {
                  this.router.navigateByUrl('/minha-conta/meus-pedidos');
                })
                .catch((error) => {
                    this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
                });
            })
          }                                 
    })
  }
}
