import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JwtService } from 'src/app/jwt.service';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { Cliente } from 'src/app/persistences/cliente';
import { PcMarkupEcommerce } from 'src/app/persistences/pc-markup-ecommerce';
import { ConfigService } from 'src/app/services/config.service';
import { ConfiguracaoContaService } from 'src/app/services/configuracao-conta.service';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';

@Component({
  selector: 'app-perfil-cliente',
  templateUrl: './perfil-cliente.component.html',
  styleUrls: ['./perfil-cliente.component.css']
})
export class PerfilClienteComponent implements OnInit {

  formGroup: FormGroup;
  public temaPadrao: string;
  public temAcesso = parseInt(localStorage.getItem('tem_acesso_business')) == 0 ? false : true;


  constructor(
    private formBuilder: FormBuilder,
    private snackBarManager: SnackbarManager,
    public clienteSingleton: ClienteSingletonService,
    public configService: ConfigService,
    public configConta: ConfiguracaoContaService,
    private jwtService: JwtService,
  ) {
    this.formGroup = this.formBuilder.group({
            nmCliente: [null],
            cpfCnpj: [null],
            nmFantasia: [null],
            markupCliente: [null]
    });
  }

  private carregarDadosCliente() {
    const cliente: Cliente = this.clienteSingleton.getClienteEstatico();
    this.formGroup.patchValue({
      nmCliente: cliente.getNmCliente,
      cpfCnpj: cliente.getCnpjCpf, 
      nmFantasia: cliente.getNmFantasia,
      markupCliente: cliente.getPcMarkup 
    });
  }

  ngOnInit() {
    this.carregarDadosCliente();
    this.temaPadrao = this.configService.getConfig().getTemaPadrao;
  }

  atualizarDadosDoCliente() {
    const pcMarkupEcommerce: PcMarkupEcommerce = new PcMarkupEcommerce();
    pcMarkupEcommerce.pcMarkupEcommerce = this.formGroup.get('markupCliente')?.value;
    this.clienteSingleton.atualizarPcMarkup(pcMarkupEcommerce.pcMarkupEcommerce);
    this.configConta.pcMarkup(pcMarkupEcommerce).subscribe(result => {
      this.snackBarManager.openSnackBar(result["status"]["mensagem"], "Ok", 3500);
    },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
      }
    );
  }

}

