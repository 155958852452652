<div class="grid-container">
  <div class="carrossel grid-item-1" *ngIf="ocultaAbas">
    <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="carregarPainelSelecionado($event)">
      <mat-tab [label]="painel.tituloAba" *ngFor="let painel of paineis"></mat-tab>
    </mat-tab-group>
  </div>
  <div class="ml-auto grid-item-2" >
    <app-controlbar *ngIf="isPesquisaRealizada"
    (ordenacaoEmitter)="emitirOrdem($event)" 
    (abrirFiltroEmitter)="emitirFiltro(!$event)"
    [ordenacaoInput]="ordenacaoInput">
    </app-controlbar>
  </div>
</div>

<!-- <div class="container">
  <div *ngIf="ocultaAbas">
    <mat-tab-group (selectedTabChange)="carregarPainelSelecionado($event)">
      <mat-tab [label]="painel.tituloAba" *ngFor="let painel of paineis"></mat-tab>
    </mat-tab-group>
  </div>
  <div class="ml-auto" >
    <app-controlbar *ngIf="isPesquisaRealizada"
      (ordenacaoEmitter)="emitirOrdem($event)" 
      (abrirFiltroEmitter)="emitirFiltro(!$event)"
      [ordenacaoInput]="ordenacaoInput">
    </app-controlbar>
  </div>
</div> -->