import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {JwtService} from '../jwt.service';
import {PedidoFlex} from '../persistences/pedido-flex';
import { PedidoSimplificado, PedidoSimplificadoFiltros } from '../persistences/pedido-simplificado';
import {PedidoVenda} from '../persistences/pedido-venda';
import {TipoEntrega} from '../persistences/tipo-entrega';
import {ConfigService} from './config.service';
import { VendaPerdida } from '../persistences/venda-perdida';


@Injectable({
  providedIn: 'root'
})
export class PedidoService {

	constructor(
		private configService: ConfigService,
		private httpClient: HttpClient,
		private jwtService: JwtService
	) { }


	condicaoPagamento(cdCliente: number){

		this.jwtService.validarAcessToken();

		let httpParams = new HttpParams();
		httpParams = httpParams.append('cdCliente', cdCliente.toString());
		return this.httpClient.get(
			this.configService.getEndereco() + '/FormaCondPgto/getByCdCliente',
			{ headers: this.configService.getHeaders(),
			params: httpParams
			}
		)
	}


	getByCliente(cdCliente: string): Observable<PedidoVenda[]>{

		this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
		httpParams = httpParams.append('cdCliente', cdCliente).append('emAberto', 'true').append('origem', 'E');
		return this.httpClient.get<PedidoVenda[]>(
			this.configService.getEndereco() + '/PedidoVenda/pedidosByCliente',
			{ headers: this.configService.getHeaders(),
			params: httpParams
			}
		)
	}

	getPedidosVendaSimplificadoByCliente(filtros: PedidoSimplificadoFiltros): Observable<PedidoSimplificado[]>{

		this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
		httpParams = httpParams
			.append('cdCliente', filtros.cdCliente)
			.append('emAberto', filtros.emAberto)
			.append('origem', filtros.origem)
			.append('dtInicial', filtros.dtInicial)
			.append('dtFinal', filtros.dtFinal)
			.append('situacao', filtros.situacao);
		return this.httpClient.get<PedidoSimplificado[]>(
			this.configService.getEndereco() + '/pedidoVenda/simplificado/getByCliente',
			{ headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
	}

	getTipoEntrega(): Observable<TipoEntrega[]>{

		this.jwtService.validarAcessToken();

		const header = new HttpHeaders({
			'authToken': localStorage.getItem('access_token_business')
		});

		return this.httpClient.get<TipoEntrega[]>(
			this.configService.getEndereco() + '/TipoEntrega/getAll',
			{ headers: header }
		)
	}

	gravarPedido (pedido: PedidoFlex) {

		this.jwtService.validarAcessToken();

		return this.httpClient.post(
			this.configService.getEndereco() + '/PedidoVendaBusiness/gravarBusiness',
			JSON.stringify(pedido),
			{ headers: this.configService.getHeaders() }
		)
	}

	pedidoVendaPendenteBusiness(cdCliente: number): Observable<PedidoVenda>{

		this.jwtService.validarAcessToken();

		let httpParams = new HttpParams();
		httpParams = httpParams.append('cdCliente', cdCliente.toString());
		return this.httpClient.get<PedidoVenda>(
			this.configService.getEndereco() + '/PedidoVenda/pedidoVendaPendenteBusiness',
			{ headers: this.configService.getHeaders(),
			params: httpParams
			}
		)
	}

    novoPedidoVenda (pedido: PedidoFlex): Observable<PedidoVenda>{

		this.jwtService.validarAcessToken();

		return this.httpClient.post<PedidoVenda>(
			this.configService.getEndereco() + '/PedidoVendaBusiness/novoPedidoVenda',
			JSON.stringify(pedido),
			{ headers: this.configService.getHeaders() }
		)
	}

	getPedidoVendaByIdPedido(IdPedido: number): Observable<PedidoVenda>{
		let httpParams = new HttpParams();
		httpParams = httpParams.append('IdPedido', IdPedido);
		return this.httpClient.get<PedidoVenda>(
			this.configService.getEndereco() + '/PedidoVenda/pedidoById',
			{ headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
	}

	gravarVendaPerdida(vendaPerdida: VendaPerdida): Observable<string>{
		const retorno = this.httpClient.post<string>(
			this.configService.getEndereco() + '/VendaPerdida/gravarVendaPerdida',
			JSON.stringify(vendaPerdida),
			{ 
				headers: this.configService.getHeaders(), 
				responseType:'text'  as 'json'
			}
		)
		return retorno;
	}

	getTiposEntregaCarrinho(): Observable<any>{
		let httpParams = new HttpParams();
		httpParams = httpParams.append('chave', "TIPOS_ENTREGA_BUSINESS_CARRINHO");
		return this.httpClient.get<any>(this.configService.getEndereco()+"/business/config/getById",{
			headers: this.configService.getHeaders(),
			params: httpParams
		});
	}

}
