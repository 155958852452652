<div>
  <form [formGroup]="formConsulta">
    <div class="form-row">
       <mat-form-field class="mat-form-field">
        <mat-label>{{labelDaDescricao}}</mat-label>
        <input matInput formControlName="descricao" type="text" (keyup)="onSearch($event.target.value)" onFocus="this.select()"
          [(ngModel)]="descricaoItem" required="{{campoRequerido}}" [matAutocomplete]="auto"/>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option class="mat-options" *ngFor="let result of this.consultaCustomizada.resultados; let i=index" 
            (click)="selecionarItem(result)">
              {{ result.descricao }}
            </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</div>