import { Component, HostListener, OnInit } from '@angular/core';
import { JwtService } from 'src/app/jwt.service';

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.css']
})
export class MinhaContaComponent implements OnInit {
    
  isMobileMenu = false;
  pesquisaVisivel = false;

  constructor(
    private jwtService: JwtService,    
  ) {
    this.jwtService.checkLogin();
    this.configurarLayout();    
  }

  ngOnInit() {
    this.pesquisaVisivel = false;
    window.addEventListener('visibilitychange', () => {
      this.isMobileMenu = !this.isMobileMenu;  
    });
  }

  configurarLayout() {
    this.isMobileMenu = window.innerWidth <= 768;
  }

  @HostListener('window:resize', ['$event'])
  onResize(){
    this.configurarLayout();
	}

}
