export class Painel {
    public idGeral?: number;
	public cdFilial: number;
	public cdUsuario: number;
	public dataFim: string;
	public dataFimDate:Date;
	public dataInicio: string;
	public dataInicioDate: Date;
	public cdAcesso?: string;
	public ordem: number;
	public tituloAba: string;
	
	constructor(init?: Partial<Painel>) {
        Object.assign(this, init);
    }
}

