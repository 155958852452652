	<div class="alinhar-itens">
		<mat-spinner class="spinner-align" mode="indeterminate"
			*ngIf="!estadosDaPesquisa.registrosEncontrados && !estadosDaPesquisa.pesquisaPronta">
		</mat-spinner>
	</div>
  <div *ngIf="estadosDaPesquisa.registrosEncontrados && estadosDaPesquisa.pesquisaPronta">
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav class="sidenav" [mode]="sideMenuMode" [(opened)]="filtroLateralAberto">
        <app-filtro-lateral [listaMarcados]="listaMarcados" [listaIdsOriginais]="listaIdsOriginais"
          (idsFiltradosEmitter)="aplicarRemoverFiltros($event)"></app-filtro-lateral>
      </mat-sidenav>
      <mat-sidenav-content class="sidenav-content"> 
        <div *ngIf="getLayoutEmUso() === 2 && estadosDaPesquisa.pesquisaPronta">
          <div>
            <app-card-lista-itens [listaItens]="listaItens" [ids]="idsPesquisados" [page]="page" [pagesize]="pageSize">
            </app-card-lista-itens>
          </div>
        </div>
        <div *ngIf="getLayoutEmUso() === 1 && estadosDaPesquisa.pesquisaPronta">
          <div>
            <app-lista-itens [listaItens]="listaItens" [ids]="idsPesquisados" [page]="page" [pagesize]="pageSize">
            </app-lista-itens>
          </div>
        </div>
        <div *ngIf="pagination && estadosDaPesquisa.registrosEncontrados && estadosDaPesquisa.pesquisaPronta">
          <div class="paginacao-align">
            <pagination-controls (pageChange)="onEnd($event)" previousLabel="" nextLabel=""></pagination-controls>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>