import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { JwtService } from 'src/app/jwt.service';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ConfigService } from 'src/app/services/config.service';
import { TitulosService } from 'src/app/services/titulos.service';

@Component({
  selector: 'app-lista-titulos',
  templateUrl: './lista-titulos.component.html',
  styleUrls: ['./lista-titulos.component.css']
})
export class ListaTitulosComponent {

  isPesquisando = true;
  listaTitulos: any;
  isCredito = false;
  formPesquisa: FormGroup;
  tipos = [{ label: 'Emissão', value: 'E' }, { label: 'Vencimento', value: 'V' }];
  tipoSelecionado = 'E';
  constructor(
    private snackBarManager: SnackbarManager,
    private configService: ConfigService,
    private jwtService: JwtService,
    private titulosService: TitulosService
  ) {  }

  ngOnInit(): void {
    this.formPesquisa = new FormGroup({
      tipo: new FormControl(''),
      dataInicial: new FormControl(new Date(new Date().setMonth(new Date().getMonth() - 1)), Validators.required),
      dataFinal: new FormControl(new Date(), Validators.required),
      isCredito: new FormControl(false)
    });
    this.getTitulosComFiltro();
  }
  
  getBoleto(id:number){
    this.titulosService.getBoleto(id).subscribe(res =>{
      const link = this.configService.getEndereco() + "/" + res['link'];
      window.open(link);
    },
    (error) => {
      this.jwtService.validarAcessToken(error);
      this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
    }
    );
  }

  getTitulosComFiltro(){
    this.isPesquisando = true;
    this.titulosService.getTitulosComFiltro(this.formPesquisa.get('tipo').value ,new Date(this.formPesquisa.get('dataInicial').value).toLocaleDateString('pt-BR'),
    new Date(this.formPesquisa.get('dataFinal').value).toLocaleDateString('pt-BR'), this.formPesquisa.get('isCredito').value).subscribe(res =>{
     this.listaTitulos = res;
     this.isPesquisando = false;
    },
    (error) => {
     this.jwtService.validarAcessToken(error);
     this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
     this.isPesquisando = false;
    });
  }

  calculaStatus(saldo: number, vcto: string) {
    const today = new Date();
    const dateParts = vcto.split('/');
    const dia = parseInt(dateParts[0], 10);
    const mes = parseInt(dateParts[1], 10) - 1;
    const ano = parseInt(dateParts[2], 10);
    const dataFormatada = new Date(ano, mes, dia);
		if (saldo == 0) {
			return 'QUITADO';
		}
		else if (dataFormatada.getDate() == today.getDate() &&
        dataFormatada.getMonth() == today.getMonth() &&
				dataFormatada.getFullYear() == today.getFullYear()
		) {
			return 'VENCENDO';
		}
		else if (dataFormatada < new Date()) {
			return 'VENCIDO';
		} 
		return 'PENDENTE';
	}
}
