import { Injectable } from '@angular/core';
import { PesquisaService } from 'src/app/services/pesquisa.service';
import { ProdutoPesquisado } from 'src/app/persistences/produto-pesquisado';

@Injectable({
    providedIn: 'root',
})

export class PesquisaManager {

    private produtosPesquisados: ProdutoPesquisado[];

    constructor(
        private pesquisaService: PesquisaService,
    ) { }

    listaOrdenada(produtos: ProdutoPesquisado[]): number[] {
        let listaOrdenada: ProdutoPesquisado[] = [];
        if (produtos.length > 0)
            listaOrdenada = this.ordenarLista(produtos);
        this.produtosPesquisados = listaOrdenada;
        this.pesquisaService.setProdutosPesquisados(this.produtosPesquisados);
        const idsPesquisa: number[] = [];
        const compativeis: number[] = [];
        this.produtosPesquisados.forEach(item => {
            idsPesquisa.push(item.id_item);
            if (item.compativel == "true") {
                compativeis.push(item.id_item);
            }
        });
        this.pesquisaService.setProdutosCompativeis(compativeis);

        return idsPesquisa;
    }

    ordenarLista(produtos: ProdutoPesquisado[]): ProdutoPesquisado[] {
        const compativeis: ProdutoPesquisado[] = [];
        const disponiveis: ProdutoPesquisado[] = [];
        const indisponiveis: ProdutoPesquisado[] = [];
        let listaOrdenada: ProdutoPesquisado[] = [];

        produtos.forEach(item => {
            if (item.compativel == "true") {
                compativeis.push(item);
            } else {
                if (item.disponivel == "true") {
                    disponiveis.push(item);
                } else {
                    indisponiveis.push(item);
                }
            }
        })

        listaOrdenada = listaOrdenada.concat(compativeis);
        listaOrdenada = listaOrdenada.concat(disponiveis);
        listaOrdenada = listaOrdenada.concat(indisponiveis);

        return listaOrdenada;
    }

}
