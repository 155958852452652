<div class="lancaItemCabecalho">
	<h2 class="titulo">Pedido</h2>
	<mat-divider class="divider"></mat-divider>
	<form class="formulario">
		<div class="valores">
			<div>
				<label class="promocao"
					*ngIf="itemDetalhado.itemPedido.flPromocao  && itemDetalhado.itemPedido.preco > 0">
					PROMOÇÂO
				</label>
				<app-quantidade-lista [detalhada]="true" [item]="itemDetalhado"
					[unMedVenda]="itemDetalhado.itemPedido.unMedVenda"
					[quantidadeItemString]="itemDetalhado.itemPedido.quantidade.toLocaleString('pt-BR',{useGrouping: false})"
					*ngIf="itemDetalhado.itemPedido.quantidade > 0">
				</app-quantidade-lista>
			</div>
			<mat-form-field class="cor" *ngIf="exibeEstoque">
				<mat-label>Quantidade estoque ({{itemDetalhado.itemDetalhes.unMedEstoque}})</mat-label>
				<input matInput type="number" [(ngModel)]="itemDetalhado.itemDetalhes.qtDisponivel" name="qtdeEstoque"
					disabled>
			</mat-form-field>
			<div class="precoPrecoSt margin-top-10 width-100">
				<mat-form-field class="cor width-50">
					<mat-label>Preço</mat-label>
					<input class="precoBold" matInput type="text"
						[ngModel]="itemDetalhado.itemPedido.valorLiquido | currency:'R$':'code'" name="preco" readonly>
				</mat-form-field>
				<mat-form-field class="cor width-50 padding-left-5">
					<mat-label>Preço + ST. + IPI</mat-label>
					<input class="precoBold" matInput type="text"
						[ngModel]="itemDetalhado.itemPedido.valorLiquidoIpiSt | currency:'R$':'code'" name="precoStIpi"
						readonly>
				</mat-form-field>
			</div>
			<mat-form-field class="cor width-100">
				<b>
					<mat-label>Total</mat-label>
				</b>
				<b><input matInput type="text" [ngModel]="itemDetalhado.itemPedido.total | currency:'R$':'code'"
						name="total" disabled></b>
			</mat-form-field>
			<div class="width-100">
				<button
					[disabled]="itemDetalhado.itemDetalhes.qtDisponivel <= 0  || itemDetalhado.itemPedido.valorLiquido <=0"
					mat-raised-button color="primary" class="adicionarCarrinho" (click)="adicionarItemAoCarrinho()"
					*ngIf="itemDetalhado.itemPedido.quantidade === 0">
					{{(itemDetalhado.itemDetalhes.qtDisponivel <= 0 || itemDetalhado.itemPedido.preco <=0)
						? 'Indisponível' : 'Adicionar no carrinho' }}</button>
			</div>
		</div>
		<div class="width-100">
			<app-lista-preco class="tabela-preco" [dialogParent]="true"
				(tabelaPrecoEmitter)="getTabelaPrecoEmitter($event)">
			</app-lista-preco>
		</div>
	</form>
</div>