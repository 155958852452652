import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PedidoSimplificado, PedidoSimplificadoFiltros } from 'src/app/persistences/pedido-simplificado';
import { PedidoService } from './../../../services/pedido.service';
import { SnackbarManager } from './../../../managers/snackbar-manager';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';

@Component({
  selector: 'app-pesquisa-customizada',
  templateUrl: './pesquisa-customizada.component.html',
  styleUrls: ['./pesquisa-customizada.component.css'],
})
export class PesquisaCustomizadaComponent implements OnInit {

  @Input()
  itemCosulta: number;
  @Output()
  resultadoDaPesquisa = new EventEmitter<PedidoSimplificado[]>();
  @Output()
  isPesquisando = new EventEmitter<boolean>();

  public origensDoPedido = [{ label: 'Todos', value: 'T' }, { label: 'Externo(Web)', value: 'E' }, { label: 'Interno(Erp)', value: 'I' }];
  public origemDoPedidoSelecionada = 'T';
  public formPesquisa: FormGroup;   

  private filtros = new PedidoSimplificadoFiltros;
  private listaDePedidos: PedidoSimplificado[];

  constructor( 
    private snackBarManager: SnackbarManager,
    private pedidoService: PedidoService,
    private clienteSingletonService: ClienteSingletonService
  ) { }

  ngOnInit(): void {  
    this.formPesquisa = new FormGroup({
      origemPedido: new FormControl(''),
      codigo: new FormControl(0),
      dataInicial: new FormControl(new Date(new Date().setMonth(new Date().getMonth() - 1)), Validators.required),
      dataFinal: new FormControl(new Date(), Validators.required),
    });
    this.isPesquisando.emit(true);
  }

  confirmar() {
    this.isPesquisando.emit(true);
    this.filtros.cdCliente = this.clienteSingletonService.getClienteEstatico().getCdCliente;
    this.filtros.situacao = this.itemCosulta === undefined || this.itemCosulta === null ? 0 : this.itemCosulta;
    this.filtros.origem = this.formPesquisa.get('origemPedido').value === 'T' ? '' : this.formPesquisa.get('origemPedido').value;
    this.filtros.dtInicial = new Date(this.formPesquisa.get('dataInicial').value).toLocaleDateString('pt-BR');
    this.filtros.dtFinal = new Date(this.formPesquisa.get('dataFinal').value).toLocaleDateString('pt-BR');

    const pedidos = this.listaDePedidos;
    this.listaDePedidos = new Array<PedidoSimplificado>();
    this.pedidoService.getPedidosVendaSimplificadoByCliente(this.filtros).subscribe(response => {
      this.listaDePedidos = response.sort((pedido1, pedido2) => {
        const dataA = this.convertStringParaData(pedido1.dtEmissao);
        const dataB = this.convertStringParaData(pedido2.dtEmissao);
        return dataB.getTime() - dataA.getTime();
      });
      this.resultadoDaPesquisa.emit(this.listaDePedidos);
      this.isPesquisando.emit(false);
    }, (error) => {
      this.listaDePedidos = pedidos;
      this.snackBarManager.openSnackBar("Não foi possível atualizar a lista, " + error.error, "Ok", 3000);
    })
  }

  receberItemConsulta(itemConsulta: number) {
    this.itemCosulta = itemConsulta;
  }

  convertStringParaData(dataString: string): Date {
    const partes = dataString.split('/');
    return new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));
  }
}

