import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-minha-conta',
  templateUrl: './menu-minha-conta.component.html',
  styleUrls: ['./menu-minha-conta.component.css']
})
export class MenuMinhaContaComponent implements OnInit {

  public isCliente = false;
  public isUsuario = false;
  public routerLink: string;

  ngOnInit() {
    this.isCliente = parseInt(localStorage.getItem('cd_usuario_business')) === 0;
    this.isUsuario = parseInt(localStorage.getItem('cd_usuario_business')) !== 0;
    this.routerLink = this.isCliente ? "/minha-conta/perfil-cliente" : "/minha-conta/perfil-usuario";
  }
}
