import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ItemManager } from 'src/app/managers/item-manager';
import { Preco } from 'src/app/persistences/preco';
import { ItemDetalhado } from './../../persistences/item-detalhado';

@Component({
  selector: 'app-lista-preco',
  templateUrl: './lista-preco.component.html',
  styleUrls: ['./lista-preco.component.css']
})
export class ListaPrecoComponent implements OnInit {

  @Input()
  public itemDetalhado: ItemDetalhado;
  @Input()
  public dialogParent = false;
  public tabelasDePreco: Preco[];
  public tabelaSelecionada: number;
  
  @Output()
  tabelaPrecoEmitter = new EventEmitter<Preco>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ItemDetalhado,
    public dialogRef: MatDialogRef<ListaPrecoComponent>,
    public dialogCustom: MatDialog,
    private itemManager: ItemManager
  ) {
    this.itemDetalhado = data['itemDetalhado'];
  }

  ngOnInit() {    
    this.tabelasDePreco = this.itemManager.removerTabelasDePrecoDuplicas(this.itemDetalhado);
    this.tabelaSelecionada = this.itemDetalhado.itemPedido.idTabelaPreco !== undefined ?
    this.itemDetalhado.itemPedido.idTabelaPreco : this.itemDetalhado.itemDetalhes.tabelaPrecoView.idGeral;
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  getTabelaPrecoEmitter(tabelaPreco: Preco) {
    this.tabelaPrecoEmitter.emit(tabelaPreco);
    if (this.dialogParent !== true)
      this.dialogRef.close(tabelaPreco);
    this.tabelasDePreco = this.itemManager.removerTabelasDePrecoDuplicas(this.itemDetalhado);    
    this.tabelaSelecionada = this.itemDetalhado.itemPedido.idTabelaPreco !== undefined ?
      this.itemDetalhado.itemPedido.idTabelaPreco : this.itemDetalhado.itemDetalhes.tabelaPrecoView.idGeral;
  }

  outrosPrecosDisponivel(): boolean {
    const result = this.itemManager.removerTabelasDePrecoDuplicas(this.itemDetalhado);
    return result === null || result.length <= 1;
  }
}
