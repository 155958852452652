import { Injectable } from '@angular/core';
import { Cliente } from '../persistences/cliente';
import { Funcionario } from '../persistences/funcionario';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';
import { ClienteManager } from './cliente-manager';
import { FuncionarioManager } from './funcionario-manager';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToolBarManager {

    public cliente: Cliente;
    public funcionario: Funcionario;
    private nomeUsuario: string;

    constructor(
        public clienteSingleton: ClienteSingletonService,
        public clienteManager: ClienteManager,
        public funcionarioManager: FuncionarioManager
    ) { }

    getFuncionario(): Observable<string> {
        return new Observable((observe) => {
            this.funcionarioManager.getFuncionario(Number.parseInt(localStorage.getItem('cd_usuario_business'))).then(res => {
                this.nomeUsuario = res.nmFuncionario;
            });
            setTimeout(() => {
                observe.next(this.nomeUsuario);
                observe.complete();
            }, 500);
        });
    }
}
