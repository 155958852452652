<mat-grid-list class="margin-top" [cols]="colunas - (lateral ? 1:0)" rowHeight="8px"
	(window:resize)="onResize($event, null)">
	<div class="listaItens"
		*ngFor="let item of listaItens | paginate: {itemsPerPage: pagesize, currentPage: page, totalItems: ids.length}">
		<mat-grid-tile [rowspan]="60">
			<app-card-item-pesquisa [itemPesquisa]="item" [temSimilares]="getSimilares(item.itemDetalhado)"
				[quantidadeItem]="getQuantidade(item.idItem)" [valorTotalItem]="getTotal(item.idItem)"
				[valorLiquido]="item.vl_unitario_liquido" [item]="getItemDetalhado(item)"
				[fichaTecnica]="getFichaTecnica(item.itemDetalhado)" [temEstoque]="getQtAtual(item.itemDetalhado)"
				[itemPedido]="getItemDetalhado(item).itemPedido" [exibeDesconto]="getExibeDesconto()"
				[exibeEstoque]="getExibeEstoque()">
			</app-card-item-pesquisa>
		</mat-grid-tile>
	</div>
</mat-grid-list>