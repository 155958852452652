import { Component, Input, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { PainelService } from '../../../services/painel.service';
import { Painel } from '../../../persistences/painel';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomizadoComponent } from 'src/app/components/genericos/dialog-customizado/dialog-customizado.component';
import { JwtService } from '../../../jwt.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-painel-cadastro',
  templateUrl: './painel-cadastro.component.html',
  styleUrls: ['./painel-cadastro.component.css']
})
export class PainelCadastroComponent implements OnInit {
  formPainelGravar: FormGroup;
  formPainelEditar: FormGroup;
  private painelView = new Painel;
  rowHeigthAdicao: number;
  rowHeigthEdicao: number;
  paineis: Painel[];
  @Input()
  itemConsultaCdFilial: number;
  @Input()
  itemConsultaCdAcesso: string;
  quantidadeItemGravacao: number;
  quantidadeItemEdicao: number;
  widthDialog: string;
  isLayoutMobile: boolean;
  painelEditado: Painel;

  constructor(
    private snackBarManager: SnackbarManager,
    private painelSevice: PainelService,    
    private dialog: MatDialog,
    private jwtService: JwtService,
    private datePipe: DatePipe
  ){ 
  }

  data: Date;

  ngOnInit(): void {  
    this.onResize();
    this.formPainelGravar = new FormGroup({
      cdFilial: new FormControl('',Validators.required),
      dataInicio: new FormControl('', [Validators.required, this.dateValidator]),
      dataFim: new FormControl('', [Validators.required, this.dateValidator]),
      cdAcesso:  new FormControl('',Validators.required),
      ordem:  new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
      tituloAba:  new FormControl('',Validators.required)
    });
    
    this.painelSevice.getAll().subscribe(response =>{            
      this.paineis = response.map(painel => {
        const dataInicioDate = this.getStringToDate(painel.dataInicio);
        const dataFimDate = this.getStringToDate(painel.dataFim);
        const form = new FormGroup({
          cdFilial: new FormControl(painel.cdFilial, Validators.required),
          dataInicio: new FormControl(dataInicioDate, [Validators.required, this.dateValidator]),
          dataFim: new FormControl(dataFimDate, [Validators.required, this.dateValidator]),
          cdAcesso: new FormControl(painel.cdAcesso, Validators.required),
          ordem: new FormControl(painel.ordem, [Validators.required, Validators.pattern('^[0-9]*$')]),
          tituloAba: new FormControl(painel.tituloAba, Validators.required)
        });

        return { ...painel, form };
      });
    }); (error) =>{
      this.snackBarManager.openSnackBar("Não foi possivel carregar paineis: " + error.error, "Ok", 3000);
      console.log(error);
    }
    this.widthDialog = '400px'
  }

  salvar(){
    this.formPainelGravar.get('cdFilial').setValue(this.itemConsultaCdFilial);
    this.formPainelGravar.get('cdAcesso').setValue(this.itemConsultaCdAcesso);
    const dataInicio = new Date(this.formPainelGravar.get('dataInicio').value);
    const dataFim = new Date(this.formPainelGravar.get('dataFim').value);

    if(this.isDateRangeValid(dataInicio, dataFim)){
      if (this.formPainelGravar.valid) {
        this.painelView.cdFilial = this.itemConsultaCdFilial;      
        this.painelView.dataInicio = this.datePipe.transform(dataInicio, 'dd/MM/yyyy');
        this.painelView.dataFim = this.datePipe.transform(dataFim, 'dd/MM/yyyy');
        this.painelView.cdAcesso = this.itemConsultaCdAcesso;
        this.painelView.ordem = this.formPainelGravar.get('ordem').value;
        this.painelView.tituloAba = this.formPainelGravar.get('tituloAba').value;
        
        this.painelSevice.gravarPainel(this.painelView).subscribe(() =>{
          this.snackBarManager.openSnackBar("Painel salvo com sucesso" , "Ok", 3000);
          this.ngOnInit();
          this.formPainelGravar.get('cdFilial').setValue('');
          this.formPainelGravar.get('cdAcesso').setValue('');
        }, (error) =>{
          this.snackBarManager.openSnackBar("Não foi possivel salvar painel: " + error.error, "Ok", 3000);
          console.log(error);
        })
      } else {
        this.snackBarManager.openSnackBar("Formulário invalido","Ok", 3000);
      }
    }else{
      this.snackBarManager.openSnackBar("A data de início deve ser menor ou igual à data de fim", "Ok", 3000);
    }
    
  }
  
  editar(painel:any){  
    const dataInicio = new Date(painel.form?.get('dataInicio').value);
    const dataFim = new Date(painel.form?.get('dataFim').value);
    this.painelEditado = new Painel();

    if(this.isDateRangeValid(dataInicio, dataFim)){
      this.painelEditado.idGeral = painel.idGeral;
      this.painelEditado.dataInicio = this.datePipe.transform(dataInicio, 'dd/MM/yyyy');
      this.painelEditado.dataFim = this.datePipe.transform(dataFim, 'dd/MM/yyyy');
      this.painelEditado.ordem = painel.ordem;
      this.painelEditado.tituloAba = painel.tituloAba;
      
      this.receberItemConsultaFilialEditar(painel.form?.get('cdFilial').value, painel);
      painel.form.get('cdFilial').setValue(this.itemConsultaCdFilial);

      this.receberItemConsultaAcessoEditar(painel.form?.get('cdAcesso').value, painel)
      painel.form.get('cdAcesso').setValue(this.itemConsultaCdAcesso);

      this.painelEditado.cdFilial = painel.form?.get('cdFilial').value;      
      this.painelEditado.cdAcesso = painel.form?.get('cdAcesso').value;

      if(painel.form?.valid){
        this.painelSevice.alterarPainel(this.painelEditado).subscribe(() => {    
          this.snackBarManager.openSnackBar("Painel editado com sucesso!" , "Ok", 3000);
          this.ngOnInit();
        }, (error) =>{
          this.snackBarManager.openSnackBar("Não foi possível excluir painel: " + error.error, "Ok", 3000);
          console.log(error)
        })
      } else{
        this.snackBarManager.openSnackBar("Formulário invalido","Ok", 3000);
      }

      this.painelEditado = null;
    }else{
      this.snackBarManager.openSnackBar("A data de início deve ser menor ou igual à data de fim", "Ok", 3000);
    }
  }

  async excluir(painel:Painel){  
    const mensagem = 'Deseja excluir este painel?';
    const confirmacao = 'Sim';
    const negacao = 'Não';
    
    const dialogRef = this.dialog.open(DialogCustomizadoComponent, {
      width: this.widthDialog,
      height: '100px',
      panelClass: 'custom-dialog',
      data:{labelMensagem: mensagem, labelConfirmacao: confirmacao, labelNegacao: negacao}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.painelSevice.deletarPainel(painel.idGeral).subscribe(() => {            
            this.ngOnInit();
            this.snackBarManager.openSnackBar("Painel excluido com sucesso!", "Ok", 3000);
          }, (error) =>{
            this.snackBarManager.openSnackBar("Não foi possível excluir painel: " + error.error, "Ok", 3000);
            console.log(error)
          })
        }
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
      }
    );
  }

  getStringToDate(date:string){
    
    const [dia, mes, ano] = date.split('/');
      return new Date(parseInt(ano),parseInt(mes)-1,parseInt(dia))
  }

  getDateToString(date){
    if(date instanceof Date){
      return date.toLocaleDateString();
    } else if(date === undefined) {
      return;
    }else {
      return date._d.toLocaleDateString();
    }
  }

  receberItemConsultaFilial(itemConsulta) {
    this.itemConsultaCdFilial = itemConsulta;
  }

  receberItemConsultaAcesso(itemConsulta: string) {
    this.itemConsultaCdAcesso = itemConsulta;
  }

  receberItemConsultaFilialEditar(itemConsulta, painel) {
    if(this.painelEditado){
      if(painel.idGeral == this.painelEditado.idGeral){
        this.itemConsultaCdFilial = itemConsulta;
      }else{
        this.itemConsultaCdFilial = painel.form.get('cdFilial').value;
      }
    }else{
      this.itemConsultaCdFilial = itemConsulta;
      painel.form.get('cdFilial').setValue(itemConsulta)
    }
  }

  receberItemConsultaAcessoEditar(itemConsulta: string, painel) {
    if(this.painelEditado){
      if(painel.idGeral == this.painelEditado.idGeral){
        this.itemConsultaCdAcesso = itemConsulta;
      }else{
        this.itemConsultaCdAcesso = painel.form.get('cdAcesso').value;
      }
    }else{
      this.itemConsultaCdAcesso = itemConsulta;
      painel.form.get('cdAcesso').setValue(itemConsulta)
    }
  }

  private isDateRangeValid(startDate: Date, endDate: Date): boolean {
    if (startDate && endDate && startDate <= endDate) {
      return true;
    }
    return false;
  }

  private dateValidator(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && !isNaN(new Date(control.value).getTime())) {
      return null;
    }
    return { 'invalidDate': true };
  }

  @HostListener('window:resize', ['$event'])
  onResize(){    
    switch(true){
      case(window.innerWidth < 800):{
        this.isLayoutMobile = true;
        this.rowHeigthEdicao = 635;
        this.rowHeigthAdicao = 600;
        break;
      }
      case(window.innerWidth < 880):{
        this.rowHeigthEdicao = 635;
        this.rowHeigthAdicao = 600;
        this.isLayoutMobile = false;
        break;
      }
      case(window.innerWidth > 880 && window.innerWidth < 1060):{
        this.rowHeigthAdicao = 250;
        this.rowHeigthEdicao = 250;
        break;
      }
      case(window.innerWidth > 1061 && window.innerWidth < 1911):{
        this.rowHeigthAdicao = 250;
        this.rowHeigthEdicao = 200;
        break;
      }
      default:{
        this.rowHeigthAdicao = 200;
        this.rowHeigthEdicao = 140;
      }
    }
  }
}
