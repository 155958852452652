import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { PainelCadastroComponent } from './pages/cadastros/painel/painel-cadastro.component';
import { CarrinhoPaginaComponent } from './pages/carrinho-pagina/carrinho-pagina.component';
import { HomeComponent } from './pages/home/home.component';
import { MeusPedidosComponent } from './pages/meus-pedidos/meus-pedidos.component';
import { LoginComponent } from './pages/login/login.component';
import { MinhaContaComponent } from './pages/minha-conta/minha-conta.component';
import { PerfilClienteComponent } from './pages/cadastros/perfil-cliente/perfil-cliente.component';
import { PerfilUsuarioComponent } from './pages/cadastros/perfil-usuario/perfil-usuario.component';
import { ListaTitulosComponent } from './pages/lista-titulos/lista-titulos.component';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: "home", component: HomeComponent },   
	{ path: "login", component: LoginComponent },
	{ path: "carrinho", component: CarrinhoPaginaComponent },
	{ path: "minha-conta", component: MinhaContaComponent, children: [
		{ path: "perfil-cliente", component: PerfilClienteComponent},
		{ path: "perfil-usuario", component: PerfilUsuarioComponent},
		{ path: "meus-pedidos", component: MeusPedidosComponent},
		{ path: "alterar-senha", component: AlterarSenhaComponent},
		{ path: "painel-cadastro", component: PainelCadastroComponent},
		{ path: "meus-titulos", component: ListaTitulosComponent}
	]},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],	
    exports: [RouterModule]
})
export class AppRoutingModule { }
