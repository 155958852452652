<div class="flex-row">
  <div>
    <button (click)="adicionarItensAoCarrinho(getMinimo())" *ngIf="botaoAdicionarVisivel()" 
    [disabled]="!statusComponent || !itemDisponivel()"
       class="addFrase" color="primary" mat-raised-button name="btnAdicionar">
      {{
        !itemDisponivel() ? "Indisponível" : "Adicionar" }}
        </button>
  </div>
  <div class="item-1" *ngIf="inputQtdeVisivel()">
    <button [disabled]="!statusComponent || isQuantidadeMinima(quantidadeItemString)" (click)="removerQtdeDoItemAtual(this.getMultiplo())
      " class="campoMenos" color="primary" mat-icon-button name="btnRemoveItem">
      <mat-icon class="lixeira">{{"remove"}}</mat-icon>
    </button>    
  </div>
  <div class="item-2 mat-input" *ngIf="inputQtdeVisivel()">
    <mat-form-field class="mat-form mat-input" name="qtdeItem">
      <div class="mat-form-field-label">
        <mat-label class="mat-label">
          Quantidade ({{ unMedVenda }})
          <img *ngIf="item.itemPedido.quantidade > item.itemPedido.quantidadeEstoque"
				  src="assets/images/warning.png" style="height: 16px;" (click)="onClickQuantidade()">
        </mat-label>
        <input autocomplete="off" [disabled]="!statusComponent" #inputHtml class="mat-input" matInput appDelayedInput
        (keydown.enter)="this.validarQuantidadeInformada(quantidadeItemString)" (blur)="onBlur(quantidadeItemString)"
        (delayedInput)="atualizarQtdeDoItemAtual(quantidadeItemString)" [delayTime]="delayTime" [(ngModel)]="quantidadeItemString"
        ignorarRotina="false" id="qtde" name="qtde" step="multiplo" min="multiplo" (focusin)="setQuantidadeAtualDoItem($event)"
        (keypress)="barrarInputsIncorretos($event)"/>
      </div>
    </mat-form-field>
  </div>
  <div class="item-3" *ngIf="botaoAdicionarItemVisivel()">
    <button [disabled]="!statusComponent || this.isQuantidadeMaxima(quantidadeItemString)" (click)="addQuantidadeItem(this.getMultiplo())" color="primary" mat-icon-button
      name="btnAdicionaItem">
      <mat-icon class="mais">add</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="inputQtdeVisivel()" class="flex-row">
  <button color="primary" mat-raised-button class="item-4" (click)="removerItemDoCarrinho()" color="primary" 
    name="btnRemoverItem">
    Remover
  </button>
</div>