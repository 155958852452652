export class CodigoConversao {
	public codigo: string;
	public descricao: string;
	public tipoCodigo: string;

	constructor(json?: any) {
        if (json) {
            // Exemplo de inicialização direta
            this.codigo = json.codigo;
            this.descricao = json.descricao;
            this.tipoCodigo = json.tipoCodigo;         
        } 
    }
}
