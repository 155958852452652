<div [ngClass]="this.temaPadrao" #scrollbar class="modal">
	<mat-toolbar class="toolbar-detalhado" color="primary">
		<mat-toolbar-row>
			<button mat-icon-button (click)="fechar()">
				<mat-icon>keyboard_backspace</mat-icon>
			</button>
			<span class="titulo">Item Detalhado</span>
			<mat-chip-listbox class="mat-chip-list-wrapper" #chipList class="trilha">
				<mat-chip-option color="accent" selected>
					<b>{{itemAtual.itemDetalhes.cdItem}}</b>
				</mat-chip-option>
			</mat-chip-listbox>
		</mat-toolbar-row>
	</mat-toolbar>
	<div class="conteudo">
		<div class="ladoLado">
			<div class="container-detalhes">
				<app-item-descricao-detalhado #itemDescricaoDetalhado *ngIf="carregado" style="color: initial;"
					[item]="itemAtual" [imageObject]="imageObject" [imagensCarregadas]="imagensCarregadas">
				</app-item-descricao-detalhado>
			</div>
			<div class="container-cabecalho">
				<app-lanca-item-cabecalho (addItem)="addToCart(itemAtual)" [itemDetalhado]="getItemAtual()">
				</app-lanca-item-cabecalho>
			</div>
		</div>
		<br>
		<mat-accordion class="expansionPanel" multi="true">
			<mat-expansion-panel [expanded]="false" *ngIf="temDescricao" (click)="fichaTecnicaProduto()">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Ficha técnica
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="cor" [innerHTML]="remover">
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="false" *ngIf="temConversao">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Conversão
					</mat-panel-title>
				</mat-expansion-panel-header>
				<table mat-table [dataSource]="itemAtual.itemDetalhes.cdsConversao" class="field-width-100">
					<ng-container matColumnDef="codigo">
						<th mat-header-cell *matHeaderCellDef> Código </th>
						<td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
					</ng-container>
					<ng-container matColumnDef="descricao">
						<th mat-header-cell *matHeaderCellDef> Descrição </th>
						<td mat-cell *matCellDef="let element"> {{element.descricao}} </td>
					</ng-container>
					<ng-container matColumnDef="tipo">
						<th mat-header-cell *matHeaderCellDef> Tipo </th>
						<td mat-cell *matCellDef="let element"> {{element.tipoCodigo}} </td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="colunasCodigos; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: colunasCodigos;"></tr>
				</table>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="false" *ngIf="temVeiculo">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Veículo
					</mat-panel-title>
				</mat-expansion-panel-header>
				<table mat-table [dataSource]="itemAtual.itemDetalhes.infoVeiculo" class="field-width-100">
					<ng-container matColumnDef="montadora">
						<th mat-header-cell *matHeaderCellDef> Montadora </th>
						<td mat-cell *matCellDef="let element"> {{element.montadora}} </td>
					</ng-container>
					<ng-container matColumnDef="modelo">
						<th mat-header-cell *matHeaderCellDef> Modelo </th>
						<td mat-cell *matCellDef="let element"> {{element.modelo}} </td>
					</ng-container>
					<ng-container matColumnDef="anoIni">
						<th mat-header-cell *matHeaderCellDef> Ano Inicial </th>
						<td mat-cell *matCellDef="let element"> {{element.anoInicio}} </td>
					</ng-container>
					<ng-container matColumnDef="anoFin">
						<th mat-header-cell *matHeaderCellDef> Ano Final </th>
						<td mat-cell *matCellDef="let element"> {{element.anoFim}} </td>
					</ng-container>
					<ng-container matColumnDef="tipo">
						<th mat-header-cell *matHeaderCellDef> Complemento </th>
						<td mat-cell *matCellDef="let element"> {{element.complemento}} </td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="colunasVeiculos; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: colunasVeiculos;"></tr>
				</table>
			</mat-expansion-panel>
		</mat-accordion>
		<div class="listaSemelhantes">
			<mat-divider class="dividerSemelhantes"></mat-divider>
			<app-lista-similares #listaSimilares (selecionado)="selecionarSimilar($event)" [cdItemAtual]="cdItemAtual">
			</app-lista-similares>
		</div>
	</div>
</div>