import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';
import { SearchbarService } from './../../services/searchbar.service';
import { ToolBarManager } from '../../managers/toolbar-manager';
import { ClienteManager } from 'src/app/managers/cliente-manager';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/persistences/cliente';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy  {

	@Input()
	public pesquisaVisivel = true;
	public logoMini: boolean;
	@Input()
	public nomeUsuario: string;
	public logoEmpresa: string;
	private subscription: Subscription;
	@Input()
	public cliente: Cliente;
	public isCliente = parseInt(localStorage.getItem("cd_usuario_business")) === 0 ? true : false;
	public temAcesso = parseInt(localStorage.getItem('tem_acesso_business')) == 0 ? false : true;

	constructor(
		private clienteSingleton: ClienteSingletonService,
		private clienteManager: ClienteManager,
		private searchbarService: SearchbarService,
		private toolbarManager: ToolBarManager,
		private router: Router,
	) { }
	
	ngOnInit() {				
		this.checkWindowSize();	
		this.subscription = this.clienteSingleton.getClienteObservable().subscribe((cliente) => {
			this.cliente = cliente;	
			if (this.isCliente)
				this.nomeUsuario = this.cliente.getNmFantasia;
			else
				this.toolbarManager.getFuncionario().subscribe(res => this.nomeUsuario = res);
			if (this.cliente.getClienteFilial) {		
				this.logoEmpresa = this.cliente.getClienteFilial.getImgStr;
			}			
		});				
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	emitIdsPesquisa(ids: any) {
		this.searchbarService.emitIdsPesquisa(ids);
	}

	emitIsLoading(boolean: any) {
		this.searchbarService.emitIsLoading(boolean);
	}

	emitOcultarAbas(boolean: any) {
		this.searchbarService.emitOcultarAbas(boolean);
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.checkWindowSize();
	}

	checkWindowSize() {
		this.logoMini = window.innerWidth < 959 ? true : false;
	}

	getRouterLink(){
		if(this.temAcesso){
			this.router.navigateByUrl('/');
		} else{
			this.router.navigateByUrl('/minha-conta/meus-pedidos')
		}
	}
}

