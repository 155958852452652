import { CodigoConversao } from './codigo-conversao';
import { InfoVeiculo } from './info-veiculo';
import { Preco } from './preco';
import { ProdutoUnidade } from './produto-unidade';
import { Tributacao } from './tributacao';

export class ItemDetalhes {
    public idItem: number;
    public cdItem: string;
    public cdOriginal: string;
    public cdFabricante: string;
    public nmItem: string;
    public nmReduzido: string;
    public fichaTecnica: string;
    public estoqueDisponivel = false;
    public qtAtual: number;
    public qtDisponivel: number;    
    public flVendeFracionado?: string;
    public unMedEstoque: string;
    public unMedVendaPadrao: string;
    public fatorConversao: number;
    public cdsConversao: CodigoConversao[];
    public infoVeiculo: InfoVeiculo[];
    public nmMarca: string;
    public cdMarca: number;
    public temSimilares: boolean;
    public valorMultiplo: number;
    public qtdMin: number;
    public tabelaPrecoView: Preco;
    public outrosPrecos: Preco[];
    public tributacaoView?: Tributacao;
    public produtoUnidade?: ProdutoUnidade[];

    constructor(json?: any) {
        if (json) {
            // Exemplo de inicialização direta
            this.idItem = json.idItem;
            this.cdItem = json.cdItem;
            this.cdOriginal = json.cdOriginal;
            this.cdFabricante = json.cdFabricante;
            this.nmItem = json.nmItem;
            this.nmReduzido = json.nmReduzido;
            this.fichaTecnica = json.fichaTecnica;
            this.estoqueDisponivel = json.estoqueDisponivel;
            this.qtAtual = json.qtAtual;
            this.qtDisponivel = json.qtDisponivel;
            this.flVendeFracionado = json.flVendeFracionado;
            this.unMedEstoque = json.unMedEstoque;
            this.unMedVendaPadrao = json.unMedVendaPadrao;
            this.fatorConversao = json.fatorConversao;            
            this.nmMarca = json.nmMarca;
            this.cdMarca = json.cdMarca;
            this.temSimilares = json.temSimilares;
            this.valorMultiplo = json.valorMultiplo;
            this.qtdMin = json.qtdMin;
            this.tabelaPrecoView = json.tabelaPrecoView;            
            this.tributacaoView = json.tributacaoView;            

            // Exemplo para arrays de objetos
            this.outrosPrecos = json.outrosPrecos ? json.outrosPrecos.map((item: any) => new Preco(item)) : [];
            this.produtoUnidade = json.produtoUnidade ? json.produtoUnidade.map((item: any) => new ProdutoUnidade(item)) : [];
            this.cdsConversao = json.cdsConversao ? json.cdsConversao.map((item: any) => new CodigoConversao(item)) : [];
            this.infoVeiculo = json.infoVeiculo ? json.infoVeiculo.map((item: any) => new InfoVeiculo(item)) : [];            
        } 
    }
}
