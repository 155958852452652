import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroLateralManager } from 'src/app/managers/filtro-manager';
import { Painel } from 'src/app/persistences/painel';
import { PesquisaService } from 'src/app/services/pesquisa.service';
import { AcessoAcaoManager } from './../../managers/acesso-acao-manager';
import { PainelService } from './../../services/painel.service';
import { SearchbarService } from 'src/app/services/searchbar.service';

@Component({
  selector: 'app-carrossel-cabecalho',
  templateUrl: './carrossel-cabecalho.component.html',
  styleUrls: ['./carrossel-cabecalho.component.css']
})
export class CarrosselCabecalhoComponent implements OnInit {
  tabIndex = 0;
  paineis: Painel[] = [];
  isPesquisaRealizada: boolean;
  ocultaAbas: boolean;
  isPesquisaSearchBar = false;
  painel: Painel;

  @Input()
  ordenacaoInput: boolean;

  @Output()
  idsPesquisaEmitter = new EventEmitter<number[]>();
  @Output()
  ordemEmitter = new EventEmitter<string>();
  @Output()
  filtroEmitter = new EventEmitter<string>();
  constructor(
    private painelService: PainelService,
    private acessoAcaoManager: AcessoAcaoManager,
    private pesquisaService: PesquisaService,
    private filtroLateralManager: FiltroLateralManager,
    private searchbarService: SearchbarService,
    private cdr: ChangeDetectorRef
  ) {
    this.isPesquisaRealizada = false;
    this.pesquisaService.pesquisaRealizadaEmitter.subscribe((res)=>{
      this.isPesquisaRealizada = res;
    })
    this.searchbarService.pesquisaSearchbarEmitter.subscribe((res)=>{
      if(this.paineis[0].tituloAba === "Pesquisa" && this.paineis[0].idGeral === -1){
        return
      }
      this.isPesquisaSearchBar = true;
      const painelPesquisa = new Painel();
      painelPesquisa.tituloAba = "Pesquisa";
      painelPesquisa.idGeral = -1;
      this.paineis.unshift(painelPesquisa);
      this.cdr.detectChanges();
      if(this.tabIndex === 0){
        this.tabIndex = this.tabIndex - 1;
      }else{
        this.tabIndex = 0;
      }
    })
  }

  ngOnInit() {
    this.carregarPaineis();
  }

  carregarPainelSelecionado($event) {
    if(this.isPesquisaSearchBar){
      if(this.paineis[0].tituloAba === "Pesquisa" && this.paineis[0].idGeral === -1){
        this.isPesquisaSearchBar = false;
        return;
        }
      this.tabIndex = $event.index - 1;
      this.paineis.shift();
      this.tabIndex = $event.index - 1;
    } else{
        const contemPainel = this.paineis.includes(this.paineis.find(p => p.idGeral === -1))
        if(contemPainel){
          this.paineis.splice(0,1);
          this.tabIndex = $event.index - 1;
        }else {
          this.tabIndex = $event.index;
          this.isPesquisaSearchBar = false;
        }
        this.carregarPainel(this.paineis[this.tabIndex].cdAcesso);
    }
    
  }

  carregarPaineis() {
    this.painelService.getAtivosPorFilial().subscribe(paineis => {
      this.painel = new Painel(this.paineis[0])
      this.paineis = paineis;
      this.ocultaAbas = this.paineis.length > 0
      for (let index = 0; index < this.paineis.length; index++) {
        if (index === 0) {
          this.carregarPainel(this.paineis[index].cdAcesso);
        }
      }
    });
  }

  carregarPainel(cdAcesso: string){
    return this.acessoAcaoManager.getIdsConsultaAcessoAcao(cdAcesso).then((idsRetorno) => {
      this.filtroLateralManager.preencherListaDeFiltros(idsRetorno);
      setTimeout(() => {
        this.idsPesquisaEmitter.emit(idsRetorno);        
      },550)
    });
  }
  emitirOrdem(ordem: string) {
    this.ordemEmitter.emit(ordem);
  }

  emitirFiltro(filtro) {
    this.filtroEmitter.emit(filtro);
  }

}