<div class="padding-20">
    <div>
        <mat-grid-list cols="1" [rowHeight]="rowHeigthAdicao">
            <mat-grid-tile class="mat-grid-tile-height">
                <div>
                    <div class="margin-10">
                        <mat-label class="label-titulo flex-center-if-mobile">Adicionar painel</mat-label>
                    </div>
                    <div>
                        <mat-card appearance="outlined"[formGroup]="formPainelGravar" >
                            <mat-card-content class="mat-card-content" >
                                <app-consulta-customizada matInput class="form-field-material" [labelDaDescricao]="'Filial'" [identificador]="'cntFilial'"
                                        [campoFiltro]="'CD_FILIAL,NM_FILIAL'" [lookupExibicao]="['CD_FILIAL', 'NM_FILIAL']"
                                        complementoConsulta=""  
                                        (itemCosulta)="receberItemConsultaFilial($event)" [campoRequerido]="true">
                                    </app-consulta-customizada>
                                <mat-form-field appearance="fill">
                                    <mat-label>Início</mat-label>
                                    <input matInput [matDatepicker]="dataInicialGravar" class="input" formControlName="dataInicio" />
                                    <mat-datepicker-toggle matSuffix [for]="dataInicialGravar"></mat-datepicker-toggle>
                                    <mat-datepicker class="mat-calendar" #dataInicialGravar></mat-datepicker>        
                                    <mat-error *ngIf="formPainelGravar.get('dataInicio').invalid">Data inválida</mat-error>
                                </mat-form-field>
                                      
                                <mat-form-field appearance="fill">
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="dataFinalGravar" class="input" formControlName="dataFim" />
                                    <mat-datepicker-toggle matSuffix [for]="dataFinalGravar"></mat-datepicker-toggle>
                                    <mat-datepicker class="mat-calendar" #dataFinalGravar></mat-datepicker>        
                                    <mat-error *ngIf="formPainelGravar.get('dataFim').invalid">Data inválida</mat-error>   
                                </mat-form-field>
                                      
                                <app-consulta-customizada matInput class="form-field-material" [labelDaDescricao]="'Ação'" [identificador]="'cntAcessoAcao'"
                                        [campoFiltro]="'CD_ACESSO,NM_ACAO'" [lookupExibicao]="['CD_ACESSO', 'NM_ACAO']" 
                                        complementoConsulta="fl_classificacao in ('R','G','L')" 
                                        (itemCosulta)="receberItemConsultaAcesso($event)" [campoRequerido]="true" >
                                    </app-consulta-customizada>
                                <mat-form-field appearance="fill">
                                    <mat-label>Ordem</mat-label>
                                    <input matInput class="input" formControlName="ordem"/>    
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Título da aba</mat-label>
                                    <input matInput class="input" formControlName="tituloAba"/>    
                                </mat-form-field>
                                <div class="padding-10">
                                    <button class="botao-form" mat-flat-button color="primary" (click)="salvar()" >
                                        Salvar
                                    </button>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
        <div *ngIf="paineis?.length > 0" class="margin-top-10">
            <div class="margin-10">
                <mat-label class="label-titulo flex-center-if-mobile">Lista de painéis</mat-label>
            </div>
            <mat-accordion *ngFor="let painel of paineis; let i = index">
                <mat-expansion-panel class="margin-bottom-10"  hideTogle>
                    <mat-expansion-panel-header [ngClass]="isLayoutMobile ? 'height-mobile':'' ">
                        <mat-panel-title>
                            {{painel.tituloAba}}
                        </mat-panel-title>
                        <mat-panel-description>
                            Filial: {{painel.cdFilial}} | De: {{((painel.dataInicio))}} | Até: {{painel.dataFim}} | Código de acesso: {{painel.cdAcesso}} | Ordem: {{painel.ordem}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <mat-grid-list cols="1" [rowHeight]="(rowHeigthEdicao)">
                            <mat-grid-tile>
                                <mat-card appearance="outlined" >
                                    <mat-card-content class="mat-card-content" >
                                        <mat-form-field appearance="fill">
                                            <mat-label>Id Geral</mat-label>
                                            <input matInput class="input"  [(ngModel)]="painel.idGeral" disabled="true" readonly="true"/>
                                        </mat-form-field>
                                        <app-consulta-customizada maxInput class="form-field-material" [labelDaDescricao]="'Filial'" [identificador]="'cntFilial'"
                                            [campoFiltro]="'CD_FILIAL,NM_FILIAL'" [campoChaveDoFiltro]="'CD_FILIAL'" [lookupExibicao]="['CD_FILIAL', 'NM_FILIAL']"
                                            [complementoConsulta]="''" [possuiItemInicial]="true" [itemInicial]="painel.cdFilial" (itemCosulta)="receberItemConsultaFilialEditar($event, painel)">
                                        </app-consulta-customizada>
                                        <mat-form-field appearance="fill" [formGroup]="painel.form">
                                            <mat-label>Inicio</mat-label>   
                                            <input matInput [matDatepicker]="dataInicialEditar" class="input" formControlName="dataInicio"/>
                                            <mat-datepicker-toggle matSuffix [for]="dataInicialEditar"></mat-datepicker-toggle>
                                            <mat-datepicker class="mat-calendar" #dataInicialEditar></mat-datepicker>        
                                            <mat-error *ngIf="painel.form.get('dataInicio').invalid">Data inválida</mat-error>                                           
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" [formGroup]="painel.form">
                                            <mat-label>Fim</mat-label>                                            
                                            <input matInput [matDatepicker]="dataFinalEditar" class="input" formControlName="dataFim" />
                                            <mat-datepicker-toggle matSuffix [for]="dataFinalEditar"></mat-datepicker-toggle>
                                            <mat-datepicker class="mat-calendar" #dataFinalEditar></mat-datepicker>        
                                            <mat-error *ngIf="painel.form.get('dataFim').invalid">Data inválida</mat-error>                                                  
                                        </mat-form-field>
                                        <app-consulta-customizada maxInput class="form-field-material" [labelDaDescricao]="'Ação'" [identificador]="'cntAcessoAcao'"
                                                [campoFiltro]="'CD_ACESSO,NM_ACAO'" [campoChaveDoFiltro]="'CD_ACESSO'" [lookupExibicao]="['CD_ACESSO', 'NM_ACAO']"
                                                complementoConsulta="fl_classificacao in ('R','G','L')" [possuiItemInicial]="true" 
                                                [itemInicial]="painel.cdAcesso"
                                                (itemCosulta)="receberItemConsultaAcessoEditar($event, painel)">
                                            </app-consulta-customizada>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Ordem</mat-label>
                                            <input matInput class="input" [(ngModel)]="painel.ordem"/>    
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Título da aba</mat-label>
                                            <input matInput class="input" [(ngModel)]="painel.tituloAba" />    
                                        </mat-form-field>
                                        <div class="botao">
                                            <button class="botao-editar-form" mat-flat-button color="primary" (click)="editar(painel)">
                                                Editar
                                            </button>
                                            <button *ngIf="this.rowHeigthEdicao === 635" class="botao-editar-form" mat-flat-button color="warn"(click)="excluir(painel)" >
                                                Excluir
                                            </button>
                                        </div>
                                        <div *ngIf="this.rowHeigthEdicao !== 635" class="botao">
                                            <button class="botao-editar-form" mat-flat-button color="warn" (click)="excluir(painel)">
                                                Excluir
                                            </button>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<app-rodape></app-rodape>
