import { Injectable } from '@angular/core';
import { ClienteService } from '../services/cliente.service';
import { Cliente } from '../persistences/cliente';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';
import { JwtService } from '../jwt.service';
import { SnackbarManager } from './snackbar-manager';
import { ClienteFilial } from '../persistences/cliente-filial';

@Injectable({
  providedIn: 'root'
})
export class ClienteManager {

  constructor(
    private clienteService: ClienteService,
    private clienteSingleton: ClienteSingletonService,
    private jwtService: JwtService,
    private snackBarManager: SnackbarManager,
  ) { }
    
  getClientefromServico(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.clienteService.getClienteServidor()
      .subscribe(
        (responseCliente) => {
          this.clienteService.getClienteFilialServidor(Number.parseInt(localStorage.getItem('filial_business'))).subscribe((responseFilial) => {
            let imgStr = "";
            const clienteFilial = new ClienteFilial(responseFilial);            
            const cliente = new Cliente(responseCliente);
            cliente._setClienteFilial(clienteFilial);
            this.clienteSingleton.atualizarCliente(cliente);            
            if (clienteFilial.getImgStr == "") {
              imgStr = "assets/images/sem_imagem.png";
            }
            else {
              imgStr = "data:image/jpeg;base64," + clienteFilial.getImgStr;
            }
            this.clienteSingleton.atualizarImgClienteFilial(imgStr);
            resolve(true);
          },
          (error) => {
            this.jwtService.validarAcessToken(error)
            this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
            reject(false);
          }
        );          
        },
        (error) => {          
          this.jwtService.validarAcessToken(error)
          this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
          reject(false);
        }
      );
    });    
  }

  getFilialServidor(cdFilial: number): void {
    this.clienteService.getClienteFilialServidor(cdFilial)
      .subscribe(
        (response) => {
          let imgStr = "";
          const clienteFilial = new ClienteFilial(response);
          this.clienteSingleton.atualizarClienteFilial(clienteFilial);
          if (clienteFilial.getImgStr == "") {
            imgStr = "assets/images/sem_imagem.png";
          }
          else {
            imgStr = "data:image/jpeg;base64," + clienteFilial.getImgStr;
          }          
          this.clienteSingleton.atualizarImgClienteFilial(imgStr);
        },
        (error) => {
          this.jwtService.validarAcessToken(error)
          this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
        }
      );
  }

  validarClienteVendedor(): boolean {
    const cliente = this.clienteSingleton.getClienteEstatico();
    if ((cliente.getCdFuncionario === 0) ||
      (cliente.getCdFuncionario == undefined)) {
      this.snackBarManager.openSnackBar(
        "Cliente não possui um vendedor padrão configurado!",
        'Ok',
        3000
      );
      return false;
    }
    return true;
  }

}
