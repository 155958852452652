<app-toolbar #toolbar [pesquisaVisivel]="pesquisaVisivel"></app-toolbar>
  <mat-divider></mat-divider>
<div class="container">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav *ngIf="!isMobileMenu" class="sidenav" mode="side" opened>
      <app-menu-minha-conta></app-menu-minha-conta>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<app-rodape></app-rodape>