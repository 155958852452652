<table class="tabela-preco" [hidden]="outrosPrecosDisponivel()">
  <thead>
    <tr>            
      <th>Qtd. Mínima</th>
      <th>Un. Medida</th>
      <th>Preço liquido</th>      
      <th>Preço + IPI + ICMSST</th>
      <th></th>
      <th>        
        <mat-icon *ngIf="!dialogParent" (click)="closeDialog()">close</mat-icon>      
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tabelaPreco of tabelasDePreco" (click)="getTabelaPrecoEmitter(tabelaPreco);" [ngClass]="{'tabela-selecionada': tabelaPreco.idGeral === tabelaSelecionada}">
      <td>{{tabelaPreco.qtMinima}}</td>
      <td>{{tabelaPreco.unMedida}}</td>
      <td>{{tabelaPreco.precoLiquido.toFixed(2) | currency:'BRL'}}</td>
      <td>{{tabelaPreco.precoLiquidoComIpiIcmsSubstituicao.toFixed(2) | currency:'BRL'}}</td>
      <td><mat-icon style="transform: translateY(4px); cursor: default;" *ngIf="tabelaPreco.flPromocao">discount</mat-icon></td>
      <td>
        <mat-icon style="cursor: default;" [ngClass]="{'mat-icon-select': tabelaPreco.idGeral === tabelaSelecionada}" *ngIf="tabelaPreco.idGeral === tabelaSelecionada">check_circle</mat-icon>
      </td>    
    </tr>
  </tbody>
</table> 