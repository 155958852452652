import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ConsultaCustomizada } from 'src/app/persistences/consulta-customizada';
import { ConsultaCustomizadaService } from './../../../services/consulta-customizada.service';

@Component({
  selector: 'app-consulta-customizada',
  templateUrl: './consulta-customizada.component.html',
  styleUrls: ['./consulta-customizada.component.css']
})

export class ConsultaCustomizadaComponent implements OnInit {
  
  formConsulta: FormGroup;
  public consultaCustomizada = new ConsultaCustomizada;

  @Input()
  labelDaDescricao = 'Descrição';
  @Input()
  identificador: string;
  @Input()
  campoFiltro: string;
  @Input()
  campoChaveDoFiltro: string;  
  @Input()
  complementoConsulta: string;
  @Input()
  itemInicial: string;
  @Input()
  possuiItemInicial: boolean;
  @Input()
  campoRequerido = false; 
  @Input()
  lookupExibicao: string[];
  @Output()
  itemCosulta = new EventEmitter<number>();
  public codigoItem: number;  
  public descricaoItem: string[];
  public indiceAtual:number;
  
  constructor(
    private consultaCustomizadaService: ConsultaCustomizadaService,
    private formBuilder: FormBuilder,
    private snackBarManager: SnackbarManager
  ) {
    this.consultaCustomizada.resultados = [];
    this.formConsulta = this.formBuilder.group({
      codigo: new FormControl(''),
      descricao: new FormControl(''),
    })
    
  }

  ngOnInit(): void {
    if(this.possuiItemInicial){
      this.descricaoItem = [];
      this.pesquisaEspecifica(this.itemInicial);
    }    
  }

  onSearch(termoPesquisado: string) {
    let result: string [];
    this.consultaCustomizada.identificador = this.identificador;
    this.consultaCustomizada.campoFiltro = this.campoFiltro;
    this.consultaCustomizada.complementoConsulta = this.complementoConsulta;
    if (termoPesquisado.length > 0) {
      this.consultaCustomizadaService.getConsulta(termoPesquisado, this.consultaCustomizada).subscribe((resultadosDaPesquisa) => {
        result = resultadosDaPesquisa.map((array) => {       
          const cdAcesso = array.find((item) => item.nmCampo === (this.lookupExibicao[0].toLowerCase()))?.valor;
          const nmAcao = array.find((item) => item.nmCampo === this.lookupExibicao[1].toLowerCase())?.valor;
          return {cdAcesso, descricao:  cdAcesso + " " + nmAcao };
        });
        this.consultaCustomizada.resultados = result;
        this.indiceAtual = 0;
      },
      (error)=>{
        this.snackBarManager.openSnackBar("Não foi possível realizar busca: "+ error.error, "Ok", 3000)
      });
    } else {
      this.consultaCustomizada.resultados = [];
      this.itemCosulta.emit(null);
    }
  }

  pesquisaEspecifica(codigoItemPesquisado: string) { 
    let result = [];   
    if(this.possuiItemInicial){      
      codigoItemPesquisado = this.itemInicial;
      this.consultaCustomizada.identificador = this.identificador;
      this.consultaCustomizada.campoFiltro = this.campoChaveDoFiltro;
      this.consultaCustomizada.complementoConsulta = this.complementoConsulta;  
      if (parseInt(codigoItemPesquisado) > 0 || codigoItemPesquisado.length > 0) {
        this.consultaCustomizadaService.getConsulta(codigoItemPesquisado, this.consultaCustomizada).subscribe((resultadosDaPesquisa) => {          
          result = resultadosDaPesquisa.map((array) => {          
            const cdAcesso = array.find((item) => item.nmCampo === (this.lookupExibicao[0].toLowerCase()))?.valor;
            const nmAcao = array.find((item) => item.nmCampo === this.lookupExibicao[1].toLowerCase())?.valor;
            return {cdAcesso, descricao:  cdAcesso + " " + nmAcao };
          });          
          this.consultaCustomizada.resultados = result;          
          this.selecionarItem(result[0]);
        },
        (error)=>{
          this.snackBarManager.openSnackBar("Não foi possível realizar busca: "+ error.error, "Ok", 3000)
        });
      } else {
        this.consultaCustomizada.resultados = [];
      }
      this.possuiItemInicial = false;
    } else{
      this.onSearch(codigoItemPesquisado)
    }  
  }

  selecionarItem(item) {
    this.descricaoItem = item.descricao;
    this.itemCosulta.emit(item.cdAcesso);
    this.consultaCustomizada.resultados = [];
  }
}
