<div class="container">
	<div class="item">
		<img class="com-imagem" *ngIf="(imagem !== undefined && imagem !== '')" mat-card-image src="{{imagem}}">
		<img class="sem-imagem" *ngIf="(imagem === '')" mat-card-image src="assets/images/no_image.png">		
	</div>
	<div class="item" matTooltip="{{itemDetalhado.itemDetalhes.nmItem}}">
		<div class="sub-item">{{itemDetalhado.itemDetalhes.nmItem}}</div>
		<div class="sub-item"><b>{{itemDetalhado.itemDetalhes.cdItem}}</b></div>
	</div>
	<div class="item">
		<app-quantidade-lista [item]="itemDetalhado" [unMedVenda]="itemDetalhado.itemPedido.unMedVenda"
			[quantidadeItemString]="itemDetalhado.itemPedido.quantidade.toLocaleString('pt-BR',{useGrouping: false})"
			>
		</app-quantidade-lista>
		<div class="valor">
			<div style="display: inline-block; text-align: right"><b>{{itemDetalhado.itemPedido.total | currency:'R$':'code'}}</b></div>
		</div>
	</div>

</div>