import { PedidoItemFlex } from './pedido-item-flex';

export class PedidoFlex {
	public idGeral: number;
	public nrSequencia: number;
	public tpFrete: string;
	public tpDocFatura: string;
	public cdFilial: number;
	public cdCliente: number;
	public cdVendedor: number;
	public cdSituacao: number;
	public cdCondPgto: number;
	public cdFormaPgto: number;
	public cdTpEntrega: number;
	public cdTransportador: number;
	public vlTotal: number;
	public vlFrete: number;
	public vlDesconto: number;
	public vlOutrasDespesas: number;
	public dtPrevisaoEnvio: string;
	public dtEmissao: string;
	public ObservacoesNota: string;
	public ObservacoesInterna: string;
	public versaoRegistro: number;
	public itens: PedidoItemFlex[];
}
