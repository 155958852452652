import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtService } from '../jwt.service';
import { NumberUtils } from '../utils/utils/number-utils';
import { ConfigService } from './config.service';
import { CartSingletonService } from '../singletons/cart-singleton.service';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';

@Injectable({
    providedIn: 'root'
})
export class ItemService {

    idsItensNoCarrinho = [];

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private jwtService: JwtService,
        private clienteSingletonService: ClienteSingletonService,
        private cartSingletonService: CartSingletonService
    ) {

    }

    getImagem(id_item: number, principal: boolean) {

        this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('id', id_item.toString())
            .append('principal', String(principal));
        return this.httpClient.get(
            this.configService.getEndereco() + '/ProdutoFoto/getImgsById',
            {
                headers: this.configService.getHeaders(),
                params: httpParams
            }
        )
    }


    getItemDetalhes(id_item: number) {

        this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('id', NumberUtils.numberToString(id_item))
            .append('cdFilial', NumberUtils.numberToString(this.clienteSingletonService.getClienteEstatico().getClienteFilial.getCdFilial));

        return this.httpClient.get(
            this.configService.getEndereco() + '/Produto/getById',
            {
                headers: this.configService.getHeaders(),
                params: httpParams
            }
        )
    }

    getSimilares(id_item: number) {

        this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
        const carrinho = this.cartSingletonService.getCarrinhoEstatico();
        httpParams = httpParams
            .append('item', NumberUtils.numberToString(id_item))
            .append('cdFormaPagamento', this.cartSingletonService.getFormaECondicaoDePagamento().cdFormaPgto)
            .append('cdCondPgto', this.cartSingletonService.getFormaECondicaoDePagamento().cdCondPgto)
            .append('tipoFrete', carrinho.getTpFrete);
        return this.httpClient.get(
            this.configService.getEndereco() + '/Business/Similares/getByItem',
            {
                headers: this.configService.getHeaders(),
                params: httpParams
            }
        )
    }

    getInfoVeiculo(id_item: number) {

        this.jwtService.validarAcessToken();

        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('id_item', NumberUtils.numberToString(id_item));

        return this.httpClient.get(
            this.configService.getEndereco() + '/ProdutoVeiculo/getDetalhadoById',
            {
                headers: this.configService.getHeaders(),
                params: httpParams
            }
        )
    }
}
