<mat-card class="item-pesquisa-card mat-elevation-z4">
	<mat-chip-listbox class="mat-chip-list-wrapper" color="primary">			
				<p><span class="badgeQTD" *ngIf="quantidadeItem !== 0 && temEstoque" matBadge="{{quantidadeItem}}" 
					matBadgeOverlap="true"></span></p>			
				<mat-chip-option class="valor" *ngIf="quantidadeItem > 0" color="primary" selected><b>Total
					{{valorTotalItem.toFixed(2) | currency:'R$':'code'}}</b>
				</mat-chip-option>
				<mat-chip-option class="semEstoque" *ngIf="!temEstoque && exibeEstoque" color="accent" selected><b>Sem estoque</b>
				</mat-chip-option>
			<div *ngIf="quantidadeItem <= 0"></div>
	</mat-chip-listbox>
	<div class="imagem-container">
	<img *ngIf="(itemPesquisa.imagem !== undefined && itemPesquisa.imagem !== '')" class="imagem-item"
			(click)="imgClick()" mat-card-image src="{{itemPesquisa.imagem}}">
		<img *ngIf="(itemPesquisa.imagem === '')" class="sem-imagem " (click)="imgClick()" mat-card-image
			src="assets/images/no_image.png">
	</div>
	<label class="promocao" color="warn" *ngIf="itemPesquisa.flPromocao && temEstoque">
		PROMOÇÃO
	</label>
	<mat-icon class="similar" *ngIf="temSimilares" (click)="openDialogDetalhes()" matTooltip="PRODUTO POSSUÍ SIMILARES"
		matTooltipPosition="above">filter_none
	</mat-icon>
	<div *ngIf="!temSimilares" style="height:4px ;"></div>
	<mat-card-content style="height: 213px">
		<form>
			<mat-form-field class="campo-item-n1 cor">
				<mat-label>Descrição</mat-label>
				<input class="text-max-size-200" matInput placeholder="-" value="{{itemPesquisa.nmItem}}" disabled>
			</mat-form-field>
			<mat-form-field class="campo-item-n2 cor" [matMenuTriggerFor]="cdItem">
					<mat-label class="label-item-pesquisa">Cd. Item</mat-label>
					<input class="text-max-size-90" matInput placeholder="-"
						value="{{itemPesquisa.cdItem}}" disabled>
				<mat-menu #cdItem="matMenu">
					<div class="fichaDescricao" (click)="$event.stopPropagation()">
						{{itemPesquisa.cdItem.toUpperCase()}}
					</div>
				</mat-menu>
			</mat-form-field>
			<mat-form-field class="campo-item-n2 cor">
				<mat-label>Cd. Fabricante</mat-label>
				<input class="text-max-size-90" matInput placeholder="-" value="{{itemPesquisa.cdFabricante}}" disabled>
			</mat-form-field>
			<mat-form-field class="campo-item-n1 cor">
				<mat-label>Marca</mat-label>
				<input class="text-max-size-150" matInput placeholder="-" value="{{itemPesquisa.nmMarca}}" disabled>
			</mat-form-field>
			<mat-form-field class="campo-item-n1 cor fichaTecnicaCursor" [matMenuTriggerFor]="ficha">
				<mat-label>FICHA TÉCNICA</mat-label>
				<input class="text-max-size-90" matInput placeholder="-" disabled>
				<button class="fichaTec" mat-button [matMenuTriggerFor]="ficha" >
					<mat-icon class="fichaIcon">assignment</mat-icon>
				</button>
				<mat-menu #ficha="matMenu">
					<div [innerHTML]="fichaTecnica" class="fichaDescricao" (click)="$event.stopPropagation()">
					</div>
				</mat-menu>
			</mat-form-field>
			<div class="div-preco-quantidade" *ngIf="quantidadeItem >= 0 && temEstoque && !(itemPesquisa.preco <= 0.0001) ">
				<mat-form-field 
					class="campo-item-n2 cor" style="margin-top: 0px; margin-bottom: 0px;">
	
					<mat-label class="valoUN">Valor</mat-label>
					<input class="text-max-size-90 precoBold" matInput placeholder="-"
						value="{{itemPesquisa.itemDetalhado.itemPedido.valorLiquidoIpiSt.toFixed(2) | currency:'R$':'code'}}" disabled>
				</mat-form-field>
				<mat-form-field 
					class="campo-item-n2 cor" appearance="fill" style="margin-top: 0px; margin-bottom: 0px;" >
					<mat-label class="valoUN">Unidade</mat-label>
						<input  class="text-max-size-90" matInput placeholder="-"
						value="({{itemPesquisa.itemDetalhado.itemPedido.unMedVenda}})" matTooltip="{{itemPesquisa.itemDetalhado.itemPedido.unMedVenda}}" disabled>	
		
				</mat-form-field>
				<button class="no-hover" mat-button matTooltip="Outros preços" (click)="listarPrecosDoItem()" [hidden]="outrosPrecosDisponivel()" style="background-color: #fafafa;">
					<mat-icon>currency_exchange</mat-icon>
				</button>
			</div>

			<div *ngIf="itemPesquisa.pcDesconto > 0 && exibeDesconto">
				<mat-form-field class="campo-item-n1 cor">
					<input class="text-max-size-110" matInput placeholder="-"
						value="Desconto: {{itemPesquisa.pcDesconto.toFixed(2) | currency:'R$':'code'}}%" style="color: red;
						font-weight: bold" disabled>
				</mat-form-field>
			</div>
			 <div [class]="exibe? 'colunas1':'colunas2'">	
			</div>
		</form>
	</mat-card-content>
	<mat-card-actions class="cardActions">
		<div class="margin-bottom-10">
			<app-quantidade-lista 
				[temEstoque]="!item.itemDetalhes.estoqueDisponivel"
				[item]="item"
				[preco]="itemPesquisa.preco"
				[quantidadeItemString]="quantidadeItem.toLocaleString('pt-BR',{useGrouping: false})"
				[unMedVenda]="itemPesquisa.itemDetalhado.itemPedido.unMedVenda">
			</app-quantidade-lista>
		</div>
   		<div style="height: 1px; width: 100%; background-color: black;" ></div>
		<button mat-raised-button style="margin-top: 15px;" color="primary" class="botao-visualizar" (click)="openDialogDetalhes()">
			Visualizar Item
		</button>
	</mat-card-actions>
</mat-card>
