export class CtaCondPgto {
    public  flPadrao: boolean;
    public  cdCondPgto: number;
    public  nmCondPgto: string;
    public  hierarquia: number;
    public  diaDoMes: number;
    public  diaSemana: number;
    public  modalidade: string;
    public  nrParcelas: number;
    public  vlMinimoParcela: number;
    public  prazoInicial: number;
    public  nrDiasPrazoMaximo: number;

    constructor(json?: any) {
        if (json) {
            this.flPadrao = json.flPadrao;
            this.cdCondPgto = json.cdCondPgto;
            this.nmCondPgto = json.nmCondPgto;
            this.hierarquia = json.hierarquia;
            this.diaDoMes = json.diaDoMes;
            this.diaSemana = json.diaSemana;
            this.modalidade = json.modalidade;
            this.nrParcelas = json.nrParcelas;
            this.vlMinimoParcela = json.vlMinimoParcela;
            this.prazoInicial = json.prazoInicial;
            this.nrDiasPrazoMaximo = json.nrDiasPrazoMaximo;
        } 
    }
}
