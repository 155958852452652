export class AcessoAcao {
    cdAcesso = "";
    listaAcessoAcaoRelFiltro: AcessoAcaoFiltro[] = [];   
}

export class AcessoAcaoFiltro {
    parametro = "";
    valor = "";

    constructor (parametro: string, valor: string){
        this.parametro = parametro;
        this.valor = valor;
    }
}