<mat-toolbar class="grid-container" color="primary">	
	<div class="item item1">
		<img class="borda-logo" *ngIf="(this.cliente.getClienteFilial !== undefined)"
			src="{{logoEmpresa}}" (click)="getRouterLink()" >
	</div>
	<div *ngIf="logoMini">
		<img class="borda-logo logo-imagem-mini" src="assets/images/logo_mini.png"(click)="getRouterLink()">
	</div>	
	<div class="item item3" *ngIf="isCliente && pesquisaVisivel && temAcesso">
		<app-search-bar #searchbar (idsPesquisaEmitter)="emitIdsPesquisa($event)"
			(isLoadingEmitter)="emitIsLoading($event)" (ocultaAbasEmitter)="emitOcultarAbas($event)">
		</app-search-bar>
	</div>
	<div [ngClass]="(temAcesso) ? 'item4':'item5'" class="item">
		<app-menu [nomeUsuario]="nomeUsuario" [isCliente]="isCliente"></app-menu>
	</div>
	<div class="item item5" *ngIf="isCliente && temAcesso">
		<app-carrinho-menu #cartmenu></app-carrinho-menu>
	</div>
</mat-toolbar>