import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Item from '../persistences/item';
import { ItemDetalhado } from '../persistences/item-detalhado';
import { ItemDetalhes } from '../persistences/item-detalhes';
import { ItemPedido } from '../persistences/item-pedido';
import { ItemSimplesView } from '../persistences/item-simples-view';
import { Preco } from '../persistences/preco';
import { ProdutoUnidade } from '../persistences/produto-unidade';
import { ConfigService } from '../services/config.service';
import { ItemService } from '../services/item.service';
import { CartSingletonService } from '../singletons/cart-singleton.service';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';
import { NumberUtils } from '../utils/utils/number-utils';
import { IQuantidadeMinima } from './../interfaces/iquantidade-minima';
import { PesquisaService } from './../services/pesquisa.service';
import { SnackbarManager } from './snackbar-manager';
import { Cliente } from '../persistences/cliente';

@Injectable({
    providedIn: 'root',
})

export class ItemManager {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    private eventSubject = new Subject<ItemDetalhado>();
    private eventHandled = false;
    private iQuantidadeMinima: IQuantidadeMinima;

    constructor(
        private cartSingletonService: CartSingletonService,
        private itemService: ItemService,
        private clienteSingleton: ClienteSingletonService,        
        private configService: ConfigService,
        private pesquisaService: PesquisaService,
        private snackBarManager: SnackbarManager,
    ) {
        this.iQuantidadeMinima = {
            minimo: 0,
            minimoControle: 0
        }
    }

    atualizarQuantidadeItem(itemDetalhado: ItemDetalhado) {
        if (itemDetalhado.itemPedido.quantidade != null) {
            if (itemDetalhado.itemPedido.quantidade > itemDetalhado.itemDetalhes.qtDisponivel) {
                itemDetalhado.itemPedido.quantidade = itemDetalhado.itemDetalhes.qtDisponivel
            }
            this.getValor(itemDetalhado);
            itemDetalhado.itemPedido.total = NumberUtils.roundTo((itemDetalhado.itemPedido.valorLiquidoIpiSt * ((itemDetalhado.itemPedido.quantidade == null) ? 0 : itemDetalhado.itemPedido.quantidade)), 2);
        }
    }

    recalculaItem(item: ItemDetalhado) {
        const quantidade: number = item.itemPedido.quantidade;
        const vlIcmsSt: number = +NumberUtils.roundTo(item.itemPedido.vlIcmsSt, 2);
        const vlIpi: number = +NumberUtils.roundTo(item.itemPedido.vlIpi, 2);
        item.itemPedido.valorLiquidoIpiSt = NumberUtils.roundTo(item.itemPedido.preco  * (1 + this.clienteSingleton.getClienteEstatico().getPcMarkup / 100) *
                (1 - item.itemPedido.pcDesconto / 100), 2) + NumberUtils.roundTo((vlIcmsSt + vlIpi), 2);
        const total: number = +NumberUtils.roundTo(item.itemPedido.valorLiquidoIpiSt * quantidade, 2);
        item.itemPedido.total = total;
    }

    getQuantidade(id: number): number {
        const item: ItemDetalhado = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem == id);
        if (item != undefined) {
            return item.itemPedido.quantidade;
        }
        return 0;
    }

    getPrecoTributacao(item: ItemDetalhado): Preco {
        item.itemDetalhes.outrosPrecos = this.removerTabelasDePrecoDuplicas(item);
        let outroPrecoSelecionado: Preco;
        const unMedVenda = item.itemPedido.unMedVenda === undefined ? item.itemDetalhes.unMedVendaPadrao : item.itemPedido.unMedVenda;
        const quantidadeItem = this.getQuantidadeItem(item);
        const outrosPrecosFiltrados = item.itemDetalhes.outrosPrecos.filter(outrosPrecos => outrosPrecos.unMedida === unMedVenda);
        outrosPrecosFiltrados.sort((a,b) => a.qtMinima - b.qtMinima)
        for (let i = 0; i < outrosPrecosFiltrados.length; i++) {
            const qtMinimaAtual = outrosPrecosFiltrados[i].qtMinima;
            const qtMinimaProximo = i + 1 < outrosPrecosFiltrados.length ? outrosPrecosFiltrados[i + 1].qtMinima : Number.POSITIVE_INFINITY;
            if (quantidadeItem >= qtMinimaAtual && quantidadeItem < qtMinimaProximo) {
                outroPrecoSelecionado = outrosPrecosFiltrados[i];
            }
        }
        if(outroPrecoSelecionado === undefined){
            for (let i = 0; i < outrosPrecosFiltrados.length; i++) {
                const qtMinimaAtual = outrosPrecosFiltrados[i].qtMinima;
                if (quantidadeItem >= qtMinimaAtual) {
                    outroPrecoSelecionado = outrosPrecosFiltrados[i];
                }
            }  
        }
        if(outroPrecoSelecionado === undefined && quantidadeItem === 0){
            return  item.itemDetalhes.outrosPrecos[0];
        }
        return outroPrecoSelecionado;
    }

    calcularVlPrecoTabelaAntigo(item: ItemDetalhado) {
        const cliente: Cliente = this.clienteSingleton.getClienteEstatico();
        item.itemPedido.vlPrecoTabelaAntigo = NumberUtils.roundTo(
            item.itemPedido.preco *
            (1 + cliente.getPcMarkup / 100) *
            (1 - item.itemPedido.pcDesconto / 100),
        2);
    }
    
    getValor(item: ItemDetalhado) {
        const itemTributacao = this.getPrecoTributacao(item);
        if (itemTributacao !== undefined) {
            const cliente: Cliente = this.clienteSingleton.getClienteEstatico();
            item.itemPedido.idTabelaPreco = itemTributacao.idGeral;            
            item.itemPedido.valorLiquidoIpiSt = itemTributacao.preco;
            item.itemPedido.preco = itemTributacao.preco;
            item.itemPedido.idTabelaPreco = itemTributacao.idGeral;
            item.itemPedido.flPromocao = itemTributacao.flPromocao;
            item.itemPedido.pcDesconto = itemTributacao.pcMaxDescontoAut;
            item.itemPedido.unMedVenda = itemTributacao.unMedida;
            item.itemPedido.vlPrecoTabelaNovo = NumberUtils.roundTo(
                item.itemPedido.preco *
                (1 + cliente.getPcMarkup / 100) *
                (1 - item.itemPedido.pcDesconto / 100),
                2
            );

            item.itemPedido.valorLiquido = item.itemPedido.preco * (1 + cliente.getPcMarkup / 100);
            if (item.itemPedido.pcDesconto != undefined)
                item.itemPedido.valorLiquido = NumberUtils.roundTo(
                    item.itemPedido.valorLiquido *
                    (1 - item.itemPedido.pcDesconto / 100),
                    2
                );            
            const ipi = item.itemDetalhes.tributacaoView.ipi;
            const icmsSt = item.itemDetalhes.tributacaoView.icmSubst;
            if (ipi != undefined) {
                item.itemPedido.vlIpi = NumberUtils.roundTo(
                    ipi.valor,
                    2
                );
            }
            if (icmsSt != undefined) {
                item.itemPedido.vlIcmsSt = NumberUtils.roundTo(
                    icmsSt.valor,
                    2
                );
            }
            this.recalculaItem(item);
        }

    }    

    public converterItensParaItensSimples(itemsRetornados: Item[]): ItemSimplesView[] {

        const itemSimplesViewMapeado = itemsRetornados.map((itemRetornado, index) => {
            const itemSimplesView: ItemSimplesView = new ItemSimplesView();

            itemSimplesView.idItem = itemRetornado.idItem;
            itemSimplesView.cdItem = itemRetornado.cdItem;
            itemSimplesView.cdFabricante = itemRetornado.cdFabricante;
            itemSimplesView.nmItem = itemRetornado.nmItem;
            itemSimplesView.nmMarca = itemRetornado.nmMarca;
            itemSimplesView.temEstoque = itemRetornado.estoqueDisponivel;
            itemSimplesView.preco = itemRetornado.tabelaPrecoView.precoLiquido !== undefined
                ? NumberUtils.roundTo(itemRetornado.tabelaPrecoView.precoLiquido, 2) : 0;
            itemSimplesView.pcDesconto =
                itemRetornado.tabelaPrecoView.pcMaxDescontoAut;
            itemSimplesView.pcMarkup =
                itemRetornado.tabelaPrecoView.pcMarckupEcommerce;
            itemSimplesView.vl_unitario_liquido =
                itemRetornado.tabelaPrecoView.precoLiquidoComIpiIcmsSubstituicao;
            itemSimplesView.idTabelaPrecoProduto =
                itemRetornado.tabelaPrecoView.idGeral;
            itemSimplesView.unMedVenda = itemRetornado.unMedVenda;
            itemSimplesView.flVendeFracionado = itemRetornado.flVendeFracionado;
            itemSimplesView.itemDetalhado.itemDetalhes.fatorConversao = itemRetornado.tabelaPrecoView.fatorConversao;
            itemSimplesView.imagem = '';
            itemSimplesView.flPromocao =
                itemRetornado.tabelaPrecoView.flPromocao;
            itemSimplesView.itemDetalhado.itemDetalhes =
                this.converterItemParaItemDetalhes(itemRetornado);
            itemSimplesView.itemDetalhado.itemPedido =
                this.converterItemParaItemPedido(itemRetornado);
            itemSimplesView.temSimilares = itemRetornado.temSimilares;
            itemSimplesView.defaultIndex = index;
            itemSimplesView.compativel = itemRetornado.compativel;

            return itemSimplesView;
        });
        return itemSimplesViewMapeado;

    }

    public unidadeProduto: ProdutoUnidade;
    public converterItemParaItemDetalhes(itemRetornado: Item): ItemDetalhes {
        const itemDetalhe: ItemDetalhes = new ItemDetalhes();
        
        itemRetornado.produtoUnidade.find(unidadeProduto => {
            if(unidadeProduto.unMedida === itemRetornado.unMedVenda)
              this.unidadeProduto = unidadeProduto;
        });
        itemDetalhe.idItem = itemRetornado.idItem;
        itemDetalhe.cdItem = itemRetornado.cdItem;
        itemDetalhe.cdOriginal = itemRetornado.cdOriginal;
        itemDetalhe.cdFabricante = itemRetornado.cdFabricante;
        itemDetalhe.nmItem = itemRetornado.nmItem;
        itemDetalhe.nmReduzido = itemRetornado.nmReduzido;
        itemDetalhe.fichaTecnica = itemRetornado.obsFichaTecnica;
        itemDetalhe.qtAtual = itemRetornado.qtAtual;
        itemDetalhe.qtDisponivel = itemRetornado.qtDisponivelParaVenda;
        itemDetalhe.estoqueDisponivel = itemRetornado.estoqueDisponivel;
        itemDetalhe.unMedVendaPadrao = itemRetornado.unMedVenda;
        itemDetalhe.unMedEstoque = itemRetornado.unMedEstoque;
        itemDetalhe.flVendeFracionado = itemRetornado.flVendeFracionado;
        itemDetalhe.cdsConversao = null;
        itemDetalhe.infoVeiculo = null;
        itemDetalhe.tabelaPrecoView = itemRetornado.tabelaPrecoView;
        itemDetalhe.outrosPrecos = itemRetornado.outrosPrecos;
        itemDetalhe.tributacaoView = itemRetornado.tributacaoView;
        itemDetalhe.produtoUnidade = itemRetornado.produtoUnidade;
        itemDetalhe.nmMarca = itemRetornado.nmMarca;
        itemDetalhe.cdMarca = itemRetornado.cdMarca;
        itemDetalhe.temSimilares = itemRetornado.temSimilares;
        itemDetalhe.fatorConversao = this.unidadeProduto.fatorConversao;
        itemDetalhe.valorMultiplo = this.unidadeProduto.multiplo;
        itemDetalhe.qtdMin = this.unidadeProduto.minimo;
        return itemDetalhe;
    }

    private converterItemParaItemPedido(itemRetornado: Item): ItemPedido {
        const itemPedido: ItemPedido = new ItemPedido();

        itemPedido.idItem = itemRetornado.idItem;
        itemPedido.quantidade = 0;
        itemPedido.quantidadeEstoque = itemRetornado.qtAtual;
        itemPedido.idTabelaPreco =
            itemRetornado.tabelaPrecoView?.idGeral !== undefined
                ? itemRetornado.tabelaPrecoView.idGeral
                : 0;

        itemPedido.preco =
            itemRetornado.tabelaPrecoView?.idGeral !== undefined ||
                itemRetornado.tabelaPrecoView?.preco !== undefined
                ? NumberUtils.roundTo(itemRetornado.tabelaPrecoView.preco, 2) : 0;

        itemPedido.vlIpi = itemRetornado.tributacaoView.ipi?.valor;
        itemPedido.vlIcmsSt = itemRetornado.tributacaoView.icmSubst?.valor;
        itemPedido.total = 0;
        itemPedido.flPromocao = itemRetornado.tabelaPrecoView.flPromocao;
        itemPedido.valorLiquido =
            itemRetornado.tabelaPrecoView?.idGeral !== undefined ||
                itemRetornado.tabelaPrecoView?.precoLiquido !== undefined
                ? NumberUtils.roundTo(itemRetornado.tabelaPrecoView.precoLiquido, 2) : 0;

        itemPedido.valorLiquidoIpiSt =
            itemRetornado.tabelaPrecoView.idGeral !== undefined ||
                itemRetornado.tabelaPrecoView.precoLiquidoComIpiIcmsSubstituicao !== undefined
                ? NumberUtils.roundTo(itemRetornado.tabelaPrecoView.precoLiquidoComIpiIcmsSubstituicao, 2) : 0;

        itemPedido.pcDesconto = itemRetornado.tabelaPrecoView.pcMaxDescontoAut;
        itemPedido.pcMarkup = itemRetornado.tabelaPrecoView.pcMarckupEcommerce;
        itemPedido.unMedVenda = itemRetornado.unMedVenda;

        return itemPedido;
    }

    getListaDeItens(idsPesquisados: number[], listaItens: ItemSimplesView[]): Promise<ItemSimplesView[]> {
        return new Promise((resolve) => {
            this.pesquisaService
                .carregaItensByIds(idsPesquisados)
                .subscribe((retornoItems) => {
                    const compativeis = this.pesquisaService.getProdutosCompativeis();
                    if (compativeis != undefined && retornoItems != undefined) {
                        retornoItems.forEach(item => {
                            compativeis.forEach(id => {
                                if (id == item.idItem) {
                                    item.compativel = true;
                                } else {
                                    item.compativel = false;
                                }

                            })
                        },
                            (error: HttpErrorResponse) => {
                                this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
                            });
                        listaItens = this.converterItensParaItensSimples(retornoItems);
                        this.orderBy(this.ordenacaoDefault,
                            listaItens);
                        resolve(listaItens);
                    }
                });
        });
    }

    orderBy(order: string, list: ItemSimplesView[]): ItemSimplesView[] {
        this.configService.configuraOrdenacao(order);
        let lista: ItemSimplesView[] = list;

        switch (order) {
            case 'nome':
                lista = this.compararPorNome(lista);
                break;
            case 'codigoItem':
                lista = this.compararPorCodigoItem(lista);
                break;
            case 'preco':
                lista = this.compararPorPreco(lista);
                break;
            case 'codigoFabricante':
                lista = this.compararPorCodigoFabricante(lista);
                break;
            default:
                lista = this.compararPorDefaultIndex(lista);
                break;
        }
        list.splice(0, list.length);
        lista.forEach(item => {
            list.push(item);
        });
        return list;
    }

    compararPorNome(list: ItemSimplesView[]): ItemSimplesView[] {
        const disponiveis: ItemSimplesView[] = [];
        const indisponiveis: ItemSimplesView[] = [];
        const compativeis: ItemSimplesView[] = [];
        list.forEach(item => {
            if (item.compativel) {
                compativeis.push(item);
            } else {
                if ((item.preco > 0) && (item.temEstoque)) {
                    disponiveis.push(item);
                } else {
                    indisponiveis.push(item);
                }
            }
        });

        disponiveis.sort((a, b) => a.nmItem < b.nmItem ? -1 : 1);
        indisponiveis.sort((a, b) => a.nmItem < b.nmItem ? -1 : 1);
        compativeis.sort((a, b) => a.nmItem < b.nmItem ? -1 : 1);

        list = [];

        list = list.concat(compativeis);
        list = list.concat(disponiveis);
        list = list.concat(indisponiveis);

        return list;
    }

    compararPorCodigoItem(list: ItemSimplesView[]): ItemSimplesView[] {
        const disponiveis: ItemSimplesView[] = [];
        const indisponiveis: ItemSimplesView[] = [];
        const compativeis: ItemSimplesView[] = [];
        list.forEach(item => {
            if (item.compativel) {
                compativeis.push(item);
            } else {
                if ((item.preco > 0) && (item.temEstoque)) {
                    disponiveis.push(item);
                } else {
                    indisponiveis.push(item);
                }
            }
        });

        disponiveis.sort((a, b) => a.cdItem < b.cdItem ? -1 : 1);
        indisponiveis.sort((a, b) => a.cdItem < b.cdItem ? -1 : 1);
        compativeis.sort((a, b) => a.cdItem < b.cdItem ? -1 : 1);

        list = [];

        list = list.concat(compativeis);
        list = list.concat(disponiveis);
        list = list.concat(indisponiveis);
        return list;
    }

    compararPorPreco(list: ItemSimplesView[]): ItemSimplesView[] {
        const disponiveis: ItemSimplesView[] = [];
        const indisponiveis: ItemSimplesView[] = [];
        const compativeis: ItemSimplesView[] = [];
        list.forEach(item => {
            if (item.compativel) {
                compativeis.push(item);
            } else {
                if ((item.preco > 0) && (item.temEstoque)) {
                    disponiveis.push(item);
                } else {
                    indisponiveis.push(item);
                }
            }
        });

        disponiveis.sort((a, b) => a.preco < b.preco ? -1 : 1);
        indisponiveis.sort((a, b) => a.preco < b.preco ? -1 : 1);
        compativeis.sort((a, b) => a.preco < b.preco ? -1 : 1);

        list = [];

        list = list.concat(compativeis);
        list = list.concat(disponiveis);
        list = list.concat(indisponiveis);

        return list;
    }

    compararPorCodigoFabricante(list: ItemSimplesView[]): ItemSimplesView[] {
        const disponiveis: ItemSimplesView[] = [];
        const indisponiveis: ItemSimplesView[] = [];
        const compativeis: ItemSimplesView[] = [];
        list.forEach(item => {
            if (item.compativel) {
                compativeis.push(item);
            } else {
                if ((item.preco > 0) && (item.temEstoque)) {
                    disponiveis.push(item);
                } else {
                    indisponiveis.push(item);
                }
            }
        });

        disponiveis.sort((a, b) => a.cdFabricante < b.cdFabricante ? -1 : 1);
        indisponiveis.sort((a, b) => a.cdFabricante < b.cdFabricante ? -1 : 1);
        compativeis.sort((a, b) => a.cdFabricante < b.cdFabricante ? -1 : 1);

        list = [];

        list = list.concat(compativeis);
        list = list.concat(disponiveis);
        list = list.concat(indisponiveis);

        return list;
    }

    compararPorDefaultIndex(list: ItemSimplesView[]): ItemSimplesView[] {
        const disponiveis: ItemSimplesView[] = [];
        const indisponiveis: ItemSimplesView[] = [];
        const compativeis: ItemSimplesView[] = [];
        list.forEach(item => {
            if (item.compativel) {
                compativeis.push(item);
            } else {
                if ((item.preco > 0) && (item.temEstoque)) {
                    disponiveis.push(item);
                } else {
                    indisponiveis.push(item);
                }
            }
        });

        disponiveis.sort((a, b) => a.defaultIndex < b.defaultIndex ? -1 : 1);
        indisponiveis.sort((a, b) => a.defaultIndex < b.defaultIndex ? -1 : 1);
        compativeis.sort((a, b) => a.defaultIndex < b.defaultIndex ? -1 : 1);
        list = [];

        list = list.concat(compativeis);
        list = list.concat(disponiveis);
        list = list.concat(indisponiveis);

        return list;

    }

    get ordenacaoDefault(): string {
        return this.configService.getConfig().getOrdenacaoDefault;
    }

    promiseGetImage(listaItens: ItemSimplesView[], listaIdsItens: number[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                listaItens.forEach((item) => {
                    if (listaIdsItens.includes(item.idItem)) {
                        this.ngUnsubscribe.next();
                        return;
                    }
                    this.itemService
                        .getImagem(item.idItem, true)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((imagem: string) => {
                            if (imagem == '') {
                                item.imagem = '';
                            } else {
                                item.imagem =
                                    'data:image/jpeg;base64,' + imagem;
                            }
                        },
                            (error: HttpErrorResponse) => {
                                this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
                            });
                });
                resolve(true);
            } catch {
                reject(false);
            }
        });
    }

    getMinimoProdutoUnidade(item: ItemDetalhado): number {
        const unMedVenda = item.itemPedido.unMedVenda === undefined ? item.itemDetalhes.tabelaPrecoView.unMedida : item.itemPedido.unMedVenda;
        const unidadeFiltrada = item.itemDetalhes.produtoUnidade.filter(produtoUnidade => produtoUnidade.unMedida === unMedVenda);        
        unidadeFiltrada.sort((a, b) => a.minimo - b.minimo);
        return unidadeFiltrada[0].minimo > 0 ? unidadeFiltrada[0].minimo : 1;
    }

    getMinimoOutrosPrecos(item: ItemDetalhado): Preco {
        const unMedVenda = item.itemPedido.unMedVenda === undefined ? item.itemDetalhes.unMedVendaPadrao : item.itemPedido.unMedVenda;
        const outrosPrecosFiltrados = item.itemDetalhes.outrosPrecos.filter(outrosPrecos => outrosPrecos.unMedida === unMedVenda);
        outrosPrecosFiltrados.sort((a, b) => a.qtMinima - b.qtMinima);
        return outrosPrecosFiltrados[0];
    }

    getMinimoTabelaSelecionada(item: ItemDetalhado): number {        
        const unMedVenda = item.itemPedido.idTabelaPreco === undefined ? item.itemDetalhes.tabelaPrecoView.idGeral : item.itemPedido.idTabelaPreco;
        const outrosPrecosFiltrados = item.itemDetalhes.outrosPrecos.filter(outrosPrecos => outrosPrecos.idGeral === unMedVenda);                
        const minimoTabela = outrosPrecosFiltrados[0].qtMinima;        
        return minimoTabela;        
    }  

    getMinimoSelecionado(item: ItemDetalhado): number{
        const tabelaSelecionada = this.getMinimoTabelaSelecionada(item);        
        let minimoTabelaSelecionada = tabelaSelecionada > 0 ? tabelaSelecionada : this.getMultiplo(item);
        const multiplo = this.getMultiplo(item);
        if (minimoTabelaSelecionada % multiplo != 0) {
            minimoTabelaSelecionada = Math.ceil(minimoTabelaSelecionada / multiplo) * multiplo;
            return minimoTabelaSelecionada;
        }
        return minimoTabelaSelecionada;
    }

    calcularResto(item: ItemDetalhado): number {
        const quantidadeAjustada = Math.round(item.itemPedido.quantidade * 100);
        const multiploAjustado = Math.round(this.getMultiplo(item) * 100);
        const resto = quantidadeAjustada % multiploAjustado;
        return resto / 100;
    }

    getMinimo(item: ItemDetalhado): IQuantidadeMinima {                
        const minimoTabelaSelecionada = this.getMinimoSelecionado(item);
        let minimo = minimoTabelaSelecionada;
        const ignorarValidacaoDeMultiplo = item.itemDetalhes.flVendeFracionado === "S";
        let multiplo = this.getMultiplo(item);
        if(minimo % multiplo != 0){
            minimo = Math.ceil(minimo/multiplo) * multiplo;
            multiplo = minimo;            
        }
        let minimoControle = this.getMinimoControle(item);        
        if(minimoControle % multiplo != 0){
            minimoControle = Math.ceil(minimoControle/multiplo) * multiplo;
        }                
        minimo = (minimo > 0 && minimo >= multiplo) ? minimo : multiplo > 0 ? multiplo : ignorarValidacaoDeMultiplo ? 0.001 : 1;        
        this.iQuantidadeMinima.minimo = minimo;
        this.iQuantidadeMinima.minimoControle = minimoControle;
        return this.iQuantidadeMinima;
    }    
    
    getMinimoControle(item: ItemDetalhado): number {        
        const produtoUnidade = this.getMinimoProdutoUnidade(item);    
        const outrosPrecos = this.getMinimoOutrosPrecos(item);
        const tabelaSelecionada = this.getMinimoTabelaSelecionada(item);
        const unQtTabelaSelecionada = tabelaSelecionada > 0 ? tabelaSelecionada : 1;
        const unQtProduto = produtoUnidade > 0 ? produtoUnidade : unQtTabelaSelecionada;
        const unQtOutrosPrecos = outrosPrecos.qtMinima > 0 ? outrosPrecos.qtMinima : unQtTabelaSelecionada;
        let minimoDeControle;
        minimoDeControle = Math.min(unQtTabelaSelecionada, unQtOutrosPrecos)
        minimoDeControle < unQtProduto? minimoDeControle = unQtProduto : minimoDeControle    
        const multiplo = item.itemDetalhes.valorMultiplo > 0 ? item.itemDetalhes.valorMultiplo : 1;        
        if(minimoDeControle % multiplo != 0)
          return Math.ceil(minimoDeControle / multiplo) * multiplo;
        return minimoDeControle;
    }    

    getMultiplo(item: ItemDetalhado): number {
        const unMedVenda = item.itemPedido.unMedVenda === undefined ? item.itemDetalhes.unMedVendaPadrao : item.itemPedido.unMedVenda;
        const produtoUnidadeFiltrados = item.itemDetalhes.produtoUnidade.filter(produtoUnidade => produtoUnidade.unMedida === unMedVenda);
        produtoUnidadeFiltrados.sort((a, b) => a.minimo - b.minimo);
        let multiplo = produtoUnidadeFiltrados[0].multiplo;
        multiplo = (multiplo >= 0.001 && multiplo <= 0.999 || multiplo > 1) ? multiplo : 1;
        return multiplo;
    }

    ignorarValidacaoDeMultiplo(item: ItemDetalhado): boolean {        
        return item.itemDetalhes.flVendeFracionado === 'S' && this.getMultiplo(item) < 1;
    }

    getQuantidadeItem(item: ItemDetalhado) {
        return item.itemPedido.quantidade >= 0 ? item.itemPedido.quantidade : this.getMultiplo(item);
    }

    selecionarTabelaDePrecoDoItem(tabelaPreco: Preco, itemDetalhado: ItemDetalhado): ItemPedido {
        itemDetalhado.itemPedido.idTabelaPreco = tabelaPreco.idGeral;
        itemDetalhado.itemPedido.preco = tabelaPreco.preco;
        itemDetalhado.itemPedido.unMedVenda = tabelaPreco.unMedida;
        const minimoItem = this.getMinimo(itemDetalhado)
        minimoItem.minimo > minimoItem.minimoControle? itemDetalhado.itemPedido.quantidade = minimoItem.minimo : itemDetalhado.itemPedido.quantidade = minimoItem.minimoControle;
        itemDetalhado.itemPedido.vlPrecoTabelaAntigo = tabelaPreco.preco;
        itemDetalhado.itemPedido.preco = tabelaPreco.preco;
        itemDetalhado.itemPedido.valorLiquido = tabelaPreco.precoLiquido;
        itemDetalhado.itemPedido.valorLiquidoIpiSt = tabelaPreco.precoLiquidoComIpiIcmsSubstituicao;
        itemDetalhado.itemPedido.vlPrecoTabelaNovo = tabelaPreco.preco;
        itemDetalhado.itemPedido.total = itemDetalhado.itemPedido.valorLiquidoIpiSt * itemDetalhado.itemPedido.quantidade;
        return itemDetalhado.itemPedido;
    }

    atualizarTabelaDePrecoDoItem(itemPedido: ItemDetalhado) {
        this.setEventHandled(false);
        this.emitEvent(itemPedido);
    }

    emitEvent(itemDetalhado: ItemDetalhado) {
        this.eventSubject.next(itemDetalhado);
    }

    getEvent() {
        return this.eventSubject.asObservable();
    }

    getEventObservable(): Observable<ItemDetalhado> {
        return this.eventSubject.asObservable();
    }

    isEventHandled(): boolean {
        return this.eventHandled;
    }

    setEventHandled(isBoolean: boolean) {
        this.eventHandled = isBoolean;
    }
    
    removerTabelasDePrecoDuplicas(itemDetalhado: ItemDetalhado): Preco[] {
        if(itemDetalhado.itemDetalhes.outrosPrecos){
            if(itemDetalhado.itemDetalhes.outrosPrecos.length <= 1){
                return itemDetalhado.itemDetalhes.outrosPrecos
            }
        }
        const outrosPrecos = [];
        itemDetalhado.itemDetalhes.outrosPrecos.filter(item => {
            if(itemDetalhado.itemDetalhes.qtDisponivel >= item.qtMinima){
                outrosPrecos.push(item);
            }
        });
        itemDetalhado.itemDetalhes.outrosPrecos = outrosPrecos;
        const idTabelaPreco = itemDetalhado.itemPedido === undefined ?
        itemDetalhado.itemPedido.idTabelaPreco : itemDetalhado.itemDetalhes.tabelaPrecoView.idGeral;
        if ((itemDetalhado.itemDetalhes.outrosPrecos === null || itemDetalhado.itemDetalhes.outrosPrecos.length === 0)) {
            const result = []     
            result.push(itemDetalhado.itemDetalhes.tabelaPrecoView)
            return result;  
        }
        let tabelaPreco = itemDetalhado
                            .itemDetalhes
                            .outrosPrecos
                            .find((tabelaPreco) => tabelaPreco.idGeral === idTabelaPreco);
        if(!tabelaPreco)
            tabelaPreco = itemDetalhado.itemDetalhes.outrosPrecos[0];
        const keyPadrao = `${tabelaPreco.qtMinima}_${tabelaPreco.unMedida}`;
 
        const uniqueItems: { [key: string]: Preco } = {};
        for (const item of itemDetalhado.itemDetalhes.outrosPrecos) {
            const key = `${item.qtMinima}_${item.unMedida}`;
            if(!uniqueItems[key] && keyPadrao === key){
                uniqueItems[key] = item;
            }
            if (keyPadrao !== key && item.precoLiquido !== 0 && item.precoLiquidoComIpiIcmsSubstituicao !== 0) {
                if (!uniqueItems[key]) {
                    uniqueItems[key] = item;
                }
            }
        } 
        if (Object.keys(uniqueItems).length === 0)
            return null;
        let result = [tabelaPreco, ...Object.values(uniqueItems)];  
        result = result.filter(
                    (item, index, self) => self.findIndex(i => i.idGeral === item.idGeral) === index
                );
        result = result.sort((a,b) => a.precoLiquidoComIpiIcmsSubstituicao - b.precoLiquidoComIpiIcmsSubstituicao)
        return result;
    }
}