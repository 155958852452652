import { Injectable } from '@angular/core';
import { AcessoAcaoService } from '../services/acesso-acao.service';
import { JwtService } from '../jwt.service';
import { AcessoAcao, AcessoAcaoFiltro } from './../persistences/acesso-acao';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarManager } from './snackbar-manager';
import { ConfigService } from './../services/config.service';
import { Config } from '../persistences/config';
import { ClienteSingletonService } from './../singletons/cliente-singleton.service';

@Injectable({
	providedIn: 'root'
})

export class AcessoAcaoManager {
	private acessoAcao: AcessoAcao = new AcessoAcao();

	constructor(
		private acessoAcaoService: AcessoAcaoService,
		private jwtService: JwtService,
		private snackBarManager: SnackbarManager,
		private configService: ConfigService,
		private clienteSingletonService: ClienteSingletonService
	) { }

	getIdsConsultaAcessoAcao(cdAcesso: string): Promise<number[]> {
		const ids = [];
		this.acessoAcao.listaAcessoAcaoRelFiltro = []; 
		this.acessoAcao.cdAcesso = cdAcesso;
		const cliente = this.clienteSingletonService.getClienteEstatico();
		this.acessoAcao.listaAcessoAcaoRelFiltro.push(new AcessoAcaoFiltro("cd_cliente", cliente.getCdCliente.toString()));
		this.acessoAcao.listaAcessoAcaoRelFiltro.push(new AcessoAcaoFiltro("cd_filial", cliente.getClienteFilial.getCdFilial.toString()));
		return new Promise((resolve, reject) => {
			try {
				this.acessoAcaoService.getIdsConsultaAcessoAcao(this.acessoAcao)
					.subscribe(
						(result) => {
							result.forEach(item => {
								ids.push(item[0].valor);
							});
							resolve(ids);
						},
						(error: HttpErrorResponse) => {
							(error) => this.jwtService.validarAcessToken(error)
							this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
						});
			} catch (error) {
				this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
				reject(this.jwtService.validarAcessToken(error));
			}
		})
	}
}
