import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { JwtService } from 'src/app/jwt.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
		private router: Router,

  ) { }

  ngOnInit () {
		if (JwtService.loggedIn()) {
			this.router.navigate(['/home']);
		}
  }

}
