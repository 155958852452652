<mat-card [formGroup]="formGroup">
    <mat-toolbar color="primary">Alterar Senha</mat-toolbar>
    <mat-card-content>
        <mat-form-field class="full-width">
            <mat-label>Senha Atual</mat-label>
            <input matInput [type]=" hideSenhaAtual ? 'password' : 'text'" formControlName="senhaAtual" class="input"
                type="password" />
            <button mat-icon-button matSuffix (click)="hideSenhaAtual = !hideSenhaAtual">
                <mat-icon>{{hideSenhaAtual ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>Nova Senha</mat-label>
            <input matInput [type]=" hideSenhaNova ? 'password' : 'text'" formControlName="senhaNova" class="input"
                (input)="onInput()" type="password" />
            <button mat-icon-button matSuffix (click)="hideSenhaNova = !hideSenhaNova">
                <mat-icon>{{hideSenhaNova ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint class="hint" *ngIf="isntMinimo">
                Mínimo de 6 caracteres
            </mat-hint>
            <mat-hint class="hint" *ngIf="isntMaximo">
                Máximo de 15 caracteres
            </mat-hint>
            <mat-hint class="hint" *ngIf="!isntMinimo && !isntMaximo && !this.formGroup.get('senhaNova').valid && this.formGroup.get('senhaNova').value.length > 0 &&this.formGroup.get('senhaNova').value.length < 15 ;">
                Requer <span *ngFor="let info of infoSenha; let last = last ">{{ info }}{{ !last? ', ': '' }}</span>
            </mat-hint>
        </mat-form-field>
        
        <mat-form-field class="full-width">
            <mat-label>Confirmar Nova Senha</mat-label>
            <input matInput [type]=" hideSenhaConfirmacao ? 'password' : 'text'" formControlName="confirmacaoSenhaNova"
                (input)="onInputConfirmacao()" type="password" />
            <button mat-icon-button matSuffix (click)="hideSenhaConfirmacao = !hideSenhaConfirmacao">
                <mat-icon>{{hideSenhaConfirmacao ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint class="hint" *ngIf="isntEqualSenha">
                A confirmação deve ser igual a senha nova
            </mat-hint>
        </mat-form-field>
        <button class="full-width" mat-flat-button color="primary" (click)="alterarSenha()">
            Alterar a Senha
        </button>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="!this.isCliente" [formGroup]="formGroupBearer">
    <mat-toolbar color="primary">Bearer Token de acesso a API</mat-toolbar>
    <mat-card-content>
        <mat-form-field class="full-width">
            <mat-label>Token</mat-label>
            <input matInput [type]=" hideToken ? 'password' : 'text'" formControlName="token" class="input"
                />
            <button mat-icon-button matSuffix (click)="hideToken = !hideToken">
                <mat-icon>{{hideToken ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <button class="full-width" mat-flat-button color="primary" (click)="generateBearerToken()">
            Gerar novo token
        </button>
    </mat-card-content>
</mat-card>