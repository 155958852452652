export class ItemSimilar {
	public indice: number;
	public idItem: number;
	public nmItem: string;
	public imagem: string;
	public cdItem: string;
	public qtAtual: number;
	public qtDisponivel: number;
	public estoqueDisponivel: boolean
	public preco: number;
	public flPromocao: boolean;
	public pcMaxDescontoAut: number;
	public ipi: number;
	public st: number;
	public ipiSt: number;

	constructor(
		indice: number,
		idItem: number,
		nmItem: string,
		cdItem: string,
		qtAtual: number,
		qtDisponivel: number,
		estoqueDisponivel: boolean,
		preco: number,
		flPromocao: boolean,
		pcMaxDescontoAut: number,
		ipi: number,
		st: number,
		ipiSt: number

	) {
		this.indice = indice;
		this.idItem = idItem;
		this.nmItem = nmItem;
		this.cdItem = cdItem;
		this.qtAtual = qtAtual;
		this.qtDisponivel = qtDisponivel;
		this.estoqueDisponivel = estoqueDisponivel; 
		this.preco = preco;
		this.flPromocao = flPromocao;
		this.pcMaxDescontoAut = pcMaxDescontoAut;
		this.ipi = ipi;
		this.st = st;
		this.ipiSt = ipiSt;
	}
}
