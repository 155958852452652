import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Funcionario } from '../persistences/funcionario';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class FuncionarioService {

  constructor(private configService: ConfigService,
    private httpClient: HttpClient) { }

    getFuncionarioByCdUsuario(cdUsuario: string): Observable<Funcionario>{
      let httpParams = new HttpParams();
      httpParams = httpParams
        .append('cdUsuario', cdUsuario);
  
      return this.httpClient.get<Funcionario>(
        this.configService.getEndereco() + '/funcionario/getByCdUsuario',	
        { 
          headers: this.configService.getHeaders(),
          params: httpParams
        }
      )
    }
}
