import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgImageSliderModule } from 'ng-image-slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardItemPesquisaComponent } from './components/card-item-pesquisa/card-item-pesquisa.component';
import { CardListaItensComponent } from './components/card-lista-itens/card-lista-itens.component';
import { CardLoginComponent } from './components/card-login/card-login.component';
import { CarrinhoComponent } from './components/carrinho/carrinho.component';
import { CarrosselCabecalhoComponent } from './components/carrossel-cabecalho/carrossel-cabecalho.component';
import { CarrinhoMenuComponent } from './components/carrrinho-menu/carrinho-menu.component';
import { ControlBarComponent } from './components/controlbar/controlbar.component';
import { FiltroLateralComponent } from './components/filtro-lateral/filtro-lateral.component';
import { ConsultaCustomizadaComponent } from './components/genericos/consulta-customizada/consulta-customizada.component';
import { DialogCustomizadoComponent } from './components/genericos/dialog-customizado/dialog-customizado.component';
import { MessageDialogCutomizadoComponent } from './components/genericos/message-dialog-cutomizado/message-dialog-cutomizado.component';
import { PesquisaCustomizadaComponent } from './components/genericos/pesquisa-customizada/pesquisa-customizada.component';
import { ProgressBarCustomizadaComponent } from './components/genericos/progress-bar-customizada/progress-bar-customizada.component';
import {
  ItemCarrinhoPaginaExcluirComponent
} from './components/item-carrinho-pagina/item-carrinho-pagina-excluir/item-carrinho-pagina-excluir.component';
import { ItemCarrinhoPaginaComponent } from './components/item-carrinho-pagina/item-carrinho-pagina.component';
import { ItemCarrinhoComponent } from './components/item-carrinho/item-carrinho.component';
import { ItemDescricaoDetalhadoComponent } from './components/item-descricao-detalhado/item-descricao-detalhado.component';
import { ItemDetalhadoComponent } from './components/item-detalhado/item-detalhado.component';
import { ItemPesquisaComponent } from './components/item-pesquisa/item-pesquisa.component';
import { LancaItemCabecalhoComponent } from './components/lanca-item-cabecalho/lanca-item-cabecalho.component';
import { ListaItensComponent } from './components/lista-itens/lista-itens.component';
import { ListaPrecoComponent } from './components/lista-preco/lista-preco.component';
import { ListaSimilaresComponent } from './components/lista-similares/lista-similares.component';
import { MenuMinhaContaComponent } from './components/menu-minha-conta/menu-minha-conta.component';
import { MenuComponent } from './components/menu/menu.component';
import { PainelComponent } from './components/painel/painel.component';
import { QuantidadeListaComponent } from './components/quantidade-lista/quantidade-lista.component';
import { RodapeComponent } from './components/rodape/rodape.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { DelayedInputModule } from './directives/delayed-input/delayed-input.module';
import { MaterialModule } from './material.module';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { PainelCadastroComponent } from './pages/cadastros/painel/painel-cadastro.component';
import { PerfilClienteComponent } from './pages/cadastros/perfil-cliente/perfil-cliente.component';
import { PerfilUsuarioComponent } from './pages/cadastros/perfil-usuario/perfil-usuario.component';
import { CarrinhoPaginaComponent } from './pages/carrinho-pagina/carrinho-pagina.component';
import { HomeComponent } from './pages/home/home.component';
import { ListaTitulosComponent } from './pages/lista-titulos/lista-titulos.component';
import { LoginComponent } from './pages/login/login.component';
import { FiltroPipe } from './pages/meus-pedidos/filtro-pipe/filtro.pipe';
import { MeusPedidosComponent } from './pages/meus-pedidos/meus-pedidos.component';
import { MinhaContaComponent } from './pages/minha-conta/minha-conta.component';
import { AcessoAcaoService } from './services/acesso-acao.service';
import { ConfigService } from './services/config.service';
import { ConfigAux } from './services/configAux';
import { InterceptorService } from './services/interceptor.service';
import { interceptorProviders } from './services/interceptorProvider';
import { CartSingletonService } from './singletons/cart-singleton.service';
import { ClienteSingletonService } from './singletons/cliente-singleton.service';
import { PedidoDetalhadoComponent } from './components/pedido-detalhado/pedido-detalhado.component';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    MeusPedidosComponent,
    CardLoginComponent,
    HomeComponent,
    LoginComponent,
    SearchBarComponent,
    MenuComponent,
    CarrinhoMenuComponent,    
    ItemPesquisaComponent,
    ListaItensComponent,
    CardItemPesquisaComponent,
    CardListaItensComponent,
    ItemDetalhadoComponent,
    ItemDescricaoDetalhadoComponent,
    LancaItemCabecalhoComponent,
    ListaSimilaresComponent,
    CarrinhoComponent,
    ItemCarrinhoComponent,
    CarrinhoPaginaComponent,
    ItemCarrinhoPaginaComponent,
    MenuMinhaContaComponent,
    ItemCarrinhoPaginaExcluirComponent,
    RodapeComponent,
    ControlBarComponent,
    QuantidadeListaComponent,
    AlterarSenhaComponent,
    ProgressBarCustomizadaComponent,
    ConsultaCustomizadaComponent,
    PesquisaCustomizadaComponent, 
    FiltroPipe,
    FiltroLateralComponent,
    CarrosselCabecalhoComponent,
    PainelComponent,
    DialogCustomizadoComponent,
    PainelCadastroComponent,
    PerfilClienteComponent,
    PerfilUsuarioComponent,
    MinhaContaComponent,
    MessageDialogCutomizadoComponent,
    ListaTitulosComponent,
    ListaPrecoComponent,
    PedidoDetalhadoComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    ScrollingModule,
    NgxPaginationModule,
    HttpClientModule,
    NgImageSliderModule,
    DelayedInputModule,    
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return localStorage.getItem('access_token_business');
        },
      }
    }),
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS,           
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigAux, ConfigService],
      useFactory: (configService: ConfigAux, config: ConfigService) => {
        return () => {
          if(localStorage.getItem("access_token_business")){
            return config.loadFullConfig();
          }else{
            return configService.loadAppConfig();          
          }
        };
      }
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },    
    CurrencyPipe,
    CartSingletonService,
    ClienteSingletonService,
    ConfigService,
    AcessoAcaoService,
    interceptorProviders,
    ConfigAux,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
