import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { JwtService } from 'src/app/jwt.service';
import { Carrinho } from 'src/app/persistences/carrinho';
import { ConfigService } from 'src/app/services/config.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.css']
})
export class CarrinhoComponent implements OnInit, OnDestroy {

    public temaPadrao: string;
	public carrinho: Carrinho;
	private subscription: Subscription;

    constructor(
		private router: Router,
		private cartSingletonService: CartSingletonService,
        private configService: ConfigService,
        private jwtService: JwtService,
		private itemService: ItemService
	) {
	}

	ngOnInit() {
        this.temaPadrao = this.configService.getConfig().getTemaPadrao;
		this.subscription = this.cartSingletonService.getCarrinhoObservable().subscribe(carrinho => {
			this.carrinho = carrinho;
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	navegarCarrinho () {
		this.jwtService.validarAcessToken();
		this.itemService.idsItensNoCarrinho = [];
		this.router.navigateByUrl("/carrinho");
	}

}
