export class PedidoItemFlex {
	public idGeral: number;
	public nrSequencia: number;
	public idItem: number;
	public qtVenda: number;
	public unVenda: string;
	public vlBruto: number;
	public pcDesconto: number;
	public idTabelaPrecoProduto: number;
}
