import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { JwtService } from '../jwt.service';
import Item from "../persistences/item";
import { ProdutoPesquisado } from '../persistences/produto-pesquisado';
import { ConfigService } from './config.service';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';
import { CartSingletonService } from '../singletons/cart-singleton.service';
import { ConsultaDetalhada } from '../persistences/consulta-detalhada';


@Injectable({
    providedIn: 'root'
})
export class PesquisaService {

    private listaProdPesquisados: ProdutoPesquisado[];
    private compativeis : number[];
    pesquisaRealizadaEmitter = new EventEmitter<boolean>();

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private jwtService: JwtService,
        private clienteSingletonService: ClienteSingletonService,
        private cartSingletonService: CartSingletonService
    ) {
    }

    pesquisa(consulta: string) {

        this.jwtService.validarAcessToken();
        const tipoItem= this.configService.getConfig().getTipoItem;
        return this.httpClient.get<ProdutoPesquisado[]>(
            
            this.configService.getEndereco() + '/Produto/DetalhadaCompleta',
            {
                headers: this.configService.getHeaders(),
                
                params: new HttpParams()
                    .set('consulta', consulta)
                    .set('montadora', '0')
                    .set('modelo', '0')
                    .set('complemento', '0')
                    .set('injecao', '0')
                    .set('motor', '0')
                    .set('transmissao', '0')
                    .set('anoInicial', '0')
                    .set('anoFinal', '0')
                    .set('considerarInfVeiculo', 'true')
                    .set('considerarFichaTecnica', 'true')
                    .set('cd_filial',this.clienteSingletonService.getClienteEstatico().getClienteFilial.getCdFilial)
                    .set('tiposItem',tipoItem)

            }
        )
    }

    public carregaItensByIds(ids: number[]): Observable<Item[]> {

        this.jwtService.validarAcessToken();

        const httpParamsCompleto = this.preencherHttpParamsItens(ids);

        return this.httpClient.get<Item[]>(
            this.configService.getEndereco() + '/getItensByIds',
            {
                headers: this.configService.getHeaders(), 
                params: httpParamsCompleto
            }
        );
    }

    public carregarFiltrosByIds(ids: number[]) {

        const consultaDetalhada: ConsultaDetalhada = new ConsultaDetalhada();
        consultaDetalhada.ids = ids;
        
        this.jwtService.validarAcessToken();

        return this.httpClient.post<ProdutoPesquisado[]>(
            this.configService.getEndereco() + '/Filtros/getConsultaDetalhadaByIds',
            JSON.stringify(consultaDetalhada),
            { headers: this.configService.getHeaders() }
        )
    }

    getProdutosPesquisados() {

        this.jwtService.validarAcessToken();

        return this.listaProdPesquisados;
    }

    getProdutosCompativeis() {

      this.jwtService.validarAcessToken();

      return this.compativeis;
  }

    setProdutosPesquisados(produtosPesquisados: ProdutoPesquisado[]) {

        this.jwtService.validarAcessToken();

        this.listaProdPesquisados = produtosPesquisados;
    }


    setProdutosCompativeis(listaCompativeis: number[]) {

      this.jwtService.validarAcessToken();

      this.compativeis = listaCompativeis;
  }

    private preencherHttpParamsItens(ids: number[]): HttpParams {

        let httpParams = new HttpParams();

        const cliente = this.clienteSingletonService.getClienteEstatico();
        const carrinho = this.cartSingletonService.getCarrinhoEstatico();
        httpParams = httpParams.append('cdFilial', cliente.getClienteFilial.getCdFilial);
        httpParams = httpParams.append('cdCondPgto', carrinho.getCdCondPgto.toString());
        httpParams = httpParams.append('cdFormaPagamento', carrinho.getCdFormaPgto.toString());
        httpParams = httpParams.append('tipoFrete', carrinho.getTpFrete);
        httpParams = httpParams.append('cdTabela', '0');
        httpParams = httpParams.append('origemPedido', '0');
        httpParams = httpParams.append('cdCliente', cliente.getCdCliente.toString());
        httpParams = httpParams.append('data', new Date().toLocaleDateString('pt-BR'));
        httpParams = httpParams.append('qtd', '1');
        httpParams = httpParams.append('cdFuncionario', cliente.getCdFuncionario.toString());

        ids.forEach(id => {
            httpParams = httpParams.append('ids', id.toString());

        });

        return httpParams;
    }

    private preencherHttpParamsFiltros(ids: number[]): HttpParams {

        let httpParams = new HttpParams();
        ids.forEach(id => {
            httpParams = httpParams.append('ids', id.toString());

        });

        return httpParams;
    }

    emitPesquisaRealizada(bool:boolean){
        this.pesquisaRealizadaEmitter.emit(bool);
    }

    enviaFiltrosLogPesquisa(filtros: string): Observable<any>{
        const params = new HttpParams().set('filtros', filtros);

        return this.httpClient.post(
            this.configService.getEndereco() + '/Filtros/gravarFiltrosLog',
            {},
            {
                headers: this.configService.getHeaders(),
                params: params
            }
        );
    }

}

