export class PedidoSimplificado {
  idGeral : number;
  nrPedido : number;
  tpFrete : string;
  tpDocFatura : string;
  cdFilial : number;
  nmFantasiaFilial : string;
  cdCliente : number;
  nmFantasiaCliente : string;
  cdClienteFatura : number;
  nmFantasiaClienteFatura : string;
  cdVendedor : number;
  nmVendedor : string;
  cdSituacao : number;
  nmSituacao : string;
  cdCondPgto : number;
  nmCondPgto : string;
  cdFormaPgto : number;
  nmFormaPgto : string;
  cdTpEntrega : number;
  nmTpEntrega : string;
  cdTransportador : number;
  nmTransportador : string;
  vlTotal : number;
  vlFrete : number;
  vlDesconto : number;
  vlOutrasDespesas : number;
  dtPrevisaoEnvio : string;
  dtEmissao : string;
  dtAtz : string;
  temBoleto : boolean;
  temDanfe : boolean;
  temXml : boolean;
}

export class PedidoSimplificadoFiltros {
  cdCliente : number;
  situacao = 0;
  emAberto = false;
  origem = 'E';
  dtInicial : string;
  dtFinal : string;
}
