import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlBarComponent } from 'src/app/components/controlbar/controlbar.component';
import { PainelComponent } from 'src/app/components/painel/painel.component';
import { IEstadosDaPesquisa } from 'src/app/interfaces/iestados-pesquisa';
import { JwtService } from 'src/app/jwt.service';
import { ConfigService } from 'src/app/services/config.service';
import { SearchbarService } from 'src/app/services/searchbar.service';
import { FiltroLateralManager } from 'src/app/managers/filtro-manager';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

    @ViewChild(PainelComponent) painelComponent!: PainelComponent
    @ViewChild(ControlBarComponent) controlBarComponent!: ControlBarComponent;
    idsPesquisados: number[];
    isLoading: boolean;
    estadosDaPesquisa: IEstadosDaPesquisa;

    ocultaAbas: boolean;
    ordenacaoInput: string;
    isCliente: boolean;
    temAcesso = parseInt(localStorage.getItem('tem_acesso_business')) == 0 ? false : true;
    private subscription: Subscription;

    constructor(
        private jwtService: JwtService,
        private configService: ConfigService,
        private searchbarService: SearchbarService,
        private filtroLateralManager: FiltroLateralManager,
        private router: Router,
        private itemService: ItemService,
        private cartSingletonService: CartSingletonService
    ) {
        if(!this.temAcesso){
            this.router.navigateByUrl('/minha-conta/meus-pedidos');
        }
        this.isCliente = parseInt(localStorage.getItem('cd_usuario_business')) !== 0 ? false : true;
        this.jwtService.checkLogin();

        this.searchbarService.idsPesquisaEmitter.subscribe((res) => {
            this.onPesquisar(res);
        })
        this.searchbarService.ocultarAbasEmitter.subscribe((res) => {
            this.ocultarAbas(res);
        })
        this.searchbarService.isLoadingEmitter.subscribe((res) => {
            this.changeIsLoading(res);
        })
    }

    get ordenacaoDefault(): string {
        return this.configService.getConfig().getOrdenacaoDefault;
    }

    ngOnInit() {
        this.ocultaAbas = true;
        this.idsPesquisados = [];
        this.estadosDaPesquisa = {
            estadoInicial: true,
            pesquisandoRegistros: false,
            registrosEncontrados: false,
            pesquisaPronta: false,
        }
        const itens = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho;
        itens.forEach(p =>{
            this.itemService.idsItensNoCarrinho.push(p.itemDetalhes.idItem)
        })
    }

    ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

    onPesquisar(ids: number[]) {
        this.idsPesquisados = ids;
    }

    getIdsConsultaPaineis(ids: number[]) {
        this.changeIsLoading(true);
        setTimeout(() => {
            this.onPesquisar(ids);
            this.changeIsLoading(false);
        }, 500);
    }

    changeIsLoading(bool: boolean) {
        this.estadosDaPesquisa.estadoInicial = false;
        this.estadosDaPesquisa.pesquisaPronta = !bool;
        this.estadosDaPesquisa.registrosEncontrados = this.idsPesquisados.length > 0;
        this.isLoading = bool;
    }

    ocultarAbas(ocultaAbas: boolean) {
        this.ocultaAbas = ocultaAbas;
    }

    setOrdemPainel(ordem: string) {
        this.painelComponent.orderByComListaItens(ordem);
    }

    setAbrirFiltroPainel(bool: boolean) {
        this.painelComponent.setStatusFiltro(bool);
        this.filtroLateralManager.setIsFiltroAberto(bool);
    }

    setOrdenacaoInput(ordenacao: string) {
        this.ordenacaoInput = ordenacao;
    }
}
