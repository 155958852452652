import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { JwtService } from 'src/app/jwt.service';
import { ClienteManager } from 'src/app/managers/cliente-manager';
import { ItemManager } from 'src/app/managers/item-manager';
import { PedidoManager } from 'src/app/managers/pedido-manager';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { Cliente } from 'src/app/persistences/cliente';
import { ItemDetalhes } from 'src/app/persistences/item-detalhes';
import { PedidoVenda } from 'src/app/persistences/pedido-venda';
import { ConfigService } from 'src/app/services/config.service';
import { FormaCondService } from 'src/app/services/forma-cond.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { FormaCondPgto } from 'src/app/persistences/forma-cond-pgto';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
    selector: 'app-card-login',
    templateUrl: './card-login.component.html',
    styleUrls: ['./card-login.component.css'],
})
export class CardLoginComponent {
    public pedidoVenda: PedidoVenda;
    public itemDetalhes: ItemDetalhes;
    public btnLoginDesabilado = false;
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public cpf_cnpj: string;

    constructor(
        private snackBarManager: SnackbarManager,
        private router: Router,
        private jwtService: JwtService,
        private cartSingleton: CartSingletonService,
        private config: ConfigService,
        private formcondService: FormaCondService,
        public itemManager: ItemManager,
        private pedidoManager: PedidoManager,        
        private clienteManager: ClienteManager,
        private clienteSingletonService: ClienteSingletonService,
        private clienteService: ClienteService,
    ) {
    }

    async login(usuario: string, senha: string) {
        usuario = this.removerMascara(usuario);
        this.btnLoginDesabilado = true;
        if (usuario == '' || senha == '') {
            this.btnLoginDesabilado = false;
            this.snackBarManager.openSnackBar('Usuario ou senha inválido.', 'Fechar', 2000);
            return;
        }
        this.jwtService.login(usuario, senha).subscribe((res) => {            
            if (res['status'] == false) {
                this.btnLoginDesabilado = false;
                this.snackBarManager.openSnackBar(res['mensagem'], 'Fechar', 2000);
            } else {
                this.config.configuraLayout(this.config.getConfig().getLayout === 2 ? true : false);                
                localStorage.setItem('access_token_business', res['access_token']);
                localStorage.setItem('expires_at_business', res['expires_at']);
                localStorage.setItem('cd_usuario_business', res['cd_usuario']);                
                localStorage.setItem('filial_business', res['filial']);
                this.clienteSingletonService.atualizarCdCliente(res['cd_cliente']);      
                if (this.clienteSingletonService.getClienteEstatico().getCdCliente == 0) {         
                    this.clienteManager.getFilialServidor(Number.parseInt(localStorage.getItem('filial_business')));
                }
                localStorage.setItem('expires_at_seconds_business', res['expires_at_seconds']);     
                
                this.config.loadFullConfig();           

                this.jwtService.temAcesso().subscribe((r: boolean) => {
                    localStorage.setItem('tem_acesso_business', r ? '1' : '0');
                },
                (error: HttpErrorResponse) => {
                    this.btnLoginDesabilado = false;
                    this.snackBarManager.openSnackBar(error.error, 'Fechar', 2000);
                })
                
                const cliente: Cliente = this.clienteSingletonService.getClienteEstatico();
                if (cliente.getCdCliente != 0) {
                    this.clienteService.clienteTemVendedorPadrao().subscribe((resposta) => {
                        if (resposta['status'] == false) {
                            this.btnLoginDesabilado = false;
                            this.snackBarManager.openSnackBar(resposta['mensagem'] ,"Ok", 3000);
                            return
                        }else{
                            this.clienteManager.getClientefromServico().then(() => {
                                this.formcondService.getFormaCondPgto(cliente.getCdCliente).subscribe(
                                    (response) => {
                                        if(response.length == 0){
                                            this.btnLoginDesabilado = false;
                                            this.jwtService.logout();
                                            this.snackBarManager.openSnackBar("O usuário não possui perfil comercial com forma e condição de pagamento","Ok", 3000);
                                            return
                                        }
        
                                        const formasAgrupadas: FormaCondPgto[] = [];
                                        response.forEach( forma => {
                                            const cdForma = forma.cdFormaPgto;
                                            if(!formasAgrupadas.some(item => item.cdFormaPgto === cdForma)){
                                                formasAgrupadas.push(forma)
                                            } else{
                                                forma.condicoes.forEach(cond => {
                                                    const formaAtual = formasAgrupadas.find(forma => forma.cdFormaPgto === cdForma)
                                                    if(!formaAtual.condicoes.some(item => item.cdCondPgto === cond.cdCondPgto)){
                                                        formasAgrupadas.forEach(forma => {
                                                            if(forma.cdFormaPgto === cdForma){
                                                                forma.condicoes.push(cond);
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        this.cartSingleton.setFormaECondicaoDePagamento(formasAgrupadas);
                                        this.clienteSingletonService.atualizaFormaCodPadrao(formasAgrupadas);
                                        this.pedidoManager
                                        .promiseBuscarPedidoEdicao()
                                            .then(() => {
                                                this.pedidoVenda = this.cartSingleton.getPedidoVenda();
                                                if (this.pedidoVenda.getIdGeral != undefined) {
                                                    let obs = '';
        
                                                    if(this.pedidoVenda.getObservacoesInterna != undefined && this.pedidoVenda.getObservacoesInterna.trim() != ''){
                                                        obs = this.pedidoVenda.getObservacoesInterna
                                                    } 
                                                    this.cartSingleton.atualizarDadosCabecalho(this.pedidoVenda.getCdFormaPgto, this.pedidoVenda.getCdCondPgto, this.pedidoVenda.getCdTpEntrega, obs);
                                                    this.pedidoManager.carregarInformacaoPedidoEdicao();
                                                } else {                                            
                                                    this.pedidoManager.promiseNovoPedidoVenda().then(
                                                        () => this.router.navigateByUrl('/home'))
                                                        .catch((error) => {
                                                            this.btnLoginDesabilado = false;
                                                            this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
                                                        });
                                                }                                 
                                        })
                                        .catch((error) => {
                                            this.btnLoginDesabilado = false;
                                            this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
                                        });
                                    },
                                    (error: HttpErrorResponse) => {
                                        this.btnLoginDesabilado = false;
                                        this.snackBarManager.openSnackBar(error.error, 'Fechar', 2000);
                                    }
                                );
                            });
                        }  
                    },
                    (error: HttpErrorResponse) => {
                        this.btnLoginDesabilado = false;                 
                        this.snackBarManager.openSnackBar(error.error, 'Fechar', 3000);
                        return                
                    });
                }else {
                    localStorage.setItem('usuario', usuario);
                    this.router.navigateByUrl('/home');
                }
                                      
            }
        }, (error: HttpErrorResponse) => {
            this.btnLoginDesabilado = false;
            if(error.status == 0){
                this.snackBarManager.openSnackBar(("Não foi possível estabeler conexão com o servidor" ), 'Ok', 2000);
            }
        });
    }

    getCpfCnpjMask() {
        if (this.cpf_cnpj != undefined) {
            if (!isNaN(+this.removerMascara(this.cpf_cnpj))) {
                if (this.removerMascara(this.cpf_cnpj).length == 3) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})/, "$1.");
                } else if (this.removerMascara(this.cpf_cnpj).length == 4) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{1})/, "$1.$2");
                } else if (this.removerMascara(this.cpf_cnpj).length == 5) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{2})/, "$1.$2");
                } else if (this.removerMascara(this.cpf_cnpj).length == 6) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{3})/, "$1.$2.");
                } else if (this.removerMascara(this.cpf_cnpj).length == 7) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{3})(\d{1})/, "$1.$2.$3");
                } else if (this.removerMascara(this.cpf_cnpj).length == 8) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{3})(\d{2})/, "$1.$2.$3");
                } else if (this.removerMascara(this.cpf_cnpj).length == 9) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{3})(\d{3})/, "$1.$2.$3-");
                } else if (this.removerMascara(this.cpf_cnpj).length == 10) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
                } else if (this.removerMascara(this.cpf_cnpj).length == 11) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                } else if (this.removerMascara(this.cpf_cnpj).length == 12) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4");
                } else if (this.removerMascara(this.cpf_cnpj).length == 13) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, "$1.$2.$3/$4-$5");
                } else if (this.removerMascara(this.cpf_cnpj).length == 14) {
                    this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
                    return this.cpf_cnpj = this.cpf_cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                }
            } else {
                this.cpf_cnpj = this.removerMascara(this.cpf_cnpj);
            }
        }
    }

    removerMascara(string: string) {
        return string = string.split('.').join('').split('-').join('').split('/').join('');
    }
}
