import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorMessageService } from './http-error-message.service';

@Injectable({
	providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

	constructor(
		private httpErrorMessageService: HttpErrorMessageService
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {	
		
		/*ME PARECE QUE NAO É MAIS NECESSÁRIO TBM this.localStorageSingletonService.recarregarCartSingleton(this.cartSingletonService);
			this.localStorageSingletonService.recarregarConfig(this.configService);
			
		if (this.configService == null || this.configService.ip == undefined || this.configService.porta == undefined) {
			this.localStorageSingletonService.recarregarConfig(this.configService);
		}				
		this.localStorageSingletonService.recarregarCliente(this.clienteSingletonService);
		*/						
		
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				this.httpErrorMessageService.openErrorMessageDialog(error);		
				return throwError(error);
			})
		);
	}
}