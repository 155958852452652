export class PedidoVenda {


    private idGeral: number;
    private nrPedido: number;
    private tpFrete: string;
    private tpDocFatura: string;
    private cdFilial: number;
    private cdCliente: number;
    private cdVendedor: number;
    private cdSituacao: number;
    private cdCondPgto: number;
    private cdFormaPgto: number;
    private cdTpEntrega: number;
    private versaoRegistro: number;
    private vlTotal: number;
    private vlFrete: number;
    private vlDesconto: number;
    private vlOutrasDespesas: number;
    private dtPrevisaoEnvio: string;
    private dtEmissao: string;
    private nmSituacao: string;
    private dtAtz: string;
    private pcMarkupEcommerce: number;
    private itens: Item[] = new Array<Item>();
    private historicoEtapas: [];
    private listaNfcPedidoVenda: [];
    private ObservacoesInterna: string;
    private ObservacoesNota: string;    

    constructor(json?: any) {        
        if (json) {            
            // Inicialização a partir do objeto JSON
            this.idGeral = json.idGeral;
            this.nrPedido = json.nrPedido;
            this.tpFrete = json.tpFrete;
            this.tpDocFatura = json.tpDocFatura;
            this.cdFilial = json.cdFilial;
            this.cdCliente = json.cdCliente;
            this.cdVendedor = json.cdVendedor;
            this.cdSituacao = json.cdSituacao;
            this.cdCondPgto = json.cdCondPgto;
            this.cdFormaPgto = json.cdFormaPgto;
            this.cdTpEntrega = json.cdTpEntrega;
            this.versaoRegistro = json.versaoRegistro;
            this.vlTotal = json.vlTotal;
            this.vlFrete = json.vlFrete;
            this.vlDesconto = json.vlDesconto;
            this.vlOutrasDespesas = json.vlOutrasDespesas;
            this.dtPrevisaoEnvio = json.dtPrevisaoEnvio;
            this.dtEmissao = json.dtEmissao;
            this.nmSituacao = json.nmSituacao;
            this.dtAtz = json.dtAtz;
            this.pcMarkupEcommerce = json.pcMarkupEcommerce;
            this.ObservacoesInterna = json.ObservacoesInterna;
            this.ObservacoesNota = json.ObservacoesNota;

            this.itens = json.itens ? json.itens.map((forma: any) => new Item(forma)) : new Array<Item>();
            this.historicoEtapas = json.historicoEtapas;
            this.listaNfcPedidoVenda = json.listaNfcPedidoVenda;

        } 
    }

    /*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO CartSingletonService*/

    get getIdGeral(): number {
        return this.idGeral;
    }

    _setIdGeral(valor: number):void {
        this.idGeral = valor;
    }    

    
    get getVersaoRegistro(): number {
        return this.versaoRegistro;
    }

    _setVersaoRegistro(valor: number) {
        this.versaoRegistro = valor;
    }
    
    get getNrPedido(): number {
        return this.nrPedido;
    }
    
    get getTpFrete(): string {
        return this.tpFrete;
    }
    
    get getTpDocFatura(): string {
        return this.tpDocFatura;
    }
    
    get getCdFilial(): number {
        return this.cdFilial;
    }
    
    get getCdCliente(): number {
        return this.cdCliente;
    }
    
    get getCdVendedor(): number {
        return this.cdVendedor;
    }
    
    get getCdSituacao(): number {
        return this.cdSituacao;
    }
    
    get getCdCondPgto(): number {
        return this.cdCondPgto;
    }
    
    get getCdFormaPgto(): number {
        return this.cdFormaPgto;
    }
    
    get getCdTpEntrega(): number {
        return this.cdTpEntrega;
    }    
    
    get getVlTotal(): number {
        return this.vlTotal;
    }
    
    get getVlFrete(): number {
        return this.vlFrete;
    }
    
    get getVlDesconto(): number {
        return this.vlDesconto;
    }
    
    get getVlOutrasDespesas(): number {
        return this.vlOutrasDespesas;
    }
    
    get getDtPrevisaoEnvio(): string {
        return this.dtPrevisaoEnvio;
    }
    
    get getDtEmissao(): string {
        return this.dtEmissao;
    }
    
    get getNmSituacao(): string {
        return this.nmSituacao;
    }
    
    get getDtAtz(): string {
        return this.dtAtz;
    }
    
    get getPcMarkupEcommerce(): number {
        return this.pcMarkupEcommerce;
    }
    
    get getItens(): Item[] {
        return this.itens;
    }
    
    get getHistoricoEtapas(): [] {
        return this.historicoEtapas;
    }
    
    get getListaNfcPedidoVenda(): [] {
        return this.listaNfcPedidoVenda;
    }
    
    get getObservacoesInterna(): string {
        return this.ObservacoesInterna;
    }
    
    get getObservacoesNota(): string {
        return this.ObservacoesNota;
    }      

}


class Item {

    private idGeral: number;
    private nrSequencia: number;
    private idItem: number;
    private qtVenda: number;
    private unVenda: string;
    private vlBruto: number;
    private vlLiquido: number;
    private pcDesconto: number;
    private idTabelaPrecoProduto: number;
    private vlPrecoTab: number;
    private flPromocao: string;
    private IcmsSubstValor: number;
    private IpiValor: number;
    private preco: number;
    private IpiCst: number;
    private quantidadeEstoque: number; 
    
    constructor(json?: any) {               
        if (json) {            
            // Inicialização a partir do objeto JSON
            this.idGeral = json.idGeral;
            this.nrSequencia = json.nrSequencia;
            this.idItem = json.idItem;
            this.qtVenda = json.qtVenda;
            this.unVenda = json.unVenda;
            this.vlBruto = json.vlBruto;
            this.vlLiquido = json.vlLiquido;
            this.pcDesconto = json.pcDesconto;
            this.idTabelaPrecoProduto = json.idTabelaPrecoProduto;
            this.vlPrecoTab = json.vlPrecoTab;
            this.flPromocao = json.flPromocao;
            this.IcmsSubstValor = json.IcmsSubstValor;
            this.IpiValor = json.IpiValor;
            this.preco = json.preco;
            this.IpiCst = json.IpiCst;
            this.quantidadeEstoque = json.quantidadeEstoque;
        } 
    }

    /*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO CartSingletonService*/

    get getIdGeral(): number {
        return this.idGeral;
    }
    
    get getNrSequencia(): number {
    return this.nrSequencia;
    }
    
    get getIdItem(): number {
        return this.idItem;
    }
    
    get getQtVenda(): number {
        return this.qtVenda;
    }
    
    get getUnVenda(): string {
        return this.unVenda;
    }
    
    get getVlBruto(): number {
        return this.vlBruto;
    }
    
    get getVlLiquido(): number {
        return this.vlLiquido;
    }
    
    get getPcDesconto(): number {
        return this.pcDesconto;
    }
    
    get getIdTabelaPrecoProduto(): number {
        return this.idTabelaPrecoProduto;
    }
    
    get getVlPrecoTab(): number {
        return this.vlPrecoTab;
    }
    
    get getFlPromocao(): string {
        return this.flPromocao;
    }
    
    get getIcmsSubstValor(): number {
        return this.IcmsSubstValor;
    }
    
    get getIpiValor(): number {
        return this.IpiValor;
    }
    
    get getPreco(): number {
    return this.preco;
    }
    
    get getIpiCst(): number {
        return this.IpiCst;
    }
    
    get getQuantidadeEstoque(): number {
        return this.quantidadeEstoque;
    }      

}
