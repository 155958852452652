<button mat-menu-item style="background-color: white" [routerLink]="routerLink"><b>Minha Conta</b></button>
<mat-divider></mat-divider>
<div *ngIf="isCliente">
	<button mat-menu-item style="background-color: white" routerLink="/minha-conta/meus-pedidos"><b>Meus
			Pedidos</b></button>
	<mat-divider></mat-divider>
</div>
<div *ngIf="isUsuario">
	<button mat-menu-item style="background-color: white" routerLink="/minha-conta/painel-cadastro"><b>Cadastro de
			Paineis</b></button>
	<mat-divider></mat-divider>
</div>
<div *ngIf="isCliente">
	<button mat-menu-item style="background-color: white" routerLink="/minha-conta/meus-titulos"><b>Meus títulos</b></button>
	<mat-divider></mat-divider>
</div>
<button mat-menu-item style="background-color: white" routerLink="/minha-conta/alterar-senha"><b>Segurança</b></button>