import { CtaCondPgto } from "./cta-cond-pgto";

export class FormaCondPgto {
    public flPadrao: boolean;
	public cdFormaPgto: number;
	public nmFormaPgto: string;
	public cdPortador: number;
	public classificacao: string;
	public flAtivo: string;
	public hierarquia: number;
	public vlMinimoParcela: number;
	public condicoes: CtaCondPgto[] =  new Array<CtaCondPgto>();

	constructor(json?: any) {
        if (json) {
            this.flPadrao = json.flPadrao;
            this.cdFormaPgto = json.cdFormaPgto;
			this.nmFormaPgto = json.nmFormaPgto;
			this.cdPortador = json.cdPortador;
			this.classificacao = json.classificacao;
			this.flAtivo = json.flAtivo;
			this.hierarquia = json.hierarquia;
			this.vlMinimoParcela = json.vlMinimoParcela;
			this.condicoes = json.condicoes ? json.condicoes.map((item: any) => new CtaCondPgto(item)) : [];
        } else {
            // Inicialize com valores padrão ou vazios conforme necessário
            this.condicoes = new Array<CtaCondPgto>();
        }
    }

	
}


