<div [ngClass]="this.temaPadrao" class="carrinho-container">
	<mat-list>
		<mat-list-item>
			<h2 class="titulo color-trans-black">Carrinho</h2>
		</mat-list-item>

		<mat-divider></mat-divider>

		<mat-list-item *ngIf="carrinho.getItensCarrinho.length === 0" class="color-trans-black">
			Carrinho vazio :(
		</mat-list-item>

		<mat-list-item class="lista-item-carrinho" style="height: 131px;" *ngFor="let item of carrinho.getItensCarrinho">
			<app-item-carrinho [itemDetalhado]="item" style="width: 100%;display: inline-block;">
			</app-item-carrinho>
			<mat-divider></mat-divider>
		</mat-list-item>


		<mat-list-item class="valor-total" *ngIf="!(carrinho.getItensCarrinho.length === 0)">
			<div> <b>Total: {{carrinho.getTotal | currency:'BRL'}}</b></div>
		</mat-list-item>

		<mat-divider></mat-divider>
		<mat-list-item>
			<button class="btn-ver-carrinho" mat-flat-button color="primary" (click)="navegarCarrinho()">Ir para o
				carrinho</button>
		</mat-list-item>
	</mat-list>
</div>