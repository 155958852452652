import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { PedidoSimplificado } from 'src/app/persistences/pedido-simplificado';
import { PesquisaCustomizadaComponent } from 'src/app/components/genericos/pesquisa-customizada/pesquisa-customizada.component';
import { DownloadDocumentosService } from 'src/app/services/download-documentos.service';
import { ConfigService } from 'src/app/services/config.service';
import { JwtService } from 'src/app/jwt.service';
import { MatDialog } from '@angular/material/dialog';
import { PedidoDetalhadoComponent } from 'src/app/components/pedido-detalhado/pedido-detalhado.component';

@Component({
  selector: 'app-meus-pedidos',
  templateUrl: './meus-pedidos.component.html',
  styleUrls: ['./meus-pedidos.component.css']
})
export class MeusPedidosComponent implements OnInit {  

  resultados: PedidoSimplificado[] = [];
  isPesquisando = true;
  @ViewChild(PesquisaCustomizadaComponent) pesquisa: PesquisaCustomizadaComponent;

  constructor(
    private snackBarManager: SnackbarManager,
    private downloadDocumentos: DownloadDocumentosService,
    private configService: ConfigService,
    private jwtService: JwtService,
    public dialog: MatDialog,
  ) {  }
  
  mostrarFiltros = false;
	pesquisarNrPedido: number;
	pesquisarNmSituacao: string;
	pesquisarDtEmissao: string;
	pesquisarDtPrevisaoEnvio: string;
	pesquisarVlDesconto: string;
	pesquisarVlTotal: string;

  ngOnInit() {  
    this.isPesquisando = true;
    setTimeout(() => {
      this.pesquisa.confirmar();      
    }, 500);    
  }

  mostrarFiltrosDasColunas(){
    this.mostrarFiltros = !this.mostrarFiltros;
  }

  confirmar(pesquisa: PedidoSimplificado[]){
    this.isPesquisando = true;
    setTimeout(() => {
      this.resultados = pesquisa;
      this.isPesquisando = false;
    }, 500);
  }
 
  aviso(){
    this.snackBarManager.openSnackBar("Função em desenvolvimento","Ok",3000);
  }

  openDialogDetalhes(pedido: PedidoSimplificado): void {
    this.dialog.open(PedidoDetalhadoComponent, {
      width: '95%',
      height: '95%',
      data: {
        idPedido: pedido.idGeral,
      },
    });
  }

  getBoleto(id:number){
    this.downloadDocumentos.getBoleto(id).subscribe(res =>{
      const link = this.configService.getEndereco() + "/" + res['link'];
      window.open(link);
    },
    (error) => {
      this.jwtService.validarAcessToken(error);
      this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
    }
  );
  }

  getXml(id:number){
    this.downloadDocumentos.getXml(id).subscribe(res =>{
      const link = this.configService.getEndereco() + "/" + res['link'];
      window.open(link);
    },
    (error) => {
      this.jwtService.validarAcessToken(error);
      this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
    }
  );
  }

  getDanfe(id:number){
    this.downloadDocumentos.getDanfe(id).subscribe(res =>{
      const link = this.configService.getEndereco() + "/" + res['link'];
      window.open(link);
    },
    (error) => {
      this.jwtService.validarAcessToken(error);
      this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
    }
  );
  }

}
