<h2 class="toolbarFiltro" >Filtros</h2>
  <mat-divider></mat-divider> 
    <div class="check" *ngIf="this.configService.getConfig().getIdentificarItensNovos !== ''">
      <mat-checkbox color="primary" (change)="adicionaMarcado('cadastrosRecentes', 'cadastrosRecentes', $event.checked)"
        [checked]="selecionado('cadastrosRecentes', 'cadastrosRecentes')">Novidade
      </mat-checkbox>
    </div>
  <div *ngIf="listaMarcas.length > 0">
    <h4>Marca</h4>
    <div class="check" *ngFor="let marca of listaMarcas.slice(0, 5)">
      <mat-checkbox color="primary" (change)="adicionaMarcado(marca, 'marca', $event.checked)"
        [checked]="selecionado(marca, 'marca')">{{marca}}
      </mat-checkbox>
    </div>
    <mat-expansion-panel class="painel" *ngIf="listaMarcas.length > 5">
      <mat-expansion-panel-header>
        <mat-panel-title><b>Mais Marcas</b></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="check" *ngFor="let marca of listaMarcas.slice(5, listaMarcas.length)">
        <mat-checkbox color="primary" (change)="adicionaMarcado(marca, 'marca', $event.checked)"
          [checked]="selecionado(marca, 'marca')">{{marca}}          
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </div>
  <div class="divGrupo" *ngIf="listaGrupos.length > 1">
    <h4>Grupo</h4>
    <div class="check" *ngFor="let grupo of listaGrupos.slice(0, 5)">
      <mat-checkbox color="primary" (change)="adicionaMarcado(grupo, 'grupo', $event.checked)"
        [checked]="selecionado(grupo, 'grupo')">{{grupo}}
      </mat-checkbox>
    </div>
    <mat-expansion-panel class="painel" *ngIf="listaGrupos.length > 5">
      <mat-expansion-panel-header>
        <mat-panel-title><b>Mais Grupos</b></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="check" *ngFor="let grupo of listaGrupos.slice(5, listaGrupos.length)">
        <mat-checkbox color="primary" (change)="adicionaMarcado(grupo, 'grupo', $event.checked)"
          [checked]="selecionado(grupo, 'grupo')">{{grupo}}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </div>
  <div class="divSubgrupo" *ngIf="listaSubGrupos.length > 1">
    <h4>Subgrupo</h4>
    <div class="check" *ngFor="let subgrupo of listaSubGrupos.slice(0, 5)">
      <mat-checkbox color="primary" (change)="adicionaMarcado(subgrupo, 'subgrupo', $event.checked)"
        [checked]="selecionado(subgrupo, 'subgrupo')">{{subgrupo}}
      </mat-checkbox>
    </div>
    <mat-expansion-panel class="painel" *ngIf="listaSubGrupos.length > 5">
      <mat-expansion-panel-header>
        <mat-panel-title><b>Mais SubGrupo</b></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="check" *ngFor="let subgrupo of listaSubGrupos.slice(5, listaSubGrupos.length)">
        <mat-checkbox color="primary" (change)="adicionaMarcado(subgrupo, 'subgrupo', $event.checked)"
          [checked]="selecionado(subgrupo, 'subgrupo')">{{subgrupo}}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </div>
  <div class="divLinha" *ngIf="listaLinhas.length > 1">
    <h4>Linha</h4>
    <div class="check" *ngFor="let linha of listaLinhas.slice(0, 5)">
      <mat-checkbox color="primary" (change)="adicionaMarcado(linha, 'linha', $event.checked)"
        [checked]="selecionado(linha, 'linha')">{{linha}}
      </mat-checkbox>
    </div>
    <mat-expansion-panel class="painel" *ngIf="listaLinhas.length > 5">
      <mat-expansion-panel-header>
        <mat-panel-title><b>Mais Linha</b></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="check" *ngFor="let linha of listaLinhas.slice(5, listaLinhas.length)">
        <mat-checkbox color="primary" (change)="adicionaMarcado(linha, 'linha', $event.checked)"
          [checked]="selecionado(linha, 'linha')">{{linha}}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </div>
  
  <button class="botao-filtrar" mat-raised-button color="primary" (click)="filtraItens()">Filtrar
    <mat-icon style="margin-left: 15px;">filter_list</mat-icon>
  </button>
  <br>
  <button class="botao-filtrar" mat-raised-button color="accent" (click)="limparFiltro()">Limpar Filtro
    <mat-icon style="margin-left: 5px; font-size: 21px;">remove_circle_outline</mat-icon>
  </button>
  <br>
  <br>

