<mat-card>
	<mat-toolbar color="primary">Meu Pedidos</mat-toolbar>
	<mat-card-content>
		<app-pesquisa-customizada (resultadoDaPesquisa)="confirmar($event)" (isPesquisando)="isPesquisando"></app-pesquisa-customizada>
		<div>
		<div class="alinhar-itens">
			<mat-spinner class="spinner-align" mode="indeterminate" *ngIf="isPesquisando">
			</mat-spinner>
		</div>			
			<div class="container" *ngIf="!isPesquisando && resultados.length <= 0"> Pedidos não encontrados :( </div>
			<div class="scrollBar">
				<table *ngIf="!isPesquisando && resultados.length > 0" class="tabela tabela-responsive">
					<thead>
						<tr>
							<th>Nr. Pedido</th>
							<th>Situação</th>
							<th>Dt. Emissão</th>
							<th>Dt. Prev. Envio</th>
							<th>Desconto</th>
							<th>Vl. Total</th>
							<th>Documentos</th>
							<th class="btn-row" matTooltip="Mostrar filtros" (click)="mostrarFiltrosDasColunas()">
								<mat-icon>filter_alt</mat-icon>
							</th>
						</tr>
						<tr *ngIf="mostrarFiltros">
							<th><input type="text" [(ngModel)]="pesquisarNrPedido"></th>
							<th><input type="text" [(ngModel)]="pesquisarNmSituacao"></th>
							<th><input type="text" [(ngModel)]="pesquisarDtEmissao"></th>
							<th><input type="text" [(ngModel)]="pesquisarDtPrevisaoEnvio"></th>
							<th><input type="text" [(ngModel)]="pesquisarVlDesconto"></th>
							<th><input type="text" [(ngModel)]="pesquisarVlTotal"></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="let pedido of resultados | filtro: pesquisarNrPedido: 'nrPedido' | filtro: pesquisarNmSituacao: 'nmSituacao'
	                                     | filtro: pesquisarDtEmissao: 'dtEmissao' | filtro: pesquisarDtPrevisaoEnvio: 'dtPrevisaoEnvio'
	                                     | filtro: pesquisarVlDesconto: 'vlDesconto' | filtro: pesquisarVlTotal: 'vlTotal'">
							<td>{{pedido.nrPedido}}</td>
							<td>{{pedido.nmSituacao}}</td>
							<td>{{pedido.dtEmissao}}</td>
							<td>{{pedido.dtPrevisaoEnvio}}</td>
							<td>{{pedido.vlDesconto.toFixed(2) | currency:'R$':'code'}}</td>
							<td sty>{{pedido.vlTotal.toFixed(2) | currency:'R$':'code'}}</td>
							<td>
								<div class="flex-row">
									<div *ngIf="pedido.temBoleto" class="table-button">
										<button mat-raised-button color="primary" (click)="getBoleto(this.pedido.idGeral)" >Boleto <mat-icon>file_download</mat-icon></button>
									</div>
									<div *ngIf="pedido.temDanfe" class="table-button">
										<button mat-raised-button color="primary" (click)="getDanfe(this.pedido.idGeral)" *ngIf="pedido.temDanfe">DANFe <mat-icon>file_download</mat-icon></button>
									</div>
									<div *ngIf="pedido.temXml" class="table-button">
										<button mat-raised-button color="primary" (click)="getXml(this.pedido.idGeral)" *ngIf="pedido.temXml">XML <mat-icon>file_download</mat-icon></button>
									</div>
								</div>
							</td>
							<td>
								<mat-icon mat-button [matMenuTriggerFor]="menuPedido">more_vert</mat-icon>
								<mat-menu #menuPedido="matMenu">
									<div>        
									  <button mat-menu-item style="background-color: white" (click)="openDialogDetalhes(pedido)"><b>Visualizar Itens</b></button>
									  <mat-divider></mat-divider>
									</div>
								  </mat-menu>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</mat-card-content>
</mat-card>