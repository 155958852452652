<div class="grid-container" color="primary">
	<div class="item item-1">
		<div style="margin-left: 8px;">
			<img *ngIf="(imagem !== undefined && imagem !== '')" class="imagem-item" mat-card-image src="{{imagem}}"
				(click)="openDialogDetalhes()">
			<img *ngIf="(imagem === '')" class="sem-imagem imagem-item" mat-card-image src="assets/images/no_image.png"
				(click)="openDialogDetalhes()">
		</div>
	</div>
	<div matTooltip="{{itemDetalhado.itemDetalhes.nmItem}}" class="item item-2">
		<div>
			{{itemDetalhado.itemDetalhes.nmItem}}
		</div>
		<br>
		<div>
			<b>{{itemDetalhado.itemDetalhes.cdItem}}</b>
		</div>
	</div>
	<div class="item item-3">
		<app-quantidade-lista
			[item]="itemDetalhado"
			[unMedVenda]="itemDetalhado.itemPedido.unMedVenda" [quantidadeItemString]="itemDetalhado.itemPedido.quantidade.toLocaleString('pt-BR',{useGrouping: false})"
			[estoqueDisponivel]="itemDetalhado.itemPedido.quantidade > itemDetalhado.itemPedido.quantidadeEstoque">
		</app-quantidade-lista>
	</div>
	<div class="item item-4">
		<div>
			Valor: {{itemDetalhado.itemPedido.valorLiquidoIpiSt | currency:'R$':'code'}}
			<img *ngIf="(itemDetalhado.itemPedido.vlPrecoTabelaNovo !== itemDetalhado.itemPedido.vlPrecoTabelaAntigo)"
				src="assets/images/warning.png" style="height: 16px;" (click)="onClickPreco()">
		</div>
		<br>
		<div>
			<b> Total: {{itemDetalhado.itemPedido.total | currency:'R$':'code'}}</b>
		</div>
	</div>
	<div class="item item-5">
		<button mat-mini-fab color="primary" (click)="openDialogDetalhes()">
			<mat-icon>touch_app</mat-icon>
		</button>

		<button (click)="listarPrecosDoItem()" color="primary" matTooltip="Outros preços" mat-mini-fab [hidden]="outrosPrecosDisponivel()">
            <mat-icon>currency_exchange</mat-icon>
        </button>
        <br><br> 		
	</div>
</div>