import { Injectable } from '@angular/core';
import { Funcionario } from '../persistences/funcionario';
import { FuncionarioService } from '../services/funcionario-service.service';

@Injectable({
    providedIn: 'root',
})
export class FuncionarioManager {

    public funcionario: Funcionario;

    constructor(
        private funcionarioService: FuncionarioService
    ) { }

    getFuncionario(cdUsuario: number): Promise<Funcionario> {
        return new Promise((resolve, reject) => {
            this.funcionarioService.getFuncionarioByCdUsuario(cdUsuario.toString())
            .subscribe(
                (response) => {
                    this.funcionario = response;
                    resolve(this.funcionario);
                },
                (error) => {
                    reject(error.message);                    
                }
            );
        });
    }
}
