import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtService } from 'src/app/jwt.service';
import { ItemManager } from 'src/app/managers/item-manager';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemDetalhes } from 'src/app/persistences/item-detalhes';
import { ItemPedido } from 'src/app/persistences/item-pedido';
import { ItemSimplesView } from 'src/app/persistences/item-simples-view';
import { ConfigService } from 'src/app/services/config.service';
import { ItemService } from 'src/app/services/item.service';
import { PesquisaService } from 'src/app/services/pesquisa.service';
import Item from "../../persistences/item";
import { ItemDescricaoDetalhadoComponent } from '../item-descricao-detalhado/item-descricao-detalhado.component';
import { ListaSimilaresComponent } from '../lista-similares/lista-similares.component';
import { IDialogItemDetalhado } from './../../interfaces/idialog-item-detalhado';
import { InfoVeiculo } from './../../persistences/info-veiculo';

@Component({
	selector: 'app-item-detalhado',
	templateUrl: './item-detalhado.component.html',
	styleUrls: [
		'./item-detalhado.component.css',
	]
})
export class ItemDetalhadoComponent implements OnInit, AfterViewInit {

	@Input()
	public imageObject: Array<object> = [];
	public imagensCarregadas = false;
	public cdItemAtual: string;
	@Input()
	public itemAtual: ItemDetalhado;
	public carregado = false;
	public afterInitView = false;
	public temDescricao = false;
	public temVeiculo = false;
	public temConversao = false;
	colunasCodigos = ['codigo', 'descricao', 'tipo'];
	colunasVeiculos = ['montadora', 'modelo', 'anoIni', 'anoFin', 'tipo'];
	collapsedHeight = "28px";
	expandedHeight = "32px";
	@ViewChild('listaSimilares') myDiv: ListaSimilaresComponent;
	@ViewChild('itemDescricaoDetalhado') itemDescricaoDetalhado: ItemDescricaoDetalhadoComponent;
	@Output()
	public addItem = new EventEmitter<ItemDetalhado>();
	public caractere:string;
	public listaCaractere:string;
	public listaCaractere2:string;
	public remover:string;
	private idItem: number;
	public temaPadrao: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IDialogItemDetalhado,
		public dialogRef: MatDialogRef<ItemDetalhadoComponent>,
		private itemService: ItemService,
		private itemManager: ItemManager,
		private pesquisaService: PesquisaService,
		private snackBarManager: SnackbarManager,
		private jwtService: JwtService,
        private configService: ConfigService,
	) {
		this.itemAtual = data.itemDetalhado;
		this.idItem = data.id;
	}

	ngOnInit() {
        this.temaPadrao = this.configService.getConfig().getTemaPadrao;
		this.configPage();
		this.carregarImagens(this.idItem);
	}

	ngAfterViewInit() {
		this.afterInitView = true;
		this.myDiv.carregaSimilares(this.idItem);
	}

	getItemAtual(): ItemDetalhado {
		this.jwtService.validarAcessToken();
		this.itemAtual.itemDetalhes.idItem = this.idItem;
		return this.itemAtual;
	}

	fichaTecnicaProduto() {
		this.remover = this.itemAtual.itemDetalhes.fichaTecnica;
    }
	
	configPage() {
		this.carregaConversao();
		this.carregaInfoVeiculo();
		this.validaVazioExpansiveis();
		this.carregado = true;
	}

	addToCart(itemDet: ItemDetalhado) {
		itemDet.itemDetalhes.idItem = this.idItem;
		itemDet.itemPedido.quantidade - this.itemAtual.itemPedido.quantidade;
		this.addItem.emit(itemDet);
	}

	carregarItemDetalhado(idItem: number) {
		this.pesquisaService.carregaItensByIds([idItem]).subscribe(
			(itemRetornado: Item[]) => {
				const itemSimplesView: ItemSimplesView[] = this.itemManager.converterItensParaItensSimples(itemRetornado);
				const itemSimiliarSelecionado: ItemSimplesView = itemSimplesView[0];

				const item = itemSimiliarSelecionado.itemDetalhado;
				this.cdItemAtual = item.itemDetalhes.cdItem;
				this.itemAtual = item;
				this.itemAtual.itemDetalhes.qtdMin = item.itemDetalhes.qtdMin;
				this.itemAtual.itemDetalhes.valorMultiplo = item.itemDetalhes.valorMultiplo;
				this.itemAtual.itemPedido.quantidade = this.itemManager.getQuantidade(item.itemDetalhes.idItem);
				this.itemManager.getValor(item);
				this.idItem = item.itemDetalhes.idItem;
				this.configPage();
			},
			(error) => {
				this.fechar();
				this.jwtService.validarAcessToken(error);
				this.snackBarManager.openSnackBar("Não é possivel consultar item detalhado! " + error.error, "Ok", 3000);
			})
	}

	carregaConversao() {
		this.itemService.getSimilares(this.itemAtual.itemDetalhes.idItem)
			.subscribe(
				(res) => {
					if (res != undefined) {
						this.itemAtual.itemDetalhes.cdsConversao = res["codigos"];
						this.validaVazioExpansiveis();
					}
				},
				(error) => {
					this.fechar();
					this.jwtService.validarAcessToken(error);
					this.snackBarManager.openSnackBar("Não é possivel consultar itens similares! " + error.error, "Ok", 3000);
				}
			);
	}

	carregaInfoVeiculo() {
		this.itemService.getInfoVeiculo(this.idItem)
			.subscribe(
				(res: InfoVeiculo[]) => {
					this.itemAtual.itemDetalhes.infoVeiculo = res;
					this.validaVazioExpansiveis();
				},
				(error) => {
					this.fechar();
					this.jwtService.validarAcessToken(error);
					this.snackBarManager.openSnackBar("Não é possivel consultar informações do veículo! " + error.error, "Ok", 3000);
				}
			);
	}

	selecionarSimilar(idItem: number) {
		this.carregado = false;
		this.carregarItemDetalhado(idItem);
		this.itemDescricaoDetalhado.item = this.itemAtual;
		this.carregarImagens(idItem);
	}

	fechar() {
		this.dialogRef.close();
	}

	validaVazioExpansiveis() {
		this.temDescricao =
			this.itemAtual.itemDetalhes.fichaTecnica != null &&
			this.itemAtual.itemDetalhes.fichaTecnica != "";

		this.temVeiculo =
			this.itemAtual.itemDetalhes.infoVeiculo != null &&
			this.itemAtual.itemDetalhes.infoVeiculo.length > 0;

		this.temConversao =
			this.itemAtual.itemDetalhes.cdsConversao != null &&
			this.itemAtual.itemDetalhes.cdsConversao.length > 0;
	}

	carregarImagens(idItem: number) {
		this.imagensCarregadas = false;
		this.itemService.getImagem(idItem, false)
			.subscribe(
				(imagens: string[]) => {
					this.imageObject = [];
					if (imagens.length == 0) {
						this.imageObject.push(
							{
								image: "assets/images/sem_imagem.png",
								thumbImage: "assets/images/sem_imagem.png",
							}
						);
					}
					else {
						imagens.forEach(imagem => {
							this.imageObject.push(
								{
									image: "data:image/jpeg;base64," + imagem,
									thumbImage: "data:image/jpeg;base64," + imagem,
								}
							);
						})
					}
					this.imagensCarregadas = true;
				},
				(error) => {
					this.fechar();
					this.jwtService.validarAcessToken(error);
					this.snackBarManager.openSnackBar("Não é possivel carregar imagem padrão para o item! " + error.error, "Ok", 3000);
				}
			);
	}
}
