import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Config } from './../persistences/config';
import { LocalStorageSingletonService } from '../singletons/local-storage-singleton.service';
import { ConfigAux } from './configAux';


@Injectable({
    providedIn: 'root'
})
export class ConfigService {    

    constructor(private localStorageSingletonService: LocalStorageSingletonService, private configAux: ConfigAux, private http: HttpClient) {
    }

    loadFullConfig(): Promise<any> {

        const endereco = this.getEndereco();
        const headers = this.getHeaders();
    
        return new Promise((resolve, reject) => {
            this.http.get(`${endereco}/business/config/all`, { headers: headers }).subscribe(
                (data) => {
                    const config = this.localStorageSingletonService.buscarConfig();
                    config._setFullConfig(data, config);
                    this.localStorageSingletonService.atualizarConfig(config);
                    resolve(config);
                },
                (error) => {
                  reject("Erro ao carregar configurações completas. " + error.error + ' - ' + error);
                }
            )
        });
      }

    getConfig(): Config{
        const config : Config = this.localStorageSingletonService.buscarConfig();            
        if((!config) || (config.getIp == null) || (!config.getIp)) {
            this.configAux.loadAppConfig();
        }
        return config; 
    }
    
    /*Somente o CartSingletonService pode atualizar a config, nunca remover private */
    private setConfig(config : Config) : Config {
        this.localStorageSingletonService.atualizarConfig(config);
        return config;     
    }
    
    getEndereco(): string {
        const config: Config = this.getConfig();
        return config.getIp + ":" + config.getPorta;
    }


    getHeaders(): HttpHeaders {
        return new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('authToken', localStorage.getItem('access_token_business'))
    }

    configuraLayout(isLayoutCards: boolean) {
        const config: Config = this.getConfig();
        config._setIsLayoutCards(isLayoutCards);
        this.setConfig(config);
    }

    configuraOrdenacao(ordenacaoDefault: string) {
        const config: Config = this.getConfig();
        config._setOrdenacaoDefault(ordenacaoDefault);
        this.setConfig(config);
    }


    configuraServiceAppConfig(configApp) {
        if (configApp != null) {         
            const config: Config = this.getConfig();   
            config._setIp(configApp.configuracoes.enderecoIp);
            config._setPorta(configApp.configuracoes.porta);
            config._setCdSituacao(configApp.situacaoPedido.default);
            config._setLayout(configApp.layoutDeExibicao.default); 
            config._setSizeList(configApp.tamanhoLista.size);
            config._setExibeDesconto(configApp.informacaoProduto.exibeDesconto);
            config._setExibeEstoque(configApp.informacaoProduto.exibeEstoque);
            config._setOrdenacaoDefault(configApp.tipoOrdenacao.default);
            config._setTemaPadrao(configApp.temaBusiness.temaPadrao);          
            config._setUsaValorDeFretePadraoDoCliente(configApp.usaValorDeFretePadraoDoCliente !== undefined ? 
                configApp.usaValorDeFretePadraoDoCliente.usaValorDoCadastro : false);
            config._setTipoItem(configApp.consultaTipoItem.tipoItem);
            config._setCdtMotivo(configApp.cdMotivo);
            this.setConfig(config);
        }

    }

}
