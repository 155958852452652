<div>
	<h2 class="titulo">Item</h2>
	<mat-divider class="divider-margin"></mat-divider>
	<br>
	<div class="image-container">
		<div class="divSpinner" *ngIf="!imagensCarregadas">
			<mat-spinner class="spinner"></mat-spinner>
		</div>
		<ng-image-slider *ngIf="imagensCarregadas" [images]="imageObject" [infinite]="true"
			[animationSpeed]="0.4" [imageSize]="{width:	'99%' , height: '180px', space: 4}" [manageImageRatio]="true"
			[autoSlide]="false" [showArrow]="imageObject.length > 1" #nav>
		</ng-image-slider>
	</div>
	<form class="form-item-descricao">
		<mat-form-field class="field-width-100 cor">
			<mat-label>Descrição</mat-label>
			<input matInput disabled [(ngModel)]="item.itemDetalhes.nmItem" name="nmItem">
		</mat-form-field>
		<br>
		<mat-form-field class="field-width-25 campo-esquerdo cor" *ngIf="item.itemDetalhes.cdItem !== undefined && item.itemDetalhes.cdItem.length > 0">
			<mat-label>Cd. Item</mat-label>
		<b><input matInput disabled [(ngModel)]="item.itemDetalhes.cdItem" name="cdItem"></b>
		</mat-form-field>
		<mat-form-field class="field-width-25 campo-esquerdo cor" *ngIf="item.itemDetalhes.cdOriginal !== undefined && item.itemDetalhes.cdOriginal.length > 0">
			<mat-label>Cd. Original</mat-label>
			<input matInput disabled [(ngModel)]="item.itemDetalhes.cdOriginal" name="cdOriginal">
		</mat-form-field>
		<mat-form-field class="campo-esquerdo cor" *ngIf="item.itemDetalhes.cdFabricante !== undefined && item.itemDetalhes.cdFabricante.length > 0"
			[ngClass]="(item.itemDetalhes.nmMarca !== undefined && item.itemDetalhes.nmMarca.length > 0) ? 'field-width-50':'field-width-25'"
			>
			<mat-label>Cd. Fabricante</mat-label>
			<input matInput disabled [(ngModel)]="item.itemDetalhes.cdFabricante" name="cdFabricante">
		</mat-form-field>
		<mat-form-field class="campo-esquerdo cor" *ngIf="item.itemDetalhes.nmMarca !== undefined && item.itemDetalhes.nmMarca.length > 0"
			[ngClass]="(item.itemDetalhes.cdFabricante !== undefined && item.itemDetalhes.cdFabricante.length > 0) ? 'field-width-50':'field-width-25'"
			>
			<mat-label>Marca</mat-label>
			<input matInput disabled [(ngModel)]="item.itemDetalhes.nmMarca" name="nmMarca">
		</mat-form-field>
	</form>
</div>