import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClienteFilial } from '../persistences/cliente-filial';
import { ConfigService } from './config.service';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';


@Injectable({
	providedIn: 'root'
})
export class ClienteService {

	constructor(
		private httpClient: HttpClient,
		private configService: ConfigService,
		private clienteSingletonService: ClienteSingletonService
	) { }

	getClienteServidor() {
		let httpParams = new HttpParams();
		httpParams = httpParams
			.append('id', this.clienteSingletonService.getClienteEstatico().getCdCliente);


		return this.httpClient.get(
			this.configService.getEndereco() + '/Cliente/getById',
			{
				headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
	}

	getClienteFilialServidor(cdFilial: number): Observable<ClienteFilial> {
		let httpParams = new HttpParams();
		httpParams = httpParams
			.append('cdFilial', cdFilial.toString());
		return this.httpClient.get<ClienteFilial>(
			this.configService.getEndereco() + '/Filial/getByCdFilial',
			{
				headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
	}

	clienteTemVendedorPadrao(){
		return this.httpClient.get(
				this.configService.getEndereco() + '/ClienteVendedor/getVendedorPadrao',
				{
					headers: this.configService.getHeaders(),
				}
			)
	}
}
