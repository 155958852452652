import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { PesquisaService } from 'src/app/services/pesquisa.service';
import { JwtService } from 'src/app/jwt.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

  @Output()
  idsPesquisaEmitter = new EventEmitter<number[]>();
  @Output()
  isLoadingEmitter = new EventEmitter<boolean>();
  @Output()
  ocultaAbasEmitter = new EventEmitter<boolean>();
  
  idsPesquisa: number[];
  strPesquisa: string;

  constructor(
    private pesquisaService: PesquisaService,
    private jwtService: JwtService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(){    
    if(localStorage.getItem('pesquisa') !== null && localStorage.getItem('pesquisa') !== ''){
        this.strPesquisa = localStorage.getItem('pesquisa')
        this.pesquisar();
      }
    }

  pesquisar() {
    this.redirectToHome();
    this.ocultaAbasEmitter.emit(false);
    this.isLoadingEmitter.emit(true);
    this.idsPesquisa = new Array<number>()
    this.pesquisaService.pesquisa(this.strPesquisa).subscribe(
      (produtos) => {

        produtos.forEach(item => {
          this.idsPesquisa.push(item.id_item);
        });
        this.pesquisaService.setProdutosPesquisados(produtos);
        this.idsPesquisaEmitter.emit(this.idsPesquisa);
        this.isLoadingEmitter.emit(false);
      },
      (error) => {
        this.isLoadingEmitter.emit(false);
        this.jwtService.validarAcessToken(error);
      }
    );
  }

  redirectToHome(){
    if(this.route.snapshot.url.join('/') === 'carrinho'){
      localStorage.setItem('pesquisa',this.strPesquisa)
      this.router.navigateByUrl('/');
    } else{
      localStorage.removeItem('pesquisa');
    }
  }
}
