<mat-card class="mat-card">
	<mat-toolbar color="primary">Meu Títulos</mat-toolbar>
	<mat-card-content>
		<form [formGroup]="formPesquisa" class="search-form">
			<div class="form-row">
				<mat-form-field class="flex-item">
				  <mat-label>Tipo</mat-label>
				  <mat-select [(ngModel)]="tipoSelecionado" formControlName="tipo">
					<mat-option class="mat-options" *ngFor="let tipo of tipos" [value]="tipo.value">
					  {{ tipo.label }}
					</mat-option>
				  </mat-select>
				</mat-form-field>
				<mat-form-field class="flex-item">
					<mat-label>Data inicial</mat-label>
					<input matInput [matDatepicker]="dataInicial" formControlName="dataInicial" />
					<mat-datepicker-toggle matSuffix [for]="dataInicial"></mat-datepicker-toggle>
					<mat-datepicker class="mat-calendar" #dataInicial></mat-datepicker>
					<mat-error *ngIf="formPesquisa.get('dataInicial').invalid">Data inválida</mat-error>
				</mat-form-field>
				<mat-form-field class="flex-item">
					<mat-label>Data final</mat-label>
					<input matInput [matDatepicker]="dataFinal" formControlName="dataFinal" />
					<mat-datepicker-toggle matSuffix [for]="dataFinal"></mat-datepicker-toggle>
					<mat-datepicker #dataFinal></mat-datepicker>
					<mat-error *ngIf="formPesquisa.get('dataFinal').invalid">Data inválida</mat-error>
				</mat-form-field>
				<mat-checkbox class="margin-top-8" color="primary" [(ngModel)]="isCredito" formControlName="isCredito">
					Crédito
				</mat-checkbox>
				<button
					mat-raised-button
					color="primary"
					(click)="getTitulosComFiltro()"
					[disabled]="formPesquisa.invalid"
					class="flex-item custom-button"
					>
					OK
				</button>
			</div>
		</form>
		<div>
			<div class="alinhar-itens">
				<mat-spinner class="spinner-align" mode="indeterminate" *ngIf="isPesquisando">
				</mat-spinner>
			</div>
			<div class="container" *ngIf="!isPesquisando && listaTitulos.length <= 0"> Títulos não encontrados :( </div>
			<div class="scrollBar">
				<table *ngIf="!isPesquisando && listaTitulos.length > 0" class="tabela tabela-responsive">
					<thead>
						<tr>
							<th>Nr. Documento</th>
							<th>Parcela(s)</th>
							<th>Dt. Vencimento</th>
							<th>Cond. de pagamento</th>
							<th>Saldo</th>
							<th>Valor Nominal</th>
							<th>Forma de pagamento</th>
							<th>Status</th>
							<th>Boleto</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="let titulo of listaTitulos">
							<td>{{titulo.nrDocumento}}</td>
							<td>{{titulo.parcela}}</td>
							<td>{{titulo.dtVcto}}</td>
							<td>{{titulo.nmCondPgto}}</td>
							<td>{{titulo.vlSaldo.toFixed(2) | currency:'R$':'code'}}</td>
							<td>{{titulo.vlNominal.toFixed(2) | currency:'R$':'code'}}</td>
							<td>{{titulo.nmFormaPgto}}</td>
							<td *ngIf="calculaStatus(titulo.vlSaldo, titulo.dtVcto)==='QUITADO'"><mat-icon [matTooltip]="'Quitado'" style="color:#3a8346">check_circle_outline</mat-icon></td>
							<td *ngIf="calculaStatus(titulo.vlSaldo,titulo.dtVcto)==='VENCENDO'"><mat-icon [matTooltip]="'Vencendo'" style="color:#e4cf12">error_outline</mat-icon></td>
							<td *ngIf="calculaStatus(titulo.vlSaldo,titulo.dtVcto)==='VENCIDO'"><mat-icon [matTooltip]="'Vencido'" style="color:#be2d2d">error_outline</mat-icon></td>
							<td *ngIf="calculaStatus(titulo.vlSaldo,titulo.dtVcto)==='PENDENTE'"><mat-icon [matTooltip]="'Pendente'" style="color:gray">error_outline</mat-icon></td>
							<td>
								<div class="flex-row">
									<div *ngIf="titulo.temPdf" class="table-button">
										<button mat-raised-button color="primary" (click)="getBoleto(this.titulo.idCprParcela)">Boleto <mat-icon>file_download</mat-icon></button>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</mat-card-content>
</mat-card>
