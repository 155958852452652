export class Config {

    private sizeList: number;
    private exibeEstoque: boolean;
    private exibeDesconto: boolean;
    private ordenacaoDefault: string;
    
    private ip: string;
    private porta: string;
    private cdSituacao: number;
    private cdTpEntrega: number;
    private usaValorDeFretePadraoDoCliente: boolean;
    private layout: number;
    private appConfig;
    private temaPadrao: string;
    private isLayoutCards: boolean;
    private tipoItem: string;
    private cdMotivo: string;
    private identificarItensNovos: string;

    /*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO CartSingletonService*/    
    _setInitialConfig(appConfig: any): void {
        this.appConfig = appConfig;
        this.ip = this.appConfig.configuracoes.enderecoIp;
        this.porta = this.appConfig.configuracoes.porta;
        this.temaPadrao = this.appConfig.temaBusiness.temaPadrao;
    }
    
    _setFullConfig(appConfig: any, configInicial: any): void {
        this.ip = configInicial.ip;
        this.porta = configInicial.porta;
        this.temaPadrao = configInicial.temaPadrao;
        appConfig.forEach(config => {
            if(config.chave === "SITUACAO_PEDIDO"){
                this.cdSituacao = config.valor;
            }else if(config.chave === "LAYOUT_EXIBICAO"){
                this.layout = config.valor;
                config.valor === '2' ? this.isLayoutCards = true : this.isLayoutCards = false;
            }else if(config.chave === "TIPO_ENTREGA"){
                this.cdTpEntrega = config.valor;
            }else if(config.chave === "TAMANHO_LISTA"){
                this.sizeList = Number(config.valor);
            }else if(config.chave === "USA_VALOR_FRETE_CLIENTE"){
                this.usaValorDeFretePadraoDoCliente = config.valor === "S" ? true : false;
            }else if(config.chave === "CONSULTA_TIPO_ITEM"){
                this.tipoItem = config.valor;
            }else if(config.chave === "MOTIVO_VENDA_PERDIDA"){
                this.cdMotivo = config.valor;
            }else if(config.chave === "TIPO_ORDENACAO"){
                this.ordenacaoDefault = config.valor;
            }else if(config.chave === "IDENTIFICAR_ITENS_NOVOS"){
                this.identificarItensNovos = config.valor;
            }
        });
    }

    constructor(json?: any) {
        this.exibeEstoque = false;
        this.exibeDesconto = false;
        this.usaValorDeFretePadraoDoCliente = false;
        if (json) {
            // Inicialização a partir do objeto JSON
            this.sizeList = json.sizeList;
            this.exibeEstoque = json.exibeEstoque;
            this.exibeDesconto = json.exibeDesconto;
            this.ordenacaoDefault = json.ordenacaoDefault;
            this.ip = json.ip;
            this.porta = json.porta;
            this.cdSituacao = json.cdSituacao;
            this.usaValorDeFretePadraoDoCliente = json.usaValorDeFretePadraoDoCliente;
            this.layout = json.layout;
            this.appConfig = json.appConfig;
            this.temaPadrao = json.temaPadrao;
            this.isLayoutCards = json.isLayoutCards;
            this.cdTpEntrega = json.cdTpEntrega;
            this.tipoItem = json.tipoItem;
            this.cdMotivo = json.cdMotivo
            this.identificarItensNovos = json.identificarItensNovos;
        }                 

    }


    /*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO ConfigService*/

    get getSizeList(): number {
        return this.sizeList;
    }

    _setSizeList(value : number): void  {
        this.sizeList = value;
    }

    get getExibeEstoque(): boolean {
        return this.exibeEstoque;
    }

    _setExibeEstoque(value : boolean): void  {
        this.exibeEstoque = value;
    }

    get getExibeDesconto(): boolean {
        return this.exibeDesconto;
    }

    _setExibeDesconto(value : boolean): void  {
        this.exibeDesconto = value;
    }

    get getOrdenacaoDefault(): string {
        return this.ordenacaoDefault;
    }

    _setOrdenacaoDefault(value : string): void  {
        this.ordenacaoDefault = value;
    }

    get getIp(): string {
        return this.ip;
    }

    _setIp(value : string): void  {
        this.ip = value;
    }

    get getPorta(): string {
        return this.porta;
    }

    _setPorta(value : string): void  {
        this.porta = value;
    }

    get getUsaValorDeFretePadraoDoCliente(): boolean {
        return this.usaValorDeFretePadraoDoCliente;
    }

    _setUsaValorDeFretePadraoDoCliente(value : boolean): void  {
        this.usaValorDeFretePadraoDoCliente = value;
    }

    get getLayout(): number {
        return this.layout;
    }

    _setLayout(value : number): void  {
        this.layout = value;
    }

    get getAppConfig() {
        return this.appConfig;
    }

    _setAppConfig(value : any): void  {
        this.appConfig = value;
    }

    get getTemaPadrao(): string {
        return this.temaPadrao;
    }

    _setTemaPadrao(value : string): void  {
        this.temaPadrao = value;
    }

    get getIsLayoutCards(): boolean {
        return this.isLayoutCards;
    }

    _setIsLayoutCards(value : boolean): void  {
        this.isLayoutCards = value;
    }

    get getCdSituacao(): number {
        return this.cdSituacao;
    }

    _setCdSituacao(value : number): void  {
        this.cdSituacao = value;
    }

    get getCdTpEntrega(): number {
        return this.cdTpEntrega;
    }

    get getTipoItem(): string {
        return this.tipoItem;
    }
    _setTipoItem(value: string) {
        this.tipoItem = value;
    }

    get getCdMotivo(): string {
        return this.cdMotivo;
    }

    _setCdtMotivo(value: string) {
        this.cdMotivo = value;
    }

    get getIdentificarItensNovos(): string {
        return this.identificarItensNovos;
    }

    _setIdentificarItensNovos(value: string) {
        this.identificarItensNovos = value;
    }
}
