<mat-card>
  <mat-toolbar color="primary">Meu Perfil</mat-toolbar>
  <mat-card-content>
    <form [formGroup]="formGroup">
      <mat-form-field class="full-width">
        <mat-label>Nome Cliente</mat-label>
        <input matInput placeholder="Nome Cliente" formControlName="nmCliente" readonly>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Nome Fantasia</mat-label>
        <input matInput placeholder="Nome Fantasia" formControlName="nmFantasia" readonly>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Cpf/Cnpj</mat-label>
        <input matInput placeholder="Cpf/Cnpj" formControlName="cpfCnpj"
          readonly>
      </mat-form-field>
      <mat-form-field class="full-width" *ngIf="temAcesso">
        <mat-label>Percentual de Markup %</mat-label>
        <input matInput type="number" placeholder="% markup" formControlName="markupCliente">        
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions class="button-container">
    <button mat-raised-button color="primary" (click)="atualizarDadosDoCliente()">Atualizar Dados</button>
  </mat-card-actions>
</mat-card>