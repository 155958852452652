import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IEstadosDaPesquisa } from 'src/app/interfaces/iestados-pesquisa';
import { JwtService } from 'src/app/jwt.service';
import { ItemManager } from 'src/app/managers/item-manager';
import { ItemSimplesView } from 'src/app/persistences/item-simples-view';
import { ConfigService } from 'src/app/services/config.service';
import { FiltroLateralManager } from '../../managers/filtro-manager';
import { PesquisaService } from 'src/app/services/pesquisa.service';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.css']
})
export class PainelComponent implements OnInit {
  
  sideMenuMode = 'side';
  isSmallScreen: boolean;
  estadosDaPesquisa: IEstadosDaPesquisa;
  page = 1;
  notPesquisando = true;
  pagination = false;
  inicioIndexPagina = 0;
  fimIndexPagina: number;
  pageSize = 20;
  filtroLateralAberto = false;  
  @Input()
  idsPesquisados: number[];
  listaIdsOriginais: number[];
  listaMarcados: Map<string, string[]>;
  listaItens: ItemSimplesView[];
  listaIdsItens: number[];
  alturaTela: number;

  @Output()
  ordenacaoDefaultEmitter = new EventEmitter<string>()
  constructor(
    private jwtService: JwtService,
    private configService: ConfigService,
    private itemManager: ItemManager,
    private filtroLateralManager: FiltroLateralManager,
    private pesquisaService: PesquisaService
  ) {
    this.jwtService.checkLogin();
    this.listaIdsItens = new Array<number>();
    this.listaItens = new Array<ItemSimplesView>();
    this.listaMarcados = new Map<string, string[]>();
    this.pesquisaService.emitPesquisaRealizada(false);
  }

  ngOnInit() {
    this.pageSize = this.configService.getConfig().getSizeList;
    this.onPesquisar(this.idsPesquisados);
    if (screen.width < 850) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
    this.ordenacaoDefaultEmitter.emit(this.ordenacaoDefault)
    this.alturaTela = window.innerHeight - 140;
    document.documentElement.style.setProperty('--altura-tela', this.alturaTela.toLocaleString()+'px')
  }

  onPesquisar(ids: number[]) {
    this.getEstadosDaPesquisa();
    this.page = 1;
    this.filtroLateralAberto = false;
    this.filtroLateralManager.setIsFiltroAberto(this.filtroLateralAberto);
    this.notPesquisando = false;
    this.filtroLateralManager.iniciarMap(this.listaMarcados);
    if (ids.length <= 0) {
      this.getEstadosDaPesquisa();
      this.notPesquisando = true;
      this.pagination = false;
    }
    this.idsPesquisados = this.listaIdsOriginais = ids;
    this.inicioIndexPagina = 0;
    this.fimIndexPagina = this.pageSize;
    if (this.pesquisaService.getProdutosPesquisados() === undefined){
      this.filtroLateralManager.preencherListaDeFiltros(this.idsPesquisados).then(ids => {
        this.idsPesquisados = ids;
        this.preencheListaDeItens();
      });
    }else {
      this.filtroLateralManager.preencherListaDeFiltrosDetalhaCompleta(this.idsPesquisados).then(ids => {
        this.idsPesquisados = ids;
        this.preencheListaDeItens();
      });
    }    
  }

  aplicarRemoverFiltros(ids: number[]) {
    this.page = 1;
    this.idsPesquisados = ids;
    this.preencheListaDeItens();
  }

  private preencheListaDeItens() {
    this.getEstadosDaPesquisa();
    this.inicioIndexPagina = (this.page - 1) * this.pageSize;
    this.fimIndexPagina = this.inicioIndexPagina + this.pageSize;
    if (
      !this.idsPesquisados ||
      this.idsPesquisados.length <= this.inicioIndexPagina
    ) {
      return;
    }

    if (this.idsPesquisados.length > this.fimIndexPagina) {
      this.listaIdsItens = this.idsPesquisados.slice(
        this.inicioIndexPagina,
        this.fimIndexPagina
      );
    } else {
      this.fimIndexPagina = this.idsPesquisados.length;
      this.listaIdsItens = this.idsPesquisados.slice(
        this.inicioIndexPagina,
        this.fimIndexPagina
      );
    }

    this.notPesquisando = false;
    if (!this.notPesquisando) {
      this.itemManager.getListaDeItens(this.listaIdsItens, this.listaItens).then(lista => {
        this.listaItens = lista;
        this.notPesquisando = true;
        this.pagination = true;
        this.filtroLateralAberto = window.innerWidth < 1215 ? false : true;
        this.filtroLateralManager.setIsFiltroAberto(this.filtroLateralAberto);
        this.getEstadosDaPesquisa(this.listaIdsItens.length > 0);
        this.itemManager.promiseGetImage(this.listaItens, this.listaIdsItens);
        this.pesquisaService.emitPesquisaRealizada(true);
      });
    }
  }

  orderBy(order: string, list: ItemSimplesView[]) {
    this.listaItens = this.itemManager.orderBy(order, list);
  }

  orderByComListaItens(order: string){
    this.orderBy(order,this.listaItens);
  }

  get ordenacaoDefault(): string {
    return this.configService.getConfig().getOrdenacaoDefault;
  }

  setStatusFiltro($event) {
    this.filtroLateralAberto = $event;
    this.filtroLateralManager.setIsFiltroAberto(this.filtroLateralAberto);
  }

  getLayoutEmUso(): number {
    return  window.innerWidth <= 825? 2 : (this.configService.getConfig().getIsLayoutCards ? 2 : 1);
  }

  getEstadosDaPesquisa(status = false): IEstadosDaPesquisa {
    return this.estadosDaPesquisa = {
      estadoInicial: false,
      pesquisandoRegistros: !status,
      pesquisaPronta: status,
      registrosEncontrados: status
    }
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.alturaTela = event.target.innerHeight - 140;
    this.isSmallScreen = event.target.innerWidth < 1215;
    this.sideMenuMode = window.innerWidth < 1215 ? 'over' : 'side';
    this.filtroLateralAberto = window.innerWidth < 1215 ? false : this.filtroLateralAberto;
    this.filtroLateralManager.setIsFiltroAberto(this.filtroLateralAberto);
  }

  onEnd(event?: number) {
    this.notPesquisando = true;
    if (this.notPesquisando) {
      this.page = event;
      this.listaItens = new Array<ItemSimplesView>();
      this.filtroLateralManager.preencherListaDeFiltrosDetalhaCompleta(this.idsPesquisados);
      this.preencheListaDeItens();
      this.pesquisaService.emitPesquisaRealizada(false);
    }
  }
}
