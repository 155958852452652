import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appDelayedInput]'
})
export class DelayedInputDirective implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @Input() delayTime = 500;
  @Output() delayedInput = new EventEmitter<string>();

  constructor(private elementRef: ElementRef<HTMLInputElement>) {
  }

  ngOnInit() {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        map((e:Event) =>{
          const inputAtual = (e.target as HTMLInputElement).value;
          return inputAtual;
        }),
        distinctUntilChanged(),
        debounce((input: string) => timer(this.getDelayedTime(input))),
        takeUntil(this.destroy$),
      )
      .subscribe(e => this.delayedInput.emit(e));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getDelayedTime(input: string){
    if(input.startsWith('0')){
      return 2000
    } else{
      return this.delayTime;
    }
  }

}
