import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogCutomizadoComponent } from '../components/genericos/message-dialog-cutomizado/message-dialog-cutomizado.component';
import { IErrorMessage } from '../interfaces/ierror-message';
import { JwtService } from '../jwt.service';
import { PedidoManager } from 'src/app/managers/pedido-manager';

@Injectable({
  providedIn: 'root'
})

export class HttpErrorMessageService {

  iErrorMessage: IErrorMessage;
  isExecutou = false;
  constructor(
    private dialog: MatDialog,
    private jwtService: JwtService,
    private pedidoManager: PedidoManager
  ){}

  getErrorMessage(error: HttpErrorResponse): IErrorMessage {
    switch (error.status) {
      case 0:
        return this.iErrorMessage = { message: 'Não foi possível conectar ao servidor. Você será redirecionado para o login. Algumas informações podem ter sido perdidas!',
                                 redirect: true }
      case 400:
        if(error.error === "O pedido não está mais disponível!"){
          this.pedidoManager.pedidoIndisponivel();
          return this.iErrorMessage = { message: "Pedido já salvo no servidor, por favor verifique seus pedidos." }
        }
        return this.iErrorMessage = { message: 'Requisição inválida. Verifique os parâmetros enviados.' }
      case 401:
        return this.iErrorMessage = { message: 'Usuário não logado!',
                                      redirect: true }
      case 403:
        if(error.error === "Usuario não logado!"){
          return this.iErrorMessage = { message: 'Usuário não logado!', redirect: true }
        }else{
          return this.iErrorMessage = { message: 'Acesso negado. Você não tem permissão para acessar essa página.' }
        }
      case 404:
        return this.iErrorMessage = { message: 'Página não encontrada.'}
      case 408:
        return this.iErrorMessage = { message: 'Tempo limite da requisição excedido. Verifique sua conexão.' }
      case 429:
        return this.iErrorMessage = { message: 'Muitas requisições. Aguarde um momento e tente novamente.' }
      case 451:
        return this.iErrorMessage = { message: 'Acesso não disponível por motivos legais.' }
      default:
        return this.iErrorMessage = { message: 'Erro desconhecido. Você será redirecionado para o login. Algumas informações podem ter sido perdidas!',
                                      redirect: true }
    }
  }
 
  openErrorMessageDialog(error: HttpErrorResponse) {
    if (!this.isExecutou) {
      this.isExecutou = true;
      if(error.error === 'Erro ao Autenticar, verifique seu usuário/senha'){
        this.iErrorMessage = {message:'Erro ao Autenticar, verifique seu usuário/senha'}
      } else{
        this.iErrorMessage = this.getErrorMessage(error); 
      }
      const message = this.iErrorMessage.message;
      const redirect = this.iErrorMessage.redirect;
      const dialogRef = this.dialog.open(MessageDialogCutomizadoComponent, {
        width: '300px',
        disableClose: true,
        data: { message: message }
      });

      dialogRef.afterClosed().subscribe(() => {
        if(redirect)
          this.jwtService.logout();
        this.isExecutou = false;
      });
    }
  }  

}