<div class="container">
	<div class="item">
		<mat-icon matTooltip="COLOCAR EM ORDEM" [matMenuTriggerFor]="ordenacao">format_line_spacing</mat-icon>
		<mat-menu #ordenacao="matMenu">
			<button mat-menu-item class="menu-itens" (click)="orderBy('')"
				[ngClass]="(ordenacaoInput === '') ? 'destacaTexto':'normalTexto' ">Ordenação Padrão</button>
			<mat-divider></mat-divider>
			<button mat-menu-item class="menu-itens" (click)="orderBy('nome')"
				[ngClass]="(ordenacaoInput === 'nome') ? 'destacaTexto':'normalTexto' ">Nome do Item</button>
			<mat-divider></mat-divider>
			<button mat-menu-item class="menu-itens" (click)="orderBy('codigoItem')"
				[ngClass]="(ordenacaoInput === 'codigoItem') ? 'destacaTexto':'normalTexto' ">Código do Item</button>
			<mat-divider></mat-divider>
			<button mat-menu-item class="menu-itens" (click)="orderBy('preco')"
				[ngClass]="(ordenacaoInput === 'preco') ? 'destacaTexto':'normalTexto' ">Preço</button>
			<mat-divider></mat-divider>
			<button mat-menu-item class="menu-itens" (click)="orderBy('codigoFabricante')"
				[ngClass]="(ordenacaoInput === 'codigoFabricante') ? 'destacaTexto':'normalTexto' ">Código do
				Fabricante</button>
		</mat-menu>
	</div>
	<div class="item" *ngIf="!isLayoutMobile">
		<mat-icon (click)="listaOuCards(layoutPadrao)" [matTooltip]="toolTipLayout">{{getMatIcon()}}</mat-icon>
	</div>
	<div class="item">
		<mat-icon (click)="abrirFiltro(statusFiltro)" [matTooltip]="toolTipFiltro">tune</mat-icon>
	</div>
</div>