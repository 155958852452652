import { Injectable } from '@angular/core';
import { FormaCondPgto } from '../persistences/forma-cond-pgto';
import { ItemDetalhado } from '../persistences/item-detalhado';
import { PedidoVenda } from '../persistences/pedido-venda';
import { Carrinho } from '../persistences/carrinho';
import { LocalStorageSingletonService } from './local-storage-singleton.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartSingletonService {

  private carrinhoSubject = new BehaviorSubject<Carrinho>(this.localStorageSingletonService.buscarCarrinho());
  
  constructor(private localStorageSingletonService: LocalStorageSingletonService) {
    window.addEventListener('storage', this.syncCarrinhoChanges.bind(this));
  }

  private syncCarrinhoChanges(event: StorageEvent): void {
    if (event.key === 'carrinho') {
      /* Recurso Multi Abas */
      const carrinhoAtualizado = this.localStorageSingletonService.buscarCarrinho();
      if (JSON.stringify(carrinhoAtualizado) !== JSON.stringify(this.carrinhoSubject.getValue())) {
        this.carrinhoSubject.next(carrinhoAtualizado);
      }
    }
  }

  /*SEMPRE AO SUBSCREVER UM OBSERVABLE, LEMBRAR DE IMPLEMENTAR NGONDESTROY e Destruílo.
  Angular não faz isso no destroy do componente.
  Pesquisar referencias se necessário */
  getCarrinhoObservable(): Observable<Carrinho> {
    return this.carrinhoSubject.asObservable();
  }

  getCarrinhoEstatico(): Carrinho {
    return this.carrinhoSubject.getValue(); 
  }

  /*Somente o CartSingletonService pode atualizar o carrinho, nunca remover private */
  private setCarrinho(carrinho : Carrinho) {
    this.localStorageSingletonService.atualizarCarrinho(carrinho);
    this.carrinhoSubject.next(carrinho);
  }

  private recalcularTotal(itemPedido : ItemDetalhado[]): number  {
    let vlTotal = 0;    
    itemPedido.forEach((item) => {
      vlTotal += item.itemPedido.total;
    });
    return vlTotal;
  }

  getPedidoVenda(): PedidoVenda{
    const pedido : PedidoVenda = this.localStorageSingletonService.buscarPedido();
    if(pedido == null){
      //pega do servidor forçando ser sincrono //
      //set pro local stor
    }
    return pedido; 
  }

  /*Somente o CartSingletonService pode atualizar o pedido, nunca remover private */
  private setPedido(pedidoVenda : PedidoVenda) : PedidoVenda {
    this.localStorageSingletonService.atualizarPedido(pedidoVenda);
    return pedidoVenda;     
  }

  novoCarrinho(cdFormaPgto: number, cdCondPgto: number, cdTpEntrega: number, obs: string) {
    const carrinho = new Carrinho();
    const pedidoVenda = new PedidoVenda();
    carrinho._setCdFormaPgto(cdFormaPgto);
    carrinho._setCdCondPgto(cdCondPgto);
    carrinho._setCdTpEntrega(cdTpEntrega);
    carrinho._setObs(obs);
    this.setCarrinho(carrinho);
    this.setPedido(pedidoVenda);
  }

  atualizarPedido(pedidoVenda: PedidoVenda):void {
    this.setPedido(pedidoVenda);
  }

  atualizarDadosCabecalho(cdFormaPgto: number, cdCondPgto: number, cdTpEntrega: number, obs: string) {
    const carrinho : Carrinho = this.getCarrinhoEstatico();
    carrinho._setCdFormaPgto(cdFormaPgto);
    carrinho._setCdCondPgto(cdCondPgto);
    carrinho._setCdTpEntrega(cdTpEntrega);
    carrinho._setObs(obs);
    this.setCarrinho(carrinho);
  }

  atualizarNrParcelas(nrParcelas: number) {
    const carrinho : Carrinho = this.getCarrinhoEstatico();
    carrinho._setNrParcelas(nrParcelas);
    this.setCarrinho(carrinho);
  }

  atualizarFrete(frete: number) {
    const carrinho : Carrinho = this.getCarrinhoEstatico();
    carrinho._setFrete(frete);
    this.setCarrinho(carrinho);
  }

  atualizarControlePedido(idGeral: number, versaoRegistro: number): PedidoVenda {
    const pedido : PedidoVenda = this.getPedidoVenda();
    pedido._setIdGeral(idGeral);
    pedido._setVersaoRegistro(versaoRegistro);
    this.setPedido(pedido);
    return pedido;
  }


  setFormaECondicaoDePagamento(value: FormaCondPgto[]) {
    const carrinho = this.getCarrinhoEstatico();
    carrinho._setFormaCondPgto(value);
    const formaCondAtual = carrinho.getFormaCondPgto.find(item => item.flPadrao) == undefined ? value[0]: carrinho.getFormaCondPgto.find(item => item.flPadrao)
    carrinho._setCdFormaPgto(formaCondAtual.cdFormaPgto);
    carrinho._setClassificacao(formaCondAtual.classificacao.toUpperCase() === 'R');
    const condicoesPgto = formaCondAtual.condicoes;
    const condPgto = condicoesPgto.find(item => item.flPadrao) == undefined ? condicoesPgto[0] : condicoesPgto.find(item => item.flPadrao);
    carrinho._setNrParcelas(condPgto.nrParcelas); 
    carrinho._setCdCondPgto(condPgto.cdCondPgto);
    this.localStorageSingletonService.atualizarCarrinho(carrinho);    
  } 

  getFormaECondicaoDePagamento() {
    const carrinho : Carrinho = this.getCarrinhoEstatico();
    const formaECond = {
      cdFormaPgto: carrinho.getCdFormaPgto,
      classificacao: carrinho.getClassificacao,
      cdCondPgto: carrinho.getCdCondPgto,
      nrParcelas: carrinho.getNrParcelas
    }
    return formaECond;
  }   

  addItemCarrinho(itemAdd: ItemDetalhado) {
    const carrinho = this.getCarrinhoEstatico();
    const itemIndex = carrinho.getItensCarrinho.findIndex((item) => item.itemPedido.idItem === itemAdd.itemPedido.idItem);
    if(itemIndex !== -1 && itemAdd.itemPedido.quantidade > 0){
      carrinho.getItensCarrinho[itemIndex] = itemAdd;
    } else {
      carrinho.getItensCarrinho.push(itemAdd);
    }    
    carrinho._setTotal(this.recalcularTotal(carrinho.getItensCarrinho));
    this.setCarrinho(carrinho);
  }
  
  removerItemCarrinho(itemAdd: ItemDetalhado): boolean {
    const carrinho = this.getCarrinhoEstatico();
    let removerItemBanco = false;
    const itemIndex = carrinho.getItensCarrinho.findIndex((item) => item.itemPedido.idItem === itemAdd.itemPedido.idItem);
    if(itemIndex !== -1 && itemAdd.itemPedido.quantidade > 0){
      carrinho.getItensCarrinho[itemIndex] = itemAdd;
    } else {
      carrinho.getItensCarrinho.splice(itemIndex, 1);
      removerItemBanco = true;
    }        
    carrinho._setTotal(this.recalcularTotal(carrinho.getItensCarrinho));
    this.setCarrinho(carrinho);
    return removerItemBanco;
  }  

  itemEstaNoCarrinho(itemAdd: ItemDetalhado): boolean {
    const carrinho = this.getCarrinhoEstatico();
    return carrinho.getItensCarrinho.some(
      (itemCarrinho) =>
        itemAdd.itemPedido.idItem === itemCarrinho.itemPedido.idItem
    );
  }

  getQtdItensNoCarrinho(): number{  
    const carrinho = this.getCarrinhoEstatico();
    if (carrinho == null) {
      return 0;
    }
    return carrinho.getItensCarrinho.length;
  }

}
