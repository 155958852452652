import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.prod';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.css']
})
export class RodapeComponent implements OnInit {
  versao: string;

  ngOnInit(): void {
    this.getVersion();    
  }
  
  getVersion() {
    this.versao = "Versão: " + environment.version;
  }

}
