<div class="gridList" (window:resize)="onResize($event)">
	<div class="listaItens"
		*ngFor="let item of listaItens | paginate: {itemsPerPage: pagesize, currentPage: page, totalItems: ids.length}">
		<app-item-pesquisa class="itemPesquisa" [itemPesquisa]="item"
			[temSimilares]="getSimilares(item.itemDetalhado)" [quantidadeItem]="getQuantidade(item.idItem)"
			[valorTotalItem]="getTotal(item.idItem)" [valorLiquido]="item.vl_unitario_liquido"
			[item]="getItemDetalhado(item)" [fichaTecnica]="getFichaTecnica(item.itemDetalhado)"
			[temEstoque]="getQtAtual(item.itemDetalhado)" [itemPedido]="getItemDetalhado(item).itemPedido"
			[exibeDesconto]="getExibeDesconto()" [exibeEstoque]="getExibeEstoque()">
		</app-item-pesquisa>
	</div>
</div>