import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JwtService } from 'src/app/jwt.service';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ProdutoPesquisado } from 'src/app/persistences/produto-pesquisado';
import { PesquisaService } from 'src/app/services/pesquisa.service';
import { FiltroLateralManager } from '../../managers/filtro-manager';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-filtro-lateral',
  templateUrl: './filtro-lateral.component.html',
  styleUrls: ['./filtro-lateral.component.css']
})
export class FiltroLateralComponent implements OnInit {

  private listaProdutosPesquisados: ProdutoPesquisado[];
  @Input()
  public listaMarcados: Map<string, string[]>;
  @Input()
  public listaMarcas: string[];
  @Input()
  public listaGrupos: string[];
  @Input()
  public listaSubGrupos: string[];
  @Input()
  public listaLinhas: string[];
  @Input()
  public cadastrosRecentes: string[];
  
  @Input()
  listaIdsOriginais: number[];
  @Output()
  idsFiltradosEmitter = new EventEmitter<number[]>();

  constructor(
    private pesquisaService: PesquisaService,
    private jwtService: JwtService,    
    private snackBarManager: SnackbarManager,
    private filtroLateralManager: FiltroLateralManager,
    public configService: ConfigService
  ) {    
    this.jwtService.checkLogin();    
  }

  ngOnInit() {
    this.filtrosRecebidos();     
  }

  limparListaDeFiltros() {
    this.listaMarcas = new Array<string>();
    this.listaLinhas = new Array<string>();
    this.listaSubGrupos = new Array<string>();
    this.listaGrupos = new Array<string>();
    this.cadastrosRecentes = new Array<string>();
  }  

  filtrosRecebidos() {
    this.limparListaDeFiltros();
    this.listaProdutosPesquisados =
      this.pesquisaService.getProdutosPesquisados();
    this.listaProdutosPesquisados.forEach((produto) => {
      if (!this.listaMarcas.includes(produto.nm_marca)) {
        this.listaMarcas.push(produto.nm_marca);
      }

      if (!this.listaLinhas.includes(produto.linha)) {
        this.listaLinhas.push(produto.linha);
      }

      if (!this.listaSubGrupos.includes(produto.subgrupo)) {
        this.listaSubGrupos.push(produto.subgrupo);
      }

      if (!this.listaGrupos.includes(produto.grupo)) {
        this.listaGrupos.push(produto.grupo);
      }
    });
    this.cadastrosRecentes.push('cadastrosRecentes');
    this.ordenarFiltro();
  }  

  ordenarFiltro() {
    this.listaMarcas.sort((marca1, marca2) => (marca1 > marca2 ? 1 : -1));
    this.listaLinhas.sort((linha1, linha2) => (linha1 > linha2 ? 1 : -1));
    this.listaGrupos.sort((grupo1, grupo2) => (grupo1 > grupo2 ? 1 : -1));
    this.listaSubGrupos.sort((subgrupo1, subgrupo2) =>
      subgrupo1 > subgrupo2 ? 1 : -1
    );
  }

  adicionaMarcado(value: string, categoria: string, evento: boolean) {
    if (this.listaMarcados.size <= 0) {
      this.listaMarcados = this.filtroLateralManager.iniciarMap(this.listaMarcados);
    }
    if (evento) {
      this.listaMarcados.get(categoria).push(value);
    } else {
      this.listaMarcados.set(
        categoria,
        this.listaMarcados
          .get(categoria)
          .filter((name) => name != value)
      );
    }
  }

  selecionado(valor: string, categoria: string): boolean {
    return this.listaMarcados.get(categoria).indexOf(valor) !== -1;
  }

  filtraItens() {
    if (
      this.listaMarcados.get('marca').length !== 0 ||
      this.listaMarcados.get('grupo').length !== 0 ||
      this.listaMarcados.get('subgrupo').length !== 0 ||
      this.listaMarcados.get('linha').length !== 0 ||
      this.listaMarcados.get('cadastrosRecentes').length !== 0
    ) {
      const lista = this.listaProdutosPesquisados
        .filter(
          (item) =>
            this.listaMarcados.get('grupo').length == 0 ||
            this.listaMarcados.get('grupo').indexOf(item.grupo) !=
            -1
        )
        .filter(
          (item) =>
            this.listaMarcados.get('linha').length === 0 ||
            this.listaMarcados.get('linha').indexOf(item.linha) !=
            -1
        )
        .filter(
          (item) =>
            this.listaMarcados.get('marca').length === 0 ||
            this.listaMarcados
              .get('marca')
              .indexOf(item.nm_marca) !== -1
        )
        .filter(
          (item) =>
            this.listaMarcados.get('subgrupo').length === 0 ||
            this.listaMarcados
              .get('subgrupo')
              .indexOf(item.subgrupo) !== -1
        )
        .filter(
          (item) => {
            const dias = this.configService.getConfig().getIdentificarItensNovos;
            if (dias !== '' && this.listaMarcados.get('cadastrosRecentes').length !== 0) {
              const dataAtual = new Date();
              const dataCadastro = new Date(item.dtCadastro);

              const diferencaMiliSeg = Math.abs(dataAtual.getTime() - dataCadastro.getTime());
              const diferencaDias = Math.ceil(diferencaMiliSeg / (1000 * 60 * 60 * 24));
              
              return diferencaDias < parseInt(dias);
            }
              return true;
          }
        );

      const produtosIds: number[] = lista.map((item) => item.id_item);
      if (produtosIds.length <= 0) {
        this.idsFiltradosEmitter.emit(this.listaIdsOriginais);
        this.snackBarManager.openSnackBar(
          'Nenhum Item foi Encontrado',
          'Ok',
          3000
        );
        this.limparMarcados();
      } else {
        this.idsFiltradosEmitter.emit(produtosIds);
      }
      this.gravarFiltrosLogPesquisa();
    } else {
      this.snackBarManager.openSnackBar(
        'Selecione ao menos um campo para filtrar',
        'Ok',
        3000
      );
    }
  }

  limparFiltro() {
    this.idsFiltradosEmitter.emit(this.listaIdsOriginais);
    this.filtrosRecebidos();
    this.listaMarcados = this.filtroLateralManager.iniciarMap(this.listaMarcados);
  }

  limparMarcados() {
    this.listaMarcados.set('marca', []);
    this.listaMarcados.set('grupo', []);
    this.listaMarcados.set('subgrupo', []);
    this.listaMarcados.set('linha', []);
    this.listaMarcados.set('cadastrosRecentes', []);
  }

  gravarFiltrosLogPesquisa() {
    let filtrosEscolhidos: string[] = [];

    this.listaMarcados.forEach((value) => {
      filtrosEscolhidos = filtrosEscolhidos.concat(value);
    });

    const filtros = filtrosEscolhidos.join(',');
    
    this.pesquisaService.enviaFiltrosLogPesquisa(filtros).subscribe();

  }
}
