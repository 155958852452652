import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(itens: any[], termoPesquisado: any, nomeDaColuna: string): any[] {
    if(!itens)  return [];
    if(!termoPesquisado)  return itens;

    termoPesquisado = termoPesquisado.toLocaleLowerCase();

    return itens.filter(item => {      
        return item[nomeDaColuna].toString().toLowerCase().includes(termoPesquisado);      
    });
  }

}
