import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemService } from 'src/app/services/item.service';
import { ItemSimilar } from 'src/app/persistences/item-similar';
import { flatMap } from 'rxjs/operators';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';
import { NumberUtils } from 'src/app/utils/utils/number-utils';
import { JwtService } from 'src/app/jwt.service';
import { CurrencyPipe } from '@angular/common';
import { SnackbarManager } from './../../managers/snackbar-manager';
import { Cliente } from 'src/app/persistences/cliente';

@Component({
	selector: 'app-lista-similares',
	templateUrl: './lista-similares.component.html',
	styleUrls: ['./lista-similares.component.css']
})
export class ListaSimilaresComponent implements OnInit {
	public imageObject: Array<object> = [];
	public imagensCarregadas = false;
	public similares: ItemSimilar[] = [];
	public listaSimilares = false;
	public colunas: number;
	public promocao: string;

	@Input()
	public cdItemAtual: string;
	public cdItemCar: string;

	@Output()
	selecionado = new EventEmitter<number>();

	constructor(
		private itemService: ItemService,
		private clienteSingleton: ClienteSingletonService,
		private jwtService: JwtService,
		private currencyPipe: CurrencyPipe,
		private snackBarManager: SnackbarManager
	) {

	}

	ngOnInit(): void {
		this.colunas = (window.innerWidth <= 700) ? 1 : 4;
	}

	onResize(event) {
		const width = event.target.innerWidth;
		if (width >= 1080) {
			this.colunas = 4;
		}
		if (width < 1080 && width > 800) {
			this.colunas = 3;
		}
		if (width < 800 && width > 700) {
			this.colunas = 2;
		}
		if (width < 700) {
			this.colunas = 1;
		}
	}


	carregaSimilares(id_item: number) {
		this.imagensCarregadas = false;
		this.similares = [];

		this.itemService.getSimilares(id_item).pipe(
			flatMap(
				(res: any[]) => {
					if (res != undefined) {
						res["itens"].forEach((item) => {
							const a = new ItemSimilar(
								undefined,
								item["idItem"],
								item["nmItem"],
								item["qtAtual"] > 0 ? item["cdItem"] : 'INDISPONÍVEL - '+item["cdItem"],
								item["qtAtual"],
								item["qtDisponivel"],
								item["estoqueDisponivel"],
								item["preco"],
								item["flPromocao"],
								item["pcMaxDescontoAut"],
								item["ipi"],
								item["st"],
								item["ipiSt"]);
							this.similares.push(a)
						})
					}

					return this.similares;
				}
			)
		)
			.subscribe(
				(similar: ItemSimilar) => {
					this.itemService.getImagem(similar.idItem, true)
						.subscribe(
							(imagem: string,) => {
								if (imagem == "") {
									similar.imagem = "assets/images/sem_imagem.png";
								}
								else {
									similar.imagem = "data:image/jpeg;base64," + imagem;
								}
								if (similar.flPromocao == false) {
									this.promocao = " "

								} else if (similar.flPromocao == true) {
									this.promocao = '- PROMOÇÃO'
								}

								const cliente: Cliente = this.clienteSingleton.getClienteEstatico();
								let valor = NumberUtils.roundTo(NumberUtils.roundTo(similar.preco,2) * (1 + cliente.getPcMarkup / 100), 2);

								valor = NumberUtils.roundTo(valor * (1 - similar.pcMaxDescontoAut / 100), 2);


								valor = NumberUtils.roundTo(valor
												+ NumberUtils.roundTo( similar.ipiSt
																		* (1 + cliente.getPcMarkup / 100)
																		* (1 - similar.pcMaxDescontoAut / 100), 2), 2);
								const valorString = this.currencyPipe.transform(valor,"R$")
								const title = similar.cdItem + " - " + similar.nmItem + " - " + valorString + this.promocao;
								similar.indice = this.imageObject.length;
								this.imageObject.push(
									{
										thumbImage: similar.imagem,
										title: title,
									}
								)
							},
							(error) => {
								this.jwtService.validarAcessToken(error);
								this.snackBarManager.openSnackBar(error.error,"Ok",3000);
							}
						);
				},
				(error) => {
					this.jwtService.validarAcessToken(error);
					this.snackBarManager.openSnackBar(error.error,"Ok",3000);
				}
			);
		this.imagensCarregadas = true;

	}


	onClickImagem(ind: number) {
		this.similares.forEach((item) => {
			if (item.indice == ind) {
				this.cdItemAtual = item.cdItem;
				this.selecionado.emit(item.idItem);
			}
		});

		this.setDestaque();
	}

	setDestaque() {
		const destaque: any = document.getElementsByClassName('caption');
		for (let i = 0; i < destaque.length; i++) {
			if (destaque[i].innerHTML.split(' - ')[0] == this.cdItemAtual) {
				destaque[i].style.background = 'black';
			} else {
				destaque[i].style.background = ' linear-gradient(to right, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6), rgba(0, 0, 0, .6), rgba(0, 0, 0, .6), rgba(0, 0, 0, .6))';
			}
		}
	}
}
