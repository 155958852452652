import { ItemDetalhado } from './item-detalhado';
import { FormaCondPgto } from './forma-cond-pgto';

export class Carrinho {

    private itensCarrinho: ItemDetalhado[];
    private total = 0;
    private frete = 0;
    private tpFrete = '9';
    private cdSituacao: number;
    private cdFormaPgto: number;
    private cdCondPgto: number;
    private classificacao = false;
    private nrParcelas: number;
    private cdTpEntrega: number;
    private cdTransportador = 0;
    private obs: string;    
    private dtPrevisaoEnvio: string = new Date().toLocaleDateString('pt-BR');
    private formaCondPgto: FormaCondPgto[];
    
    constructor(json?: any) {        
        this.formaCondPgto = new Array<FormaCondPgto>();
        this.itensCarrinho = new Array<ItemDetalhado>();
        if (json) {            
            // Inicialização a partir do objeto JSON
            this.itensCarrinho = json.itensCarrinho ? json.itensCarrinho.map((item: any) => new ItemDetalhado(item)) : new Array<ItemDetalhado>();
            this.total = json.total || 0;
            this.frete = json.frete || 0;
            this.tpFrete = json.tpFrete || '9';
            this.cdSituacao = json.cdSituacao;
            this.cdFormaPgto = json.cdFormaPgto;
            this.cdCondPgto = json.cdCondPgto;
            this.classificacao = json.classificacao || false;
            this.nrParcelas = json.nrParcelas;
            this.cdTpEntrega = json.cdTpEntrega;
            this.cdTransportador = json.cdTransportador || 0;
            this.obs = json.obs || '';
            this.dtPrevisaoEnvio = json.dtPrevisaoEnvio || new Date().toLocaleDateString('pt-BR');
            this.formaCondPgto = json.formaCondPgto ? json.formaCondPgto.map((forma: any) => new FormaCondPgto(forma)) : new Array<FormaCondPgto>();
        } 
    }


    /*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO CartSingletonService*/
    _setItensCarrinho(valor : ItemDetalhado[]) {
        this.itensCarrinho = valor;
    }

    get getItensCarrinho():ItemDetalhado[] {
        return this.itensCarrinho;
    }

    _setTotal(valor : number) {
        this.total = valor;
    }

    get getTotal():number {
        return this.total;
    }

    _setFrete(valor : number) {
        this.frete = valor;
    }

    get getFrete():number {
        return this.frete;
    }

    _setTpFrete(valor : string) {
        this.tpFrete = valor;
    }

    get getTpFrete():string {
        return this.tpFrete;
    }

    _setCdSituacao(valor : number) {
        this.cdSituacao = valor;
    }

    get getCdSituacao():number {
        return this.cdSituacao;
    }

    _setCdCondPgto(valor : number) {
        this.cdCondPgto = valor;
    }

    get getCdCondPgto():number {
        return this.cdCondPgto;
    }

    _setClassificacao(valor : boolean) {
        this.classificacao = valor;
    }

    get getClassificacao():boolean {
        return this.classificacao;
    }

    _setNrParcelas(valor : number) {
        this.nrParcelas = valor;
    }

    get getNrParcelas():number {
        return this.nrParcelas;
    }

    _setCdTpEntrega(valor : number) {
        this.cdTpEntrega = valor;
    }

    get getCdTpEntrega():number {
        return this.cdTpEntrega;
    }

    _setCdTransportador(valor : number) {
        this.cdTransportador = valor;
    }

    get getCdTransportador():number {
        return this.cdTransportador;
    }

    _setObs(valor : string) {
        this.obs = valor;
    }

    get getObs():string {
        return this.obs;
    }

    _setCdFormaPgto(valor : number) {
        this.cdFormaPgto = valor;
    }

    get getCdFormaPgto():number {
        return this.cdFormaPgto;
    }

    _setDtPrevisaoEnvio(valor : string) {
        this.dtPrevisaoEnvio = valor;
    }

    get getDtPrevisaoEnvio():string {
        return this.dtPrevisaoEnvio;
    }

    _setFormaCondPgto(valor : Array<FormaCondPgto>) {
        this.formaCondPgto = valor;
    }

    get getFormaCondPgto():Array<FormaCondPgto> {
        return this.formaCondPgto;
    }

}
