import { ItemPedido } from './item-pedido';
import { ItemDetalhes } from './item-detalhes';

export class ItemDetalhado {
	public itemDetalhes: ItemDetalhes;

	public itemPedido: ItemPedido;

    public itemNovo: boolean;

	constructor(json?: any) {
        if (json) {
            this.itemDetalhes = new ItemDetalhes(json.itemDetalhes);
            this.itemPedido = new ItemPedido(json.itemPedido);
        } else {
            // Inicialize com valores padrão ou vazios conforme necessário
            this.itemDetalhes = new ItemDetalhes();
            this.itemPedido = new ItemPedido();
        }
    }


}
