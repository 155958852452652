<div class="gridContainer"  color="primary" >
    <div class="item item-1 min-width-45">
        <mat-icon (click)="openDialogDetalhes()" *ngIf="temSimilares" 
                  matTooltip="PRODUTO POSSUÍ SIMILARES" matTooltipPosition="below">filter_none
        </mat-icon>
    </div>
    <div class="item-2" >
        
        <div class="flex-column">
            <div>
                <img (click)="imgClick()" *ngIf="(itemPesquisa.imagem !== undefined && itemPesquisa.imagem !== '')"
                      src="{{itemPesquisa.imagem}}"
                      class="max-width-150">
            </div>
            <div>
                <img (click)="imgClick()" *ngIf="(itemPesquisa.imagem === '')"
                     src="assets/images/no_image.png"
                     class="max-width-76">
            </div>
            <div *ngIf="itemPesquisa.flPromocao" class="promocao flex-row">
                <label color="warn">
                    PROMOÇÃO
                </label>
            </div>
        </div>
        
    </div>
    <div class="item item-3">

        <div style="margin-bottom: 5px;">
            {{itemPesquisa.nmItem}}
        </div>
        <div>
            <b>{{itemPesquisa.cdItem}}</b>
        </div>
    </div>
    <div class="item item-4">
        <div style="margin-bottom: 5px;">
            Cd.Fabricante: {{itemPesquisa.cdFabricante}}"
        </div>

        <div>
            Marca: {{itemPesquisa.nmMarca}}
        </div>
    </div>
    <div class="item item-5" >
        <div  >
            <div color="primary" style="padding-bottom: 10px;">
                <app-quantidade-lista [item]="item"
                                      [quantidadeItemString]="quantidadeItem.toLocaleString('pt-BR',{useGrouping: false})"
                                      [temEstoque]="!item.itemDetalhes.estoqueDisponivel"
                                      [preco]="itemPesquisa.preco"
                                      [unMedVenda]="itemPesquisa.itemDetalhado.itemPedido.unMedVenda"
                                      >
                </app-quantidade-lista>
            </div>
        </div>
    </div>
    <div class="item item-6">
        <div>
          <p *ngIf="quantidadeItem >= 0 && temEstoque && !(itemPesquisa.preco <= 0.01) ">Valor:</p>
          <p class="precoDesconto precoBold">{{itemPesquisa.itemDetalhado.itemPedido.valorLiquidoIpiSt.toFixed(2) | currency:'BRL'}}</p>          
          ({{itemPesquisa.itemDetalhado.itemPedido.unMedVenda}})
        </div>

        <div *ngIf="itemPesquisa.pcDesconto > 0 && exibeDesconto">
            <p><b style="color: red">Desconto: {{itemPesquisa.pcDesconto.toFixed(2) | currency:'BRL'}} %</b>
            </p>
        </div>
        <div >
            <div>
                <p *ngIf="quantidadeItem>0"><b><span matPrefix>Total: &nbsp;</span>{{(itemPesquisa.itemDetalhado.itemPedido.valorLiquidoIpiSt * quantidadeItem).toFixed(2) | currency:'BRL'}}</b>
                </p>
                <p *ngIf="!temEstoque && exibeEstoque" style="color: red;"><b><span matPrefix>&nbsp;</span>Sem
                    estoque</b></p>

            </div>
        </div>
    </div>
    <div class="item item-7">
        <button (click)="openDialogDetalhes()" matTooltip="Item detalhado" color="primary" mat-mini-fab>
            <mat-icon>touch_app</mat-icon>
        </button>
        <br><br>
        <button (click)="listarPrecosDoItem()" color="primary" mat-mini-fab [hidden]="outrosPrecosDisponivel()">
            <mat-icon>currency_exchange</mat-icon>
        </button>
        <br><br>           
        <div>
            <button [matMenuTriggerFor]="ficha" matTooltip="Ficha técnica" color="primary"
                mat-mini-fab>
                <mat-icon>assignment</mat-icon>
            </button>
            <mat-menu #ficha="matMenu">
                <div [innerHTML]="fichaTecnica" (click)="$event.stopPropagation()" class="fichaDescricao">
                </div>
            </mat-menu>
        </div>
    </div>
</div>
<br>