import { Component, Input, OnInit } from '@angular/core';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemSimplesView } from 'src/app/persistences/item-simples-view';
import { ConfigService } from 'src/app/services/config.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { FiltroLateralManager } from 'src/app/managers/filtro-manager';

@Component({
  selector: 'app-card-lista-itens',
  templateUrl: './card-lista-itens.component.html',
  styleUrls: ['./card-lista-itens.component.css']
})
export class CardListaItensComponent implements OnInit {
  @Input()
  public listaItens: ItemSimplesView[];
  @Input()
  public lateral: boolean;
  @Input()
  public ids: Array<number>;
  @Input()
  public page: number;
  @Input()
  public pagesize: number;
  public carregaItem: HomeComponent;
  colunas: number;
  public grade = true;
  isFiltroLateralAberto: boolean;

  constructor(
		private cartSingletonService: CartSingletonService,
    private configService: ConfigService,
    private filtroLateralManager: FiltroLateralManager
  ) { }

  ngOnInit(): void {
    this.isFiltroLateralAberto = this.filtroLateralManager.getIsFiltroAberto();
    this.colunas = this.onResize(null, window.innerWidth);
    this.filtroLateralManager.isFiltroAbertoEmitter.subscribe((res:any) => {
      this.isFiltroLateralAberto = res;
      this.onResize(null, window.innerWidth);
  })
  }

  getItemDetalhado(item: ItemSimplesView): ItemDetalhado {
    return item.itemDetalhado == undefined ? new ItemDetalhado() : item.itemDetalhado;
  }

  getQtAtual(item: ItemDetalhado): boolean{
    return  item.itemDetalhes == undefined ?  false:item.itemDetalhes.qtAtual > 0;
   }

   getSimilares(item: ItemDetalhado): boolean {
     return item.itemDetalhes == undefined ? false:item.itemDetalhes.temSimilares == true;
   }

   getFichaTecnica(item: ItemDetalhado): string{
     return item.itemDetalhes == undefined ? "":item.itemDetalhes.fichaTecnica;
   }

   getExibeEstoque(): boolean{
     return this.configService.getConfig().getExibeEstoque;
   }

   getExibeDesconto(): boolean{
     return this.configService.getConfig().getExibeDesconto;
   }

  getTotal(id: number):number{
    const carrinho = this.cartSingletonService.getCarrinhoEstatico();
    const item: ItemDetalhado = carrinho.getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem ==id);
    if(carrinho.getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem ==id) != undefined){
      return item.itemPedido.total;
    }
    return 0;
  }

  getQuantidade(id: number): number{
    const item: ItemDetalhado = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem ==id);
    if(item != undefined){
      return item.itemPedido.quantidade;
    }
    return 0;

  }

  itemNoCarrinho(item: ItemDetalhado): boolean {
    let carrinho = false;
    this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.forEach(itemCarrinho => {
      if (itemCarrinho.itemDetalhes.idItem == item.itemDetalhes.idItem) {
          itemCarrinho = item;
          carrinho = true;
      }
    });
    return carrinho;
  }

  atualizaLista(item: ItemDetalhado) {
    this.listaItens.forEach(itemSimplesView => {
      if (itemSimplesView.itemDetalhado.itemDetalhes.idItem == item.itemDetalhes.idItem) {
        itemSimplesView.itemDetalhado = item;
      }
    });
  }

  temTotal(item: ItemDetalhado): boolean {
    const bool = true;
    if (item.itemDetalhes.qtDisponivel <= 0 || item.itemPedido.quantidade <= 0) {
      return !bool;
    }
    return bool;
  }

  onResize(event, col:number):number {
    const width = event == null ? col:event.target.innerWidth;
    if (!this.grade) {
      this.colunas = 1;
    } else {
      if (width >= 2800) {
        this.isFiltroLateralAberto? this.colunas = 8 : this.colunas = 9;
      }
      else if (width <= 2800 && width > 2500) {
        this.isFiltroLateralAberto? this.colunas = 7 : this.colunas = 8;

      }
      else if (width <= 2500 && width > 2200) {
        this.isFiltroLateralAberto? this.colunas = 6 : this.colunas = 7;

      }
      else if (width <= 2200 && width > 1900) {
        this.isFiltroLateralAberto? this.colunas = 5 : this.colunas = 6;

      }
      else if (width <= 1900 && width > 1500) {
        this.isFiltroLateralAberto? this.colunas = 4 : this.colunas = 5;
      }
      else if (width <= 1500 && width > 1215) {
        this.isFiltroLateralAberto? this.colunas = 3 : this.colunas = 4;

      }
      else if (width <= 1215 && width > 770) {
        this.colunas = 3;
      }
      else if (width <= 770 && width > 570) {
        this.colunas = 2;
      }
      else if (width <= 570) {
        this.colunas = 1;
      }
    }
    return this.colunas;
  }

  get ordenacaoDefault(): string{
    return this.configService.getConfig().getOrdenacaoDefault;
  }
}
