import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PcMarkupEcommerce } from '../persistences/pc-markup-ecommerce';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoContaService {

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
  ) { }

  pcMarkup(markup: PcMarkupEcommerce) {
    return this.httpClient.put(
      this.configService.getEndereco() + '/Business/putConfiguracaoConta',
      JSON.stringify(markup),
      {
        headers: this.configService.getHeaders()
      },
    );
  }
}
