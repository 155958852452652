import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtService } from '../jwt.service';
import { Painel } from './../persistences/painel';
import { ConfigService } from './config.service';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';

@Injectable({
  providedIn: 'root'
})
export class PainelService {

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private jwtService: JwtService,
    private clienteSingletonService: ClienteSingletonService
  ) { }

  getAtivosPorFilial(): Observable<Painel[]> {
    this.jwtService.validarAcessToken();
    let httpParams = new HttpParams();
    httpParams = httpParams.append('cdFilial', this.clienteSingletonService.getClienteEstatico().getClienteFilial.getCdFilial);
    return this.httpClient.get<Painel[]>(
      this.configService.getEndereco() + '/painel/getAtivosPorFilial',
      {
        headers: this.configService.getHeaders(),
        params: httpParams
      }
    )
  }

  getAll(): Observable<Painel[]> {
    this.jwtService.validarAcessToken();
    return this.httpClient.get<Painel[]>(
      this.configService.getEndereco() + '/painel/getAll',
      {
        headers: this.configService.getHeaders()
      }
    )
  }

  gravarPainel(painel: Painel) {
    this.jwtService.validarAcessToken();
    return this.httpClient.post(
      this.configService.getEndereco() + '/painel/gravar',
      JSON.stringify(painel),
      { headers: this.configService.getHeaders() }
    )
  }

  alterarPainel(painel: Painel) {
    this.jwtService.validarAcessToken();
    return this.httpClient.put(
      this.configService.getEndereco() + '/painel/alterar',
      JSON.stringify(painel),
      { headers: this.configService.getHeaders() }
    )
  }

  deletarPainel(id: number) {
    this.jwtService.validarAcessToken();
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id.toString());
    return this.httpClient.delete(
      this.configService.getEndereco() + '/painel/deletar',
      {
        headers: this.configService.getHeaders(),
        params: httpParams
      }
    )
  }
}
