import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtService } from 'src/app/jwt.service';
import { ClienteManager } from 'src/app/managers/cliente-manager';
import { ConverterCarrinhoManager } from 'src/app/managers/converter-carrinho.manager';
import { ItemManager } from 'src/app/managers/item-manager';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemPedido } from 'src/app/persistences/item-pedido';
import { Preco } from 'src/app/persistences/preco';
import { ConfigService } from 'src/app/services/config.service';
import { PedidoService } from 'src/app/services/pedido.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { PedidoManager } from 'src/app/managers/pedido-manager';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';

@Component({
  selector: 'app-lanca-item-cabecalho',
  templateUrl: './lanca-item-cabecalho.component.html',
  styleUrls: ['./lanca-item-cabecalho.component.css']
})
export class LancaItemCabecalhoComponent implements OnInit {
  public exibeEstoque: boolean;
  private subscription: Subscription;
  @Input()
  public itemDetalhado: ItemDetalhado;
  @Output()
  public addItem = new EventEmitter<ItemDetalhado>();
  @Output()
  public addItemCarrinho = new EventEmitter<ItemDetalhado>();

  constructor(
    private cartSingletonService: CartSingletonService,
    public itemManager: ItemManager,
    private configService: ConfigService,
    public dialog: MatDialog,
    private pedidoService: PedidoService,
    private snackBarManager: SnackbarManager,
    private jwtService: JwtService,
    private clienteManager: ClienteManager,
    private converterCarrinhoManager: ConverterCarrinhoManager,
    private pedidoManager: PedidoManager,
    private clienteSingletonService: ClienteSingletonService
  ) {
  }

  ngOnInit(): void {
    this.configuraItem();
    this.exibeEstoque = this.configService.getConfig().getExibeEstoque;
  }

  ngOnDestroy() {
		this.subscription.unsubscribe();
	}

  receberQuantidadeInput(quantidade: number) {
    this.itemDetalhado.itemNovo = (this.itemDetalhado.itemPedido.quantidade === null) 
                                  || (this.itemDetalhado.itemPedido.quantidade === undefined) 
                                  || (this.itemDetalhado.itemPedido.quantidade <= 0);
    this.itemDetalhado.itemPedido.quantidade = quantidade;
    this.pedidoManager.gravarItemCarrinhoPedido(this.itemDetalhado).then()
    .catch((error: HttpErrorResponse) => {
      this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
    });
  }

  configuraItem() {
    this.subscription = this.cartSingletonService.getCarrinhoObservable().subscribe(carrinho => {
      const item = carrinho.getItensCarrinho.find(item => {
        return this.itemDetalhado.itemPedido.idItem === item.itemPedido.idItem
      });
      if(item == undefined){
        this.itemDetalhado.itemPedido.quantidade = 0;
      }
      this.itemDetalhado.itemPedido = item == undefined ? this.itemDetalhado.itemPedido : item.itemPedido;
		});
  }

  temEstoque(): boolean {
    const bool = true;
    if (this.itemDetalhado.itemDetalhes.qtDisponivel <= 0 || this.itemDetalhado.itemPedido.quantidade <= 0) {
      return !bool;
    }
    return bool;
  }

  adicionarItemAoCarrinho() {
    if (this.clienteManager.validarClienteVendedor()) {
      this.itemDetalhado.itemPedido.quantidade = this.itemManager.getMinimo(this.itemDetalhado).minimoControle;
      if (this.temEstoque()) {
        this.itemDetalhado.itemNovo = (this.itemDetalhado.itemPedido.quantidade === null) 
                                      || (this.itemDetalhado.itemPedido.quantidade === undefined) 
                                      || (this.itemDetalhado.itemPedido.quantidade <= 0);
        this.pedidoManager.gravarItemCarrinhoPedido(this.itemDetalhado);
        this.snackBarManager.openSnackBar("Item adicionado no carrinho.", "Ok", 2000);        
      } else {
        this.itemDetalhado.itemPedido.quantidade <= 0 ? this.snackBarManager.openSnackBar("QUANTIDADE (UN) TEM QUE SER MAIOR QUE 0", 'OK', 2000) :
          this.snackBarManager.openSnackBar("NÃO HÁ ESTOQUE PARA ESTE PRODUTO", 'OK', 2000)
      }
    }
  }

  getTabelaPrecoEmitter(tabelaPreco: Preco) {
    if (tabelaPreco !== null) {
      this.atualizarPrecoItem(this.itemManager.selecionarTabelaDePrecoDoItem(tabelaPreco, this.itemDetalhado));
      this.itemManager.atualizarTabelaDePrecoDoItem(this.itemDetalhado);
    }
  }

  atualizarPrecoItem(itemPedido: ItemPedido) {
    let total = this.itemDetalhado.itemPedido.valorLiquidoIpiSt * this.itemDetalhado.itemPedido.quantidade;
    total = total + (total * this.clienteSingletonService.getClienteEstatico().getPcMarkup)/100;
    this.itemDetalhado.itemPedido.unMedVenda = itemPedido.unMedVenda;
    this.itemDetalhado.itemPedido.idTabelaPreco = itemPedido.idTabelaPreco;
    this.itemDetalhado.itemPedido.quantidade = itemPedido.quantidade !== 0 ? itemPedido.quantidade : 1;
    this.itemDetalhado.itemPedido.vlPrecoTabelaAntigo = this.itemDetalhado.itemPedido.preco;
    this.itemDetalhado.itemPedido.preco = itemPedido.preco;
    this.itemDetalhado.itemPedido.valorLiquido = itemPedido.valorLiquido;
    this.itemDetalhado.itemPedido.valorLiquidoIpiSt = itemPedido.valorLiquidoIpiSt;
    this.itemDetalhado.itemPedido.vlPrecoTabelaNovo = itemPedido.preco;
    this.itemDetalhado.itemPedido.total = total;
  }


}
