import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchbarService {
  idsPesquisaEmitter = new EventEmitter<any>();
  isLoadingEmitter = new EventEmitter<any>();
  ocultarAbasEmitter = new EventEmitter<any>();
  pesquisaSearchbarEmitter = new EventEmitter<any>();
  
  emitIdsPesquisa(ids:any){
    this.idsPesquisaEmitter.emit(ids);
    this.pesquisaSearchbarEmitter.emit(true);
  }

  emitIsLoading(boolean: any){
    this.isLoadingEmitter.emit(boolean);
  }

  emitOcultarAbas(boolean: any){
    this.ocultarAbasEmitter.emit(boolean);
  }
}
