import { Injectable } from '@angular/core';
import { Carrinho } from '../persistences/carrinho';
import { PedidoVenda } from '../persistences/pedido-venda';
import { Config } from './../persistences/config';
import { Cliente } from './../persistences/cliente';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageSingletonService {

    constructor() {        
      console.log("Reconstruiu LocalStorageSingletonService");
    }

    private setarItem(chave: string, valor: any): void{
      localStorage.setItem(chave, JSON.stringify(valor));
    }

    private buscarItem<T>(chave: string): T | null {
      const item = localStorage.getItem(chave);
      return item ? JSON.parse(item) : null;
    }

    /*Nao sei porque, mas alguns objetos mesmo com o buscarItem forçando parse, ainda precisou garantir novamente*/
    private forceParse(json: any): any {
      if (typeof json === 'string') {
        json = JSON.parse(json);
      } 
      return json;
    }
    
    atualizarCarrinho(carrinho : Carrinho): void {
      this.setarItem("carrinho", JSON.stringify(carrinho));
    }    
    
    atualizarPedido(pedidoVenda : PedidoVenda): void {
      this.setarItem("pedidoVenda", JSON.stringify(pedidoVenda));
    }           

    atualizarConfig(config : Config): void {
      this.setarItem("config_business", JSON.stringify(config));
    }

    atualizarCliente(cliente: Cliente): void {
      this.setarItem("cliente_business", JSON.stringify(cliente));
    }

    buscarCliente():Cliente {
      return new Cliente(this.forceParse(this.buscarItem("cliente_business")));
    }
    
    buscarCarrinho(): Carrinho {     
      return new Carrinho(this.forceParse(this.buscarItem("carrinho"))); 
    }

    buscarPedido(): PedidoVenda {
      return new PedidoVenda(this.forceParse(this.buscarItem("pedidoVenda")));
    }

    buscarConfig(): Config {      
      return new Config(this.forceParse(this.buscarItem("config_business")));
    }

    limparStorage(): void {
      localStorage.clear();
    }

}
