import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar-customizada',
  templateUrl: './progress-bar-customizada.component.html',
  styleUrls: ['./progress-bar-customizada.component.scss']
})
export class ProgressBarCustomizadaComponent {

  @Input()
  public frase01: string;
  @Input()
  public frase02: string;
  @Input()
  public frase03: string;
  @Input()
  public classHtml: string;
}
