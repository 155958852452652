export class ProdutoUnidade{		
    idGeral?: number;
    alturaCm?: number;
    comprimentoCm?: number;    
    fatorConversao?: number;
    flTipo?: number;
    larguraCm?: number;
    maximo?: number;
    minimo?: number;
    multiplo?: number;
    pesoBruto?: number;
    pesoLiquido?: number;
    unMedida: string;
    qtAtual?: number;
    qtDisponivelParaVenda?: number;
    qtEstoqueFisico?: number;
    estoqueDisponivel?: boolean;

    constructor(json?: any) {
        if (json) {            
            this.idGeral = json.idGeral;
            this.alturaCm = json.alturaCm;
            this.comprimentoCm = json.comprimentoCm;
            this.fatorConversao = json.fatorConversao;
            this.flTipo = json.flTipo;
            this.larguraCm = json.larguraCm;
            this.maximo = json.maximo;
            this.minimo = json.minimo;
            this.multiplo = json.multiplo;
            this.pesoBruto = json.pesoBruto;
            this.pesoLiquido = json.pesoLiquido;
            this.unMedida = json.unMedida;
            this.qtAtual = json.qtAtual;
            this.qtDisponivelParaVenda = json.qtDisponivelParaVenda;
            this.qtEstoqueFisico = json.qtEstoqueFisico;
            this.estoqueDisponivel = json.estoqueDisponivel;
        } 
    }
}