import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-item-carrinho-pagina-excluir',
  templateUrl: './item-carrinho-pagina-excluir.component.html',
  styleUrls: ['./item-carrinho-pagina-excluir.component.css'],
  preserveWhitespaces: true
})
export class ItemCarrinhoPaginaExcluirComponent {


  constructor(
    private dialogRef: MatDialogRef<ItemCarrinhoPaginaExcluirComponent>,
    ) { }
}
