import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { JwtService } from '../jwt.service';
import { ConsultaCustomizada } from '../persistences/consulta-customizada';

@Injectable({
  providedIn: 'root',
})
export class ConsultaCustomizadaService {
    
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private jwtService: JwtService,
  ) {}

  public getConsulta(termoPesquisado: string, consultaCustomizada: ConsultaCustomizada): Observable<any> {
    this.jwtService.validarAcessToken();

    return this.httpClient.get(
      this.configService.getEndereco() + '/Consulta/getConsulta',
      {
        headers: this.configService.getHeaders(),

        params: new HttpParams()
          .set('filtro', termoPesquisado)
          .set('campoFiltro', consultaCustomizada.campoFiltro)
          .set('complementoConsulta', consultaCustomizada.complementoConsulta)
          .set('identificador', consultaCustomizada.identificador)
          .set('tpFiltro', consultaCustomizada.tpFiltro),    
      }
    );
  }
}
