export class CondicaoPagamento {
    cdFormaPgto: number;
    nmFormaPgto: string;
    cdPortador: number;
    classificacao: string;
    flAtivo: string;
    hierarquia: string;
    vlMinimoParcela: string;
    condicoes: CdPgt[];
}
 
export class CdPgt {
    cdFormaPgto: number;
    cdCondPgto: number;
    nmCondPgto: string;
    hierarquia: number;
    diaDoMes: string;
    diaSemana: string;
    modalidade: string;
    nrParcelas: number;
    vlMinimoParcela: string;
    prazoInicial: number;
    nrDiasPrazoMaximo: number
}