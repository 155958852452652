<app-toolbar></app-toolbar>
<div class="grid-container">
	<div class="barra-pesquisa">
	</div>
</div>
<div class="grid-container">
	<div class="carrossel-component" *ngIf="isCliente">
		<app-carrossel-cabecalho
		  (idsPesquisaEmitter)="getIdsConsultaPaineis($event)"
		  (ordemEmitter)="setOrdemPainel($event)"
		  (filtroEmitter)="setAbrirFiltroPainel($event)"
		  [ordenacaoInput]="ordenacaoDefault">
		</app-carrossel-cabecalho>
	</div>
</div>
<div>
	<div *ngIf="!estadosDaPesquisa.estadoInicial && !this.estadosDaPesquisa.registrosEncontrados && !isLoading">
		<div class="alinhar-itens">
			<mat-icon color="warn" class="icone-nao-encontrado">warning</mat-icon>
			<h3>Nenhum item encontrado</h3>
		</div>
	</div>
	<div class="alinhar-itens">
		<mat-spinner class="spinner-align" mode="indeterminate"
			*ngIf="!this.estadosDaPesquisa.estadoInicial && !this.estadosDaPesquisa.pesquisaPronta || isLoading">
		</mat-spinner>
	</div>
	<div *ngIf="this.estadosDaPesquisa.registrosEncontrados">
		<app-painel *ngIf="this.estadosDaPesquisa.pesquisaPronta && this.estadosDaPesquisa.registrosEncontrados"
			[idsPesquisados]="idsPesquisados"
			(ordenacaoDefaultEmitter)="setOrdenacaoInput($event)">
		</app-painel>
	</div>
</div>
<app-rodape></app-rodape>