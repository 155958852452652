import { ClienteFilial } from "./cliente-filial";

export class Cliente {


	private cdCliente = 0;
	private nmCliente = "";
	private cdFuncionario: number;
	private cdTipoEntrega: number;
	private cdFormaPadrao: number;
	private cdCondPadrao: number;
	private cnpjCpf: string;
	private nmFantasia: string;
	private tpDocFatura: string;
	private pcMarkup: number;
	private clienteFilial: ClienteFilial;

	constructor(json?: any) {    
        if (json) {       
            // Inicialização a partir do objeto JSON
            this.cdCliente = json.cdCliente;
			this.nmCliente = json.nmCliente;
			this.cdFuncionario = json.cdFuncionario;
			this.cdTipoEntrega = json.cdTipoEntrega;
			this.cdFormaPadrao = json.cdFormaPadrao;
			this.cdCondPadrao = json.cdCondPadrao;
			this.cnpjCpf = json.cnpjCpf;
			this.nmFantasia = json.nmFantasia;
			this.tpDocFatura = json.tpDocFatura;
			this.pcMarkup = json.pcMarkup;
			if (json.clienteFilial !== undefined) {
				this.clienteFilial = new ClienteFilial(json.clienteFilial);
			} else {
				this.clienteFilial = new ClienteFilial();
			}
        } 
    }

	/*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO ClienteSingletonService*/

	get getCdCliente():number {
        return this.cdCliente;
    }

	_setCdCliente(cdCliente: number): void {
		this.cdCliente = cdCliente;
	}

	get getNmCliente():string {
        return this.nmCliente;
    }

	get getCdFuncionario():number {
        return this.cdFuncionario;
    }

	get getCdTipoEntrega():number {
        return this.cdTipoEntrega;
    }

	get getCdFormaPadrao():number{
		return this.cdFormaPadrao;
	}

	get getCdCondPadrao(): number{
		return this.cdCondPadrao;
	}

	get getCnpjCpf():string {
        return this.cnpjCpf;
    }

	get getNmFantasia():string {
        return this.nmFantasia;
    }

	get getTpDocFatura():string {
        return this.tpDocFatura;
    }

	get getPcMarkup():number {
        return this.pcMarkup;		
    }

	get getClienteFilial():ClienteFilial {
		return this.clienteFilial;
	}

	_setClienteFilial(clienteFilial: ClienteFilial): void {
		this.clienteFilial = clienteFilial;
	}

	_setPcMarkup(pcMarkup: number): void {
		this.pcMarkup = pcMarkup;
	}
	_setFormCondPadrao(cdFormaPadrao: number, cdCondPadrao: number){
		this.cdFormaPadrao = cdFormaPadrao;
		this.cdCondPadrao = cdCondPadrao;
	}

}
