import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtService } from '../jwt.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadDocumentosService {
  
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private jwtService: JwtService
  ) { }

  getBoleto(id: number){
    this.jwtService.validarAcessToken();
    return this.httpClient.get(
      this.configService.getEndereco() + '/Boletos/getBoletos',
      {
        headers: this.configService.getHeaders(),
        params: new HttpParams().set('idPedido', id)
      }
    ) 
  }

  getXml(id: number){
    this.jwtService.validarAcessToken();
    return this.httpClient.get(
      this.configService.getEndereco() + '/Nfc/getXml',
      {
        headers: this.configService.getHeaders(),
        params: new HttpParams().set('idPedido', id)
      }
    ) 
  }

  getDanfe(id: number){
    this.jwtService.validarAcessToken();
    return this.httpClient.get(
      this.configService.getEndereco() + '/Nfc/getDanfe',
      {
        headers: this.configService.getHeaders(),
        params: new HttpParams().set('idPedido', id)
      }
    ) 
  }
}
