import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SnackbarManager } from './managers/snackbar-manager';
import { IDadosSenha } from './interfaces/ialterar-senha';
import { FormaCondPgto } from './persistences/forma-cond-pgto';
import { ItemDetalhado } from './persistences/item-detalhado';
import { ConfigService } from './services/config.service';
import { CartSingletonService } from './singletons/cart-singleton.service';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';
import { Carrinho } from './persistences/carrinho';
import { LocalStorageSingletonService } from './singletons/local-storage-singleton.service';
import { Config } from './persistences/config';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

	constructor(
		private configService: ConfigService,
		private httpClient: HttpClient,
		private router: Router,
		private cartSingleton: CartSingletonService,
		private snackBarManager: SnackbarManager,
		private clienteSingletonService: ClienteSingletonService,
		private localStorageSingletonService: LocalStorageSingletonService
	) { }


	login(user: string, password: string) {
		return this.httpClient.post(
			this.configService.getEndereco() + '/Business/login',
			{
				username: user,
				password: password,
			},
			{ headers: new HttpHeaders({ 'authToken': "" })}
		)
	}


	logout() {		
		this.localStorageSingletonService.limparStorage();
		this.clienteSingletonService.novoCliente();	
		const config: Config = this.configService.getConfig(); 
		this.cartSingleton.novoCarrinho(0, 0, 0,  '');
		this.router.navigate(['/login']);
	}


	public static loggedIn(): boolean{
		return localStorage.getItem('access_token_business') !==  null;
	}

	public checkLogin () {
		if (!JwtService.loggedIn()) {
			this.router.navigate(['/login']);
			this.snackBarManager.openSnackBar("Sua sessão expirou. Efetue o login novamente.", "Ok", 5000);
		}
	}

	public InicioLogin(){
		if(localStorage.getItem('access_token_business') == null){
			this.router.navigate(['/login']);
		}
	}

	public validarAcessToken(httpErrorResponse: HttpErrorResponse = null){
		const segundosExpires: number = Number.parseInt(localStorage.getItem("expires_at_seconds_business"));
		const accesToken = localStorage.getItem("access_token_business");
		const dataAtual = new Date;
		const dataExpiracao = new Date()
		dataExpiracao.setSeconds(segundosExpires);

		if ((dataExpiracao < dataAtual) || (accesToken == 'undefined') || (accesToken == null) || 
		((httpErrorResponse !== null) && ((httpErrorResponse.status === 401) || (httpErrorResponse.status === 403)) 
		&& (!httpErrorResponse.ok))){
			this.snackBarManager.openSnackBar("Sua sessão expirou. Efetue o login novamente.", "Ok", 5000);
			this.logout();
		}
	}

	public alterarSenha(dadosSenha: IDadosSenha): Observable<any>{
		this.validarAcessToken();

        return this.httpClient.put<any>(
            `${this.configService.getEndereco()}/login/alterar-senha`,
            dadosSenha,
            { headers: new HttpHeaders({authToken: localStorage.getItem('access_token_business')})}
        );
	}

	temAcesso(){
        return this.httpClient.get(
                this.configService.getEndereco() + '/acesso/hasAcesso?modulo=BUSINESS',
                {
                    headers: this.configService.getHeaders(),
                }
            )
    }

	getBearerToken(){
		let httpParams = new HttpParams();
		httpParams = httpParams
			.append('username', localStorage.getItem('usuario'));
		return this.httpClient.get(
			this.configService.getEndereco() + '/getBearerToken',
			{
				headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
    }

	generateBearerToken(){		
		return this.httpClient.post(
			this.configService.getEndereco() + '/generateBearerToken',
			{
				username: localStorage.getItem('usuario'),				
			},
			{
				headers: this.configService.getHeaders(),				
			}
		)
	}
}
