export class ClienteFilial {

    private cd_filial: number;
    private nm_filial: string;
    private nmFantasia: string;
    private imgStr: string;

    constructor(json?: any) {        
        if (json) {            
            // Inicialização a partir do objeto JSON
            this.cd_filial = json.cd_filial;
			this.nm_filial = json.nm_filial;
			this.nmFantasia = json.nmFantasia;
			this.imgStr = json.imgStr;
        }
    }    

    /*TODOS OS METODOS SET FORAM PADRONIZADOS COM _ PARA FUGIR DO PADRAO E SEREM UTILIZADOS SOMENTE POR DENTRO DO ClienteSingletonService*/
    get getCdFilial():number {
        return this.cd_filial;
    }

	get getNmFilial():string {
        return this.nm_filial;
    }

    get getNmFantasia():string {
        return this.nmFantasia;
    }

	get getImgStr():string {
        return this.imgStr;
    }

    _setImgStr(imgStr: string): void {
        this.imgStr = imgStr;
    }
}



