<app-toolbar #toolbar></app-toolbar>
<mat-divider></mat-divider>

<app-progress-bar-customizada class="custom-progress-bar" [frase01]="frase01"
	*ngIf="validandoItensDoCarrinho"></app-progress-bar-customizada>
<app-progress-bar-customizada class="custom-progress-bar" [classHtml]="'frase'"
	[frase01]="'Aguarde! Estamos concluíndo seu seu pedido.'" [frase02]="'Gostando do nosso Sistema?'"
	[frase03]="'Dúvidas? Entre em contato com o suporte.'" *ngIf="gravandoPedido"></app-progress-bar-customizada>
<div class="carrinho-container float-left" *ngIf="!gravandoPedido">		
	<mat-list class="lista-item-carrinho-pagina padding-0">
		<mat-list-item *ngIf="carrinho.getItensCarrinho.length === 0">
			Carrinho vazio :(
		</mat-list-item>
		<mat-card *ngIf="carrinho.getItensCarrinho.length > 0  && !validandoItensDoCarrinho" appearance="outlined" class="card-resumo-pedido margin-default"
		style="background: whitesmoke !important;">
			<div class="margin-left-20" >
				<h2 class="color-trans-black">Carrinho</h2>
			</div>
			<mat-divider></mat-divider>
			<mat-list-item *ngFor="let item of carrinho.getItensCarrinho" class="item-lista-carrinho-pagina">
		
				<app-item-carrinho-pagina class="item-carrinho" [itemDetalhado]="item" *ngIf="!validandoItensDoCarrinho">
				</app-item-carrinho-pagina>
		
				<mat-divider *ngIf="!validandoItensDoCarrinho"></mat-divider>
			</mat-list-item>
			<mat-divider *ngIf="!validandoItensDoCarrinho"></mat-divider>
		</mat-card>
	</mat-list>
</div>

<div class="resumo-pedido float-left" *ngIf="!gravandoPedido  && !validandoItensDoCarrinho">
	
	<mat-card appearance="outlined" class="card-resumo-pedido margin-default"
		style="background: whitesmoke !important;">
		<h2 class="item-6 color-trans-black">{{this.nomeFantasia}}</h2>
		<mat-divider></mat-divider>
		<mat-card-header>
			<div mat-card-avatar>
				<mat-icon>shopping_bag</mat-icon>
			</div>
			<mat-card-title>Resumo</mat-card-title>
			<mat-card-subtitle>Revise seu pedido antes de finalizar</mat-card-subtitle>
		</mat-card-header>
		<div class="pedidoAprovar">
			<mat-checkbox color="primary" [(ngModel)]="orcamento" class="tipoPedido">Orçamento</mat-checkbox>
		</div>
		<mat-divider></mat-divider>
		<mat-card-content class="formaPgto">
			<mat-icon class="icon">assignment_turned_in</mat-icon>
			<mat-card-title class="title">Observação</mat-card-title>
		</mat-card-content>
		<div class="pedidoAprovar">
			<mat-checkbox color="primary" [(ngModel)]="notaFiscal" class="tipoPedido">Destacar em nota fiscal
			</mat-checkbox>
		</div>
		<div style="margin-left: 15px;">
			<mat-form-field class="campo-item-n1 cor mat-form">
				<mat-label color="primary">Coloque uma observação:</mat-label>
				<textarea style="margin-left:20px" class="text-max-size-200 " matInput [(ngModel)]="obs"></textarea>
			</mat-form-field>
		</div>
		<mat-divider></mat-divider>
		<mat-card-content class="formaPgto">
			<mat-icon class="icon">attach_money</mat-icon>
			<mat-card-title class="title">Forma de Pagamento</mat-card-title>
		</mat-card-content>
		
		<div>
			<div class="item-3">
				<select name="select" class="condicaoPagamento" 
				(change)="listarFormasDePagamento($event.target.value);"
					[value]="getFormaPadrao()">
					<optgroup label="ESCOLHA A FORMA" [disabled]="getFormaPadrao() === 0"></optgroup>					
					<option *ngFor="let formapgto of formasDePagamento" [value]="formapgto.cdFormaPgto"
						[selected]="getFormaPadrao() === formapgto.cdFormaPgto">
						{{ formapgto.nmFormaPgto }}
					</option>
				</select>
			</div>
			<div class="item-3-row-2">
				<select id="selectCondicoes" class="condicaoPagamento"
				(change)="calcularParcela($event.target.value); setCondPgtoSelecionada($event.target.value)"
				[value]="getCondPgtoSelecionada()">
					<optgroup label="CONDIÇÃO DE PAGAMENTO" [disabled]="getCondPgtoSelecionada() === 0">						
						<option *ngFor="let cond of listaCondicoes" [value]="cond.cdCondPgto"
							[selected]="getCondPgtoSelecionada() === cond.cdCondPgto">
							{{ cond.nmCondPgto }}
						</option>
					</optgroup>
				</select>
			</div>
		</div>
		<mat-divider style="margin-top: 10px;"></mat-divider>
		<br>
		<mat-card-content class="formaPgto">
			<mat-icon class="icon">local_shipping</mat-icon>
			<mat-card-title class="title">Tipo de Entrega</mat-card-title>
		</mat-card-content>
		<div class="item-3">
			<select id="selectTpEntrega" name="select" class="condicaoPagamento "
				(change)="setTpEntrega($event.target.value)" [(ngModel)]="indiceTpEntrega">
				<option class="fake" [value]="0">ESCOLHA O TIPO DE ENTREGA </option>
				<option *ngFor="let entrega of tipoEntrega" [value]="entrega.cdTipoEntrega"> {{ entrega.nmTipoEntrega }}
				</option>
			</select>
		</div>
		<mat-card-content class="padding-default">
			<div>
				<div style="float: left;">
					<div *ngIf="carrinho.getItensCarrinho.length !== 1" style="float: left;">
						{{carrinho.getItensCarrinho.length}} Produtos
					</div>
					<div *ngIf="carrinho.getItensCarrinho.length === 1" style="float: left;">
						{{carrinho.getItensCarrinho.length}} Produto
					</div>
				</div>
				<mat-label style="float: right;">{{carrinho.getTotal | currency:'R$':'code'}}
				</mat-label>
			</div>
			<br><br>
			<div *ngIf="parcela">
				<div style="float: left;">Parcelas</div>
				<div style="float: right;">{{(carrinho.getTotal/this.parcelaQuantidade).toFixed(2)|
					currency:'R$':'code'}}</div>
			</div>
			<br><br>
			<div>
				<div style="float: left;">Frete</div>
				<div style="float: right;">{{carrinho.getFrete | currency:'R$':'code'}}</div>
			</div>
		</mat-card-content>
		<mat-divider class="divisor"></mat-divider>
		<div class="margin-default">
			<div class="total color-trans-black align-right"><b>Total:
					{{carrinho.getTotal +
					carrinho.getFrete | currency:'R$':'code'}}</b></div>
		</div>
		<mat-divider></mat-divider>
		<mat-card-actions class="grid-container">
			<div class="item-1">
				<button mat-flat-button color="primary" class="continuarCompra" (click)="continuarComprando()">Continuar
					comprando</button>
			</div>
			<div class="item-2">
				<button mat-flat-button color="primary" class="botao-finalizar" (click)="finalizar()"
					[disabled]="carrinho.getTotal <= 0">Finalizar</button>
			</div>
		</mat-card-actions>
		
	</mat-card>		
</div>
<app-rodape></app-rodape>