import { Component, Input } from '@angular/core';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';

@Component({
  selector: 'app-item-descricao-detalhado',
  templateUrl: './item-descricao-detalhado.component.html',
  styleUrls: ['./item-descricao-detalhado.component.css']
})
export class ItemDescricaoDetalhadoComponent {
	@Input()
	public item: ItemDetalhado;
	@Input()
	public imageObject: Array<object> = [];
	@Input()
	public imagensCarregadas = false;

}