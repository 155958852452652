import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JwtService } from 'src/app/jwt.service';
import { ClienteManager } from 'src/app/managers/cliente-manager';
import { ItemManager } from 'src/app/managers/item-manager';
import { PedidoManager } from 'src/app/managers/pedido-manager';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { NumberUtils } from 'src/app/utils/utils/number-utils';
import { ItemCarrinhoPaginaExcluirComponent } from '../item-carrinho-pagina/item-carrinho-pagina-excluir/item-carrinho-pagina-excluir.component';

@Component({
  selector: 'app-quantidade-lista',
  templateUrl: './quantidade-lista.component.html',
  styleUrls: ['./quantidade-lista.component.css'],
})
export class QuantidadeListaComponent implements OnInit {
  delayTime = 500;
  private btnClicked = new Subject();

  @ViewChild('inputHtml')
  public inputNumber;
  @Input()
  public temEstoque;
  @Input()
  public preco;
  @Input()
  public unMedVenda;
  @Input()
  public item: ItemDetalhado;
  @Input()
  public detalhada = false;
  @Input()
  public ignorarRotina = false;
  public valorTotalItem: number;
  @Input()
  public statusComponent = true;
  public quantidadeAtualDoItem = 0.0000;
  
  @Input()
  public quantidadeItemString;
  private ignorarValidacaoDeMultiplo = false;
  @Input()
  public estoqueDisponivel;

  constructor(
    public dialog: MatDialog,
    private cartSingletonService: CartSingletonService,
    private pedidoManager: PedidoManager,
    private itemManager: ItemManager,
    private snackBarManager: SnackbarManager,
    private jwtService: JwtService,
    private clienteManager: ClienteManager
  ) {
  }

  ngOnInit(): void {
    this.pedidoManager.desabilitaControles.subscribe((status) => {
      this.statusComponent = status;
    });
    this.ignorarValidacaoDeMultiplo = this.itemManager.ignorarValidacaoDeMultiplo(this.item);
    this.itemManager.getEventObservable().subscribe((item) => {
      if (!this.itemManager.isEventHandled()) {
        this.item = item;
        this.quantidadeItemString = this.item.itemPedido.quantidade.toString();
        if(!this.cartSingletonService.itemEstaNoCarrinho(this.item)){
          this.adicionarItensAoCarrinho(parseFloat(this.quantidadeItemString));
          this.snackBarManager.openSnackBar("Item adicionado no carrinho.", "Ok", 2000);
        }
        else
          this.atualizarQtdeDoItemAtual(this.quantidadeItemString);
      }
      this.itemManager.setEventHandled(true);
    });
  }
  
  addQuantidadeItem(quantidade: number) {
    this.item.itemPedido.quantidade = this.calcularQuantidadeDoItem(quantidade);
    this.adicionarItensAoCarrinho(this.item.itemPedido.quantidade);    
  }

  adicionarItensAoCarrinho(quantidade: number) {
    //PROVISORIO, PRECISO ENTENDER ESSAS VALIDACOES MELHOR
    if (this.clienteManager.validarClienteVendedor()) {
      if (this.item.itemPedido.quantidade <= 0) {
        this.statusComponent = false;
      }
      this.item.itemNovo = (this.item.itemPedido.quantidade === null) 
                          || (this.item.itemPedido.quantidade === undefined) 
                          || (this.item.itemPedido.quantidade <= 0);
      this.item.itemPedido.quantidade = quantidade;
      this.quantidadeItemString = this.formatarInput(this.item.itemPedido.quantidade);
      if(this.inputNumber != undefined){
        this.inputNumber.nativeElement.value = this.quantidadeItemString;
      }
      this.pedidoManager.gravarItemCarrinhoPedido(this.item).then((status) => (
        this.statusComponent = status
      ))
      .catch((error: HttpErrorResponse) => {
        this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
      });
      this.btnClicked.next();
    }
  }

  atualizarQtdeDoItemAtual(quantidadeItemString: string) {    
    this.validaInput(quantidadeItemString);
  }

  removerQtdeDoItemAtual(quantidade: number) {
    if (this.clienteManager.validarClienteVendedor()) {
      const quantidadeArredondada = (this.item.itemPedido.quantidade - quantidade).toFixed(4);
      if(parseFloat(quantidadeArredondada.replace(',','.')) >= this.getMinimo()){
        this.item.itemPedido.quantidade = this.calcularQuantidadeDoItem(quantidade * - 1);
        this.quantidadeItemString = this.formatarInput(this.item.itemPedido.quantidade);
        this.pedidoManager.removerQuantidadeItemCarrinho(this.item);
        this.btnClicked.next();
      }
    }
  }

  validaInput(quantidadeItemString: string) {    
    if (this.validarQuantidadeInformada(quantidadeItemString)) {         
      this.recalculaTotal();
      this.adicionarItensAoCarrinho(this.item.itemPedido.quantidade);
    }
  }

  ajustaQuantidadeMaxima(quantidade: number) {
    const multiplo = this.getMultiplo();
    const sobra = this.calcularResto(quantidade % multiplo);
    this.snackBarManager.openSnackBar(
      'Quantidade máxima: ' + (this.item.itemDetalhes.qtDisponivel - sobra),
      'Ok',
      3000
    );
    this.item.itemPedido.quantidade = this.item.itemDetalhes.qtDisponivel - sobra;
    this.quantidadeItemString = this.formatarInput(this.item.itemPedido.quantidade);
    this.atualizarInput(this.quantidadeItemString);
  }

  recalculaTotal() {
    this.item.itemPedido.total = NumberUtils.roundTo(
      this.item.itemPedido.valorLiquidoIpiSt *
      (this.item.itemPedido.quantidade == null ? 0 : this.item.itemPedido.quantidade),
      2
    );
    this.valorTotalItem = this.item.itemPedido.total;
  }

  removerItemCarrinho() {
    this.pedidoManager.removerItemCarrinhoPedido(this.item);      
    this.recalculaTotal();
  }

  removerItemDoCarrinho(): boolean {
    if (this.clienteManager.validarClienteVendedor()) {
      let removeItemDoCarrinho = false;
      const dialogRef = this.dialog.open(ItemCarrinhoPaginaExcluirComponent, {
        width: '400px',
        height: '100px',
        panelClass: 'custom-dialog',
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          if (result) {
            this.removerItemCarrinho();
            removeItemDoCarrinho = true;
          }
        },
        (error) => {
          removeItemDoCarrinho = false;
          this.jwtService.validarAcessToken(error);
        }
      );
      return removeItemDoCarrinho;
    }
  }

  validarQuantidadeInformada(quantidadeItemString: string): boolean {
    const quantidade = parseFloat(quantidadeItemString.replace(',','.'));    
    if(quantidade === 0 || isNaN(quantidade)){
      return
    }
    const minimo = this.getMinimo();
    if (quantidade > 0 && quantidade < minimo) {
      this.snackBarManager.openSnackBar(
        'Quantidade minima de venda para este item é: ' + 
        minimo.toString(),
        'Ok',
        3000
      );
      const qtdOriginal = minimo;
      this.quantidadeItemString = this.formatarInput(qtdOriginal);
      this.item.itemPedido.quantidade = qtdOriginal;
      this.atualizarInput(this.quantidadeItemString);       
      return false;
    }

    if (!this.ignorarValidacaoDeMultiplo) {
      if (this.calcularResto(quantidade) !== 0) {
        this.item.itemPedido.quantidade = this.quantidadeAtualDoItem;        
        this.quantidadeItemString = this.formatarInput(this.quantidadeAtualDoItem);
        this.snackBarManager.openSnackBar(
          'Use apenas valores multiplos de: ' + this.getMultiplo().toString(),
          'Ok',
          3000
        );
        return false;
      }
    }
    if (quantidade > this.item.itemDetalhes.qtDisponivel) {
      this.ajustaQuantidadeMaxima(this.item.itemDetalhes.qtDisponivel);
    } else {
      this.item.itemPedido.quantidade = quantidade;
      this.atualizarInput(this.quantidadeItemString);
    }
    return true;
  }

  calcularQuantidadeDoItem(quantidade: number): number {
    let quantidadeAtual = this.item.itemPedido.quantidade;
    quantidadeAtual += quantidade;
    this.quantidadeAtualDoItem = quantidadeAtual;
    return Number(quantidadeAtual.toFixed(4));
  }

  calcularResto(quantidade: number): number {
    const quantidadeAjustada = Math.round(quantidade * 100);
    const multiploAjustado = Math.round(this.getMultiplo() * 100);
    const resto = quantidadeAjustada % multiploAjustado;
    return resto / 100;
  }

  setQuantidadeAtualDoItem($event) {
    this.inputNumber.nativeElement.select();
    this.quantidadeAtualDoItem = parseFloat($event.target.value.replace(',','.'));
  }

  botaoAdicionarVisivel(): boolean {   
    return !this.cartSingletonService.itemEstaNoCarrinho(this.item); 
  }

  itemDisponivel(): boolean {
    if (this.temEstoque || this.preco <= 0 || this.item.itemDetalhes.qtDisponivel < this.item.itemDetalhes.qtdMin) {
      return false;
    }
    return true;
  }

  inputQtdeVisivel(): boolean {   
    return (this.detalhada !== false || this.cartSingletonService.itemEstaNoCarrinho(this.item));
  }

  botaoAdicionarItemVisivel(): boolean {      
    return (this.detalhada !== false || this.cartSingletonService.itemEstaNoCarrinho(this.item));
  }

  isQuantidadeMaxima(quantidadeString: string): boolean{
     const quantidade = parseFloat(quantidadeString.replace(',','.'));
    const quantidadeArredondada = (quantidade + this.getMultiplo()).toFixed(4);
    return parseFloat(quantidadeArredondada.replace(',','.')) > this.item.itemDetalhes.qtDisponivel;
  }

  isQuantidadeMinima(quantidadeString: string): boolean{
    const quantidade = parseFloat(quantidadeString.replace(',','.'));
    const quantidadeArredondada = (quantidade - this.getMultiplo()).toFixed(4);    
    return parseFloat(quantidadeArredondada.replace(',','.')) < this.getMinimo();
  }  

  getMinimo(): number{
    return this.itemManager.getMinimo(this.item).minimoControle;
  } 

  getMultiplo(): number{   
    return this.itemManager.getMultiplo(this.item);
  }

  barrarInputsIncorretos(input){
     const pattern = /^[0-9,.]*$/
     const inputDoUsuario = String.fromCharCode(input.charCode);
     if(!pattern.test(inputDoUsuario)){
      input.preventDefault();
    }
  }

  atualizarInput(quantidadeItemString:string){    
    if( this.inputNumber != undefined){
      this.inputNumber.nativeElement.value = quantidadeItemString;
    }
  }  

  onBlur(quantidadeItemString:string ){
    if(quantidadeItemString.trim() === '' || quantidadeItemString.trim() === '0' || quantidadeItemString.trim() === '0,'){
      this.quantidadeItemString = this.formatarInput(this.item.itemPedido.quantidade);
      this.inputNumber.nativeElement.value = this.quantidadeItemString;
    }
  }

  formatarInput(quantidade: number):string {    
    return quantidade.toLocaleString('pt-BR',{useGrouping: false}).replace('.',',');
  }

  onClickQuantidade() {
    this.snackBarManager.openSnackBarCustom(
      'Quantidade de estoque indisponível!',
      'Ok',
      5000
    );
  }

}
