import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtService } from 'src/app/jwt.service';
import { ItemManager } from 'src/app/managers/item-manager';
import { PedidoManager } from 'src/app/managers/pedido-manager';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemPedido } from 'src/app/persistences/item-pedido';
import { ItemService } from 'src/app/services/item.service';
import { ItemDetalhadoComponent } from '../item-detalhado/item-detalhado.component';
import { ListaPrecoComponent } from '../lista-preco/lista-preco.component';
import { SnackbarManager } from './../../managers/snackbar-manager';

@Component({
  selector: 'app-item-carrinho-pagina',
  templateUrl: './item-carrinho-pagina.component.html',
  styleUrls: ['./item-carrinho-pagina.component.css'],
  preserveWhitespaces: true,
})
export class ItemCarrinhoPaginaComponent implements OnInit {
  @Input()
  public itemDetalhado: ItemDetalhado;

  @Output()
  public addItem = new EventEmitter<ItemDetalhado>();

  @Output()
  public addItemCarrinho = new EventEmitter<ItemDetalhado>();

  public imagem = '';

  constructor(
    public itemManager: ItemManager,
    public pedido: PedidoManager,
    public dialog: MatDialog,
    private itemService: ItemService,
    private jwtService: JwtService,
    private snackBarManager: SnackbarManager
  ) {}

  ngOnInit(): void {
    if(this.itemService.idsItensNoCarrinho.includes(this.itemDetalhado.itemDetalhes.idItem)){
			return
		} else {
      this.itemService.idsItensNoCarrinho.push(this.itemDetalhado.itemDetalhes.idItem)
      this.itemService
      .getImagem(this.itemDetalhado.itemDetalhes.idItem, true)
      .subscribe(
        (imagem: string) => {
          if (imagem == '') {
            this.imagem = '';
          } else {
            this.imagem = 'data:image/jpeg;base64,' + imagem;
          }
        },
        (error) => this.jwtService.validarAcessToken(error)
      );
    }
  }

  receberQuantidadeInput(quantidade: number) {
    this.itemDetalhado.itemPedido.quantidade = quantidade;
    this.itemManager.atualizarQuantidadeItem(this.itemDetalhado);
    this.addItemCarrinho.emit(this.itemDetalhado);
    this.addItem.emit(this.itemDetalhado);
  }

  openDialogDetalhes(): void {
    const dictItem: Map<number, ItemDetalhado> = new Map();
    dictItem.set(this.itemDetalhado.itemDetalhes.idItem, new ItemDetalhado());
    const idItem = this.itemDetalhado.itemDetalhes.idItem;
    const dialogRef = this.dialog.open(ItemDetalhadoComponent, {
      width: '95%',
      height: '95%',
      data: {
        trilhaItens: dictItem,
        itemDetalhado: this.itemDetalhado,
        id: idItem,
      },
    });

    dialogRef.componentInstance.addItem.subscribe(
      (item: ItemDetalhado) => {
        this.addCarrinho(item);
        this.addItemCarrinho.emit(item);
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error,"Ok",5000);
      }
    );

    dialogRef.afterClosed().subscribe(
      () => {
        this.itemDetalhado.itemDetalhes.idItem = idItem;
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error,"Ok",5000);
      }
    );
  }

  addCarrinho(itemAdd: ItemDetalhado) {
    this.pedido.gravarItemCarrinhoPedido(itemAdd);
  }

  onClickPreco() {
    this.snackBarManager.openSnackBarCustom(
      'Houve uma alteração de preço. ' +
      '\n' +
      'Preço antigo: R$' +
      this.itemDetalhado.itemPedido.vlPrecoTabelaAntigo,
      'Ok',
      5000
    );

    this.itemDetalhado.itemPedido.visualizouPrecoAntigo = true;
  }

  listarPrecosDoItem(): void {    
    const dialogRef = this.dialog.open(ListaPrecoComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        itemDetalhado: this.itemDetalhado,
      },
    });

    dialogRef.afterClosed().subscribe(
      (tabelaPreco) => {
        if (tabelaPreco !== null) {
          this.atualizarPrecoItem(this.itemManager.selecionarTabelaDePrecoDoItem(tabelaPreco, this.itemDetalhado));
          this.itemManager.atualizarTabelaDePrecoDoItem(this.itemDetalhado);
        }
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
      }
    );
  }

  atualizarPrecoItem(itemPedido: ItemPedido) {
    this.itemDetalhado.itemPedido.unMedVenda = itemPedido.unMedVenda;
    this.itemDetalhado.itemPedido.idTabelaPreco = itemPedido.idTabelaPreco;
    this.itemDetalhado.itemPedido.preco = itemPedido.preco;
    this.itemDetalhado.itemPedido.quantidade = itemPedido.quantidade !== 0 ? itemPedido.quantidade : 1;
    this.itemDetalhado.itemPedido.vlPrecoTabelaAntigo = this.itemDetalhado.itemPedido.preco;
    this.itemDetalhado.itemPedido.preco = itemPedido.preco;
    this.itemDetalhado.itemPedido.valorLiquido = itemPedido.valorLiquido;
    this.itemDetalhado.itemPedido.valorLiquidoIpiSt = itemPedido.valorLiquidoIpiSt;
    this.itemDetalhado.itemPedido.vlPrecoTabelaNovo = itemPedido.preco;
    this.itemDetalhado.itemPedido.total = this.itemDetalhado.itemPedido.valorLiquidoIpiSt * this.itemDetalhado.itemPedido.quantidade;
  }

  outrosPrecosDisponivel(): boolean {        
    const result = this.itemManager.removerTabelasDePrecoDuplicas(this.itemDetalhado); 
    return result === null || result.length <= 1; 
  }  

}
