import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogCustomizadoComponent } from 'src/app/components/genericos/dialog-customizado/dialog-customizado.component';
import { JwtService } from 'src/app/jwt.service';
import { ConverterCarrinhoManager } from 'src/app/managers/converter-carrinho.manager';
import { ItemManager } from 'src/app/managers/item-manager';
import { PedidoManager } from 'src/app/managers/pedido-manager';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { Carrinho } from 'src/app/persistences/carrinho';
import { Cliente } from 'src/app/persistences/cliente';
import { CdPgt } from 'src/app/persistences/condicao-pagamento';
import { FormaCondPgto } from 'src/app/persistences/forma-cond-pgto';
import { TipoEntrega } from 'src/app/persistences/tipo-entrega';
import { ConfigService } from 'src/app/services/config.service';
import { FormaCondService } from 'src/app/services/forma-cond.service';
import { PedidoService } from 'src/app/services/pedido.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';

@Component({
  selector: 'app-carrinho-pagina',
templateUrl: './carrinho-pagina.component.html',
  styleUrls: ['./carrinho-pagina.component.css'],
})
export class CarrinhoPaginaComponent implements OnInit, OnDestroy {
  public carrinho: Carrinho = new Carrinho();
  private subscription: Subscription;
  public formasDePagamento: FormaCondPgto[];
  public tipoEntrega: Array<TipoEntrega>;  
  public tEntrega = false;
  public valorParcela: number;
  public listaCondicoes: CdPgt[];
  public condicao: CdPgt;
  public orcamento = false;
  public parcelaQuantidade: number;
  public parcela = false;
  public notaFiscal = false;
  public obs = '';
  public indiceTpEntrega: number;
  public gravandoPedido = false;

  public frase01 = 'Validando pedido. Aguarde...';
  public classHtml: string;
  public validandoItensDoCarrinho = true;
  public nomeFantasia: string;
  public temAcesso = parseInt(localStorage.getItem('tem_acesso_business')) == 0 ? false : true;

  public cdFormaPgtoSelecionado: number;  
  public cdCondPgtoSelecionado: number;  	
  constructor(
    private snackBarManager: SnackbarManager,
    public itemManager: ItemManager,
    private pedidoManager: PedidoManager,
    private pedidoService: PedidoService,
    private configService: ConfigService,
    public cartSingletonService: CartSingletonService,
    private router: Router,
    private converterCarrinhoManager: ConverterCarrinhoManager,
    private jwtService: JwtService,
    private clienteSingletonService: ClienteSingletonService,
    private formaCondService: FormaCondService,
    private dialog: MatDialog
  ) {
    this.jwtService.checkLogin();
    if(!this.temAcesso){
      this.router.navigateByUrl('/minha-conta/meus-pedidos');
    }
    this.listaCondicoes = new Array<CdPgt>();
  }

  ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

  ngOnInit() {
    const cliente: Cliente = this.clienteSingletonService.getClienteEstatico();
    this.subscription = this.cartSingletonService.getCarrinhoObservable().subscribe(carrinho => {
			this.carrinho = carrinho;
		});
    if(this.carrinho.getCdCondPgto === undefined || this.carrinho.getCdFormaPgto === undefined){
      this.pedidoService.pedidoVendaPendenteBusiness(cliente.getCdCliente).subscribe(r => {
        this.cartSingletonService.atualizarDadosCabecalho(r.getCdFormaPgto, r.getCdCondPgto, r.getCdTpEntrega, r.getObservacoesInterna);
        this.formaCondService.getFormaCondPgto(cliente.getCdCliente).subscribe(response => {
          if(response.length == 0){
            this.jwtService.logout();
            this.snackBarManager.openSnackBar("O usuário não possui perfil comercial com forma e condição de pagamento","Ok", 3000);
            return
          }
          const formasAgrupadas: FormaCondPgto[] = [];
          response.forEach( forma => {
              const cdForma = forma.cdFormaPgto;
              if(!formasAgrupadas.some(item => item.cdFormaPgto === cdForma)){
                  formasAgrupadas.push(forma)
              } else{
                  forma.condicoes.forEach(cond => {
                      const formaAtual = formasAgrupadas.find(forma => forma.cdFormaPgto === cdForma)
                      if(!formaAtual.condicoes.some(item => item.cdCondPgto === cond.cdCondPgto)){
                          formasAgrupadas.forEach(forma => {
                              if(forma.cdFormaPgto === cdForma){
                                  forma.condicoes.push(cond);
                              }
                          })
                      }
                  })
              }
          })
          const formasResponse = formasAgrupadas.filter(forma => forma.cdFormaPgto === this.carrinho.getCdFormaPgto);
          let temForma = false;
          for(const f of formasResponse){
            const forma = f.condicoes;
            for(const condicao of forma){
              if(condicao.cdCondPgto === this.carrinho.getCdFormaPgto){
                temForma = true;
              }
            }
          }
          if(!temForma){
            this.cartSingletonService.setFormaECondicaoDePagamento(formasAgrupadas);                                        
            this.clienteSingletonService.atualizaFormaCodPadrao(formasAgrupadas);
          }
          this.formasDePagamento = this.carrinho.getFormaCondPgto.sort((form1, form2) =>
            form1.nmFormaPgto > form2.nmFormaPgto ? 1 : -1
          );
          this.listaCondicoes = new Array<CdPgt>();
          const formas = [];
          this.formasDePagamento.forEach((r) => { 
            if(r.cdFormaPgto === this.carrinho.getCdFormaPgto){
              formas.push(r);
            }
          });
          formas.forEach((r) => {
            r.condicoes.forEach((res) => {
              if(res.cdCondPgto === this.carrinho.getCdCondPgto){
                this.cartSingletonService.atualizarNrParcelas(res.nrParcelas);
              }
            })
          });
          this.cdFormaPgtoSelecionado = this.carrinho.getCdFormaPgto;
          this.cdCondPgtoSelecionado = this.carrinho.getCdCondPgto;
          this.obs = this.carrinho.getObs;
          this.condPadrao();           
          this.setCondPgtoSelecionada(this.cdCondPgtoSelecionado);
          this.calcularParcela(this.cdCondPgtoSelecionado);
          this.getTiposDeEntrega();
          this.getValorFretePadrao();
          this.nomeFantasia = this.ajustarNomeParaInicialEmMaiusculo(this.clienteSingletonService.getClienteEstatico().getClienteFilial.getNmFantasia);
          this.validarItensDoCarrinho().then((resolve) => {
            this.validandoItensDoCarrinho = resolve;
          });
        },
        (error) => {
          this.gravandoPedido = false;
          this.jwtService.validarAcessToken(error);
          this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
        })
      },
      (error) => {
        this.gravandoPedido = false;
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
      });
    } else{
      this.formaCondService.getFormaCondPgto(cliente.getCdCliente)
      .subscribe((response) => {
        if(response.length == 0){
          this.jwtService.logout();
          this.snackBarManager.openSnackBar("O usuário não possui perfil comercial com forma e condição de pagamento","Ok", 3000);
          return
        }
        const formasAgrupadas: FormaCondPgto[] = [];
        response.forEach( forma => {
            const cdForma = forma.cdFormaPgto;
            if(!formasAgrupadas.some(item => item.cdFormaPgto === cdForma)){
                formasAgrupadas.push(forma)
            } else{
                forma.condicoes.forEach(cond => {
                    const formaAtual = formasAgrupadas.find(forma => forma.cdFormaPgto === cdForma)
                    if(!formaAtual.condicoes.some(item => item.cdCondPgto === cond.cdCondPgto)){
                        formasAgrupadas.forEach(forma => {
                            if(forma.cdFormaPgto === cdForma){
                                forma.condicoes.push(cond);
                            }
                        })
                    }
                })
            }
        })
        const formasResponse = formasAgrupadas.filter(forma => forma.cdFormaPgto === this.carrinho.getCdFormaPgto);
        let temForma = false;
        for(const f of formasResponse){
          const forma = f.condicoes;
          for(const condicao of forma){
            if(condicao.cdCondPgto === this.carrinho.getCdCondPgto){
              temForma = true;
            }
          }
        }
        if(!temForma){
          this.cartSingletonService.setFormaECondicaoDePagamento(formasAgrupadas);                                               
          this.clienteSingletonService.atualizaFormaCodPadrao(formasAgrupadas);

        }
        this.formasDePagamento = this.carrinho.getFormaCondPgto.sort((form1, form2) =>
          form1.nmFormaPgto > form2.nmFormaPgto ? 1 : -1
        );
        this.listaCondicoes = new Array<CdPgt>();
        const formas = [];
        this.formasDePagamento.forEach((r) => { 
          if(r.cdFormaPgto === this.carrinho.getCdFormaPgto){
            formas.push(r);
          }
        });
        formas.forEach((r) => {
          r.condicoes.forEach((res) => {
            if(res.cdCondPgto === this.carrinho.getCdCondPgto){              
              this.cartSingletonService.atualizarNrParcelas(res.nrParcelas);
            }
          })
        })
        this.cdFormaPgtoSelecionado = this.carrinho.getCdFormaPgto;
        this.cdCondPgtoSelecionado = this.carrinho.getCdCondPgto;
        this.obs = this.carrinho.getObs;
        this.condPadrao();           
        this.setCondPgtoSelecionada(this.cdCondPgtoSelecionado);
        this.calcularParcela(this.cdCondPgtoSelecionado);
        this.getTiposDeEntrega();
        this.getValorFretePadrao();
        this.nomeFantasia = this.ajustarNomeParaInicialEmMaiusculo(this.clienteSingletonService.getClienteEstatico().getClienteFilial.getNmFantasia);
        this.validarItensDoCarrinho().then((resolve) => {
          this.validandoItensDoCarrinho = resolve;
        });
      },
      (error) => {
        this.gravandoPedido = false;
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
      })
    }
  }
  
  calcularParcela(cdCondPgto) {
    this.setCondAtual(cdCondPgto);
    this.parcelaQuantidade = this.listaCondicoes.find(condPgto => 
      condPgto.cdCondPgto === parseInt(cdCondPgto)).nrParcelas;
      this.valorParcela = this.carrinho.getTotal / this.parcelaQuantidade; 
  }

  limparArray(array: Array<CdPgt>) {
    array.splice(0, array.length);
  }
  
  getTiposDeEntrega() {
    this.tipoEntrega = [];
    this.pedidoService.getTipoEntrega().subscribe(
      (res) => {
        this.pedidoService.getTiposEntregaCarrinho().subscribe((response) => {
          if(response.valor !== ""){
            const tiposEntregaCarrinho = response.valor.trim().split(",").map(Number);
            tiposEntregaCarrinho.forEach((tipoCarrinho) => {
              const tipo = res.find((tipo) => tipoCarrinho === tipo.cdTipoEntrega);
              if (tipo) {
                this.tipoEntrega.push(tipo);
              }
            });
          }else{
            this.tipoEntrega = res;
          }
          const tipoIgual = this.tipoEntrega.find((tipo)=> {return tipo.cdTipoEntrega == this.carrinho.getCdTpEntrega} ) 
          if(tipoIgual){
            this.indiceTpEntrega =  this.carrinho.getCdTpEntrega
          }else{
            this.indiceTpEntrega =  0;
          }
        });
      },
      (error) => {
        console.log(error);
        this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
      }
    );
    this.tEntrega = true;
  }

  getValorFretePadrao(){
    this.cartSingletonService.atualizarFrete(!this.configService.getConfig().getUsaValorDeFretePadraoDoCliente ? 0 :
      JSON.parse(localStorage.getItem("cliente_business")).cliente.vlFretePadrao !== null ?
      JSON.parse(localStorage.getItem("cliente_business")).cliente.vlFretePadrao : 0);
  }  

  continuarComprando() {    
    if(!this.validaCamposCabecalho())
      return;  
    this.cartSingletonService.atualizarDadosCabecalho(this.cdFormaPgtoSelecionado, this.cdCondPgtoSelecionado, this.indiceTpEntrega, this.obs);
    
      this.pedidoManager
        .atualizarPedidoEdicao()
        .then(() => (
          this.router.navigateByUrl('/')  
        ))
        .catch((error: HttpErrorResponse) => {
          this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
        });    
  }

  validaCamposCabecalho(): boolean{    
    if (this.cdFormaPgtoSelecionado == undefined || this.cdFormaPgtoSelecionado == null || this.cdFormaPgtoSelecionado == 0) {
      this.snackBarManager.openSnackBar(
        'Informe uma Forma de Pagamento, por favor',
        'Ok',
        3000
      );
      return false;
    }
    if (this.cdCondPgtoSelecionado == undefined || this.cdCondPgtoSelecionado == null|| this.cdCondPgtoSelecionado == 0) {
      this.snackBarManager.openSnackBar(
        'Informe uma Condição de Pagamento, por favor',
        'Ok',
        3000
      );
      return false;
    }
    if (this.indiceTpEntrega == undefined || this.indiceTpEntrega == null|| this.indiceTpEntrega == 0) {
      this.snackBarManager.openSnackBar(
        'Informe um Tipo de Entrega, por favor',
        'Ok',
        3000
      );
      return false;
    }
    return true;    
  }

  async finalizar() {
    if(!this.validaCamposCabecalho())
      return;
    this.gravandoPedido = true;
    this.cartSingletonService.atualizarDadosCabecalho(this.cdFormaPgtoSelecionado, this.cdCondPgtoSelecionado, this.indiceTpEntrega, this.obs);
    const cliente: Cliente = this.clienteSingletonService.getClienteEstatico();    
    const itemForaDeEstoque = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.find(
      (item) => item.itemPedido.quantidade > item.itemPedido.quantidadeEstoque
    );

    if (itemForaDeEstoque) {
      const confirmacao = await this.openConfirmationDialog(
        'Item(s) no pedido com estoque indisponível no momento! Deseja enviar o pedido dessa forma?'
      );
  
      if (!confirmacao) {
        this.gravandoPedido = false;
        return;
      }
    }
    const pedido = this.converterCarrinhoManager.converterCarrinhoParaPedido(
      this.orcamento,
      this.obs,
      this.notaFiscal
    );
    setTimeout(() => {      
      this.formaCondService.getFormaCondPgto(cliente.getCdCliente).subscribe((response) => {
        if(response.length == 0){
          this.jwtService.logout();
          this.snackBarManager.openSnackBar("O usuário não possui perfil comercial com forma e condição de pagamento","Ok", 3000);
          return
        }
        const formasAgrupadas: FormaCondPgto[] = [];
        response.forEach( forma => {
            const cdForma = forma.cdFormaPgto;
            if(!formasAgrupadas.some(item => item.cdFormaPgto === cdForma)){
                formasAgrupadas.push(forma)
            } else{
                forma.condicoes.forEach(cond => {
                    const formaAtual = formasAgrupadas.find(forma => forma.cdFormaPgto === cdForma)
                    if(!formaAtual.condicoes.some(item => item.cdCondPgto === cond.cdCondPgto)){
                        formasAgrupadas.forEach(forma => {
                            if(forma.cdFormaPgto === cdForma){
                                forma.condicoes.push(cond);
                            }
                        })
                    }
                })
            }
        })
        const formasResponse = formasAgrupadas.filter(forma => forma.cdFormaPgto === this.carrinho.getCdFormaPgto);
        let temForma = false;
        for(const f of formasResponse){
          const forma = f.condicoes;
          for(const condicao of forma){
            if(condicao.cdCondPgto === this.carrinho.getCdCondPgto){
              temForma = true;
            }
          }
        }
        if(!temForma){
          this.cartSingletonService.setFormaECondicaoDePagamento(formasAgrupadas);                                        
          this.clienteSingletonService.atualizaFormaCodPadrao(formasAgrupadas);                           
          this.formasDePagamento = this.carrinho.getFormaCondPgto.sort((form1, form2) =>
            form1.nmFormaPgto > form2.nmFormaPgto ? 1 : -1
          );
          this.cdCondPgtoSelecionado = this.carrinho.getCdCondPgto;
          this.cdFormaPgtoSelecionado = this.carrinho.getCdFormaPgto;
          this.indiceTpEntrega = this.carrinho.getCdTpEntrega;
          this.listarFormasDePagamento(this.cdFormaPgtoSelecionado);
          this.gravandoPedido = false;
          this.snackBarManager.openSnackBar("O usuário não possui a forma e condição de pagamento, por favor escolha outra forma e condição","Ok", 3000);
          return
        }
        this.pedidoService.gravarPedido(pedido).subscribe(
          (res) => {
            if (res['success'] == true) {
              this.snackBarManager.openSnackBar(
                'O pedido ' + res['nrPedido'] + ' foi salvo.',
                'Ok',
                2000
              );    
            const cliente  = this.clienteSingletonService.getClienteEstatico();  
            this.cartSingletonService.novoCarrinho(cliente.getCdFormaPadrao, cliente.getCdCondPadrao, cliente.getCdTipoEntrega,  '');
            this.formaCondService.getFormaCondPgto(cliente.getCdCliente)
            .subscribe((response) => {
              if(response.length == 0){
                this.jwtService.logout();
                this.snackBarManager.openSnackBar("O usuário não possui perfil comercial com forma e condição de pagamento","Ok", 5000);
                return
              }
              const formasAgrupadas: FormaCondPgto[] = [];
              response.forEach( forma => {
                  const cdForma = forma.cdFormaPgto;
                  if(!formasAgrupadas.some(item => item.cdFormaPgto === cdForma)){
                      formasAgrupadas.push(forma)
                  } else{
                      forma.condicoes.forEach(cond => {
                          const formaAtual = formasAgrupadas.find(forma => forma.cdFormaPgto === cdForma)
                          if(!formaAtual.condicoes.some(item => item.cdCondPgto === cond.cdCondPgto)){
                              formasAgrupadas.forEach(forma => {
                                  if(forma.cdFormaPgto === cdForma){
                                      forma.condicoes.push(cond);
                                  }
                              })
                          }
                      })
                  }
              })
              this.cartSingletonService.setFormaECondicaoDePagamento(formasAgrupadas);                                        
              this.clienteSingletonService.atualizaFormaCodPadrao(formasAgrupadas);                       
              this.pedidoManager.promiseNovoPedidoVenda().then(
                () => this.router.navigateByUrl('/home'))
              .catch((error) => {
                this.gravandoPedido = false;
                this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
              });
            },
            (error) => {
              this.gravandoPedido = false;
              this.jwtService.validarAcessToken(error);
              this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
            })
            
          } else {
            this.gravandoPedido = false;
            this.snackBarManager.openSnackBar(
              'Erro ao finalizar pedido. ' + res['mensagem'],
              'Ok',
              2000
            );
          }
          this.router.navigateByUrl('/home');
          this.gravandoPedido = false;
        },
        (error) => {
          this.gravandoPedido = false;
          this.jwtService.validarAcessToken(error);
          this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
        }
      );
      },
      (error) => {
        this.gravandoPedido = false;
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, "Ok", 3000);
      })    
    }, 2500);
  }

  listarFormasDePagamento(value) {  
    this.limparArray(this.listaCondicoes);
    this.formasDePagamento.forEach((formas) => {
      if (value == formas.cdFormaPgto) {
        formas.condicoes.forEach((condPgto) => {
          this.condicao = new CdPgt();
          this.condicao.cdFormaPgto = formas.cdFormaPgto;
          this.condicao.cdCondPgto = condPgto.cdCondPgto;
          this.condicao.nmCondPgto = condPgto.nmCondPgto;
          this.condicao.hierarquia = condPgto.hierarquia;
          this.condicao.diaDoMes = condPgto.diaDoMes.toString();
          this.condicao.diaSemana = condPgto.diaSemana.toString();
          this.condicao.modalidade = condPgto.modalidade;
          this.condicao.nrParcelas = condPgto.nrParcelas;
          this.condicao.vlMinimoParcela = condPgto.vlMinimoParcela.toString();
          this.condicao.prazoInicial = condPgto.prazoInicial;
          this.condicao.nrDiasPrazoMaximo = condPgto.nrDiasPrazoMaximo;
          this.parcela = formas.classificacao.toUpperCase() == 'R';
          if (this.parcela)
            this.parcelaQuantidade = Number.parseInt(
              formas.condicoes[0].nmCondPgto.replace(/\D/g, '')
            );
          this.listaCondicoes.push(this.condicao);
        });
        this.setFormaPgtoSelecionada(Number.parseInt(value));
        this.setCondAtual(this.listaCondicoes[0].cdCondPgto);      
      }
    });    
    this.calcularParcela(this.cdCondPgtoSelecionado);    
  }

  condPadrao() {
    this.listaCondicoes = new Array<CdPgt>();
    this.formasDePagamento.forEach((formas) => {
      const possuiForma = this.carrinho.getCdFormaPgto == formas.cdFormaPgto;
      if (possuiForma) {
        formas.condicoes.forEach((condPgto) => {
          this.condicao = new CdPgt();
          this.condicao.cdFormaPgto = formas.cdFormaPgto;
          this.condicao.cdCondPgto = condPgto.cdCondPgto;
          this.condicao.nmCondPgto = condPgto.nmCondPgto;
          this.condicao.hierarquia = condPgto.hierarquia;
          this.condicao.diaDoMes = condPgto.diaDoMes.toString();
          this.condicao.diaSemana = condPgto.diaSemana.toString();
          this.condicao.modalidade = condPgto.modalidade;
          this.condicao.nrParcelas = condPgto.nrParcelas;
          this.condicao.vlMinimoParcela = condPgto.vlMinimoParcela.toString();
          this.condicao.prazoInicial = condPgto.prazoInicial;
          this.condicao.nrDiasPrazoMaximo = condPgto.nrDiasPrazoMaximo;
          this.listaCondicoes.push(this.condicao);
        });
      }
    });
  }

  setTpEntrega(value) {
    this.indiceTpEntrega = value;
  }

  setFormaPgtoSelecionada(cdFormaPgto) {
    this.cdFormaPgtoSelecionado = parseInt(cdFormaPgto);
  }  

  setCondPgtoSelecionada(cdCondPgto) {
    this.cdCondPgtoSelecionado = parseInt(cdCondPgto);
    this.parcela = this.formasDePagamento.find(formaPgto =>       
      formaPgto.cdFormaPgto === this.cdFormaPgtoSelecionado
      ).classificacao === 'R';
    this.calcularParcela(this.cdCondPgtoSelecionado); 
  }    

  getFormaPadrao(): number {    
    const formaPagto = this.cartSingletonService.getFormaECondicaoDePagamento();
    return formaPagto.cdFormaPgto;
  }

  setCondAtual(value) {
    this.cdCondPgtoSelecionado = value;
  }  

  getCondPgtoSelecionada(): number {    
    return  this.cdCondPgtoSelecionado;
  }   

  validarItensDoCarrinho(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.carrinho.getItensCarrinho.forEach((item) => {
          this.itemManager.getValor(item);
        });
        resolve(false);
      }, 1000);
    });
  }

  ajustarNomeParaInicialEmMaiusculo(string: string){
    const nome = string.toLowerCase().split(' ');
    for(let i = 0; i< nome.length; i++){
      const palavra = nome[i];
      const nomeCorrigido = palavra.charAt(0).toUpperCase() + palavra.slice(1);
      nome[i] = nomeCorrigido;
    }
    return nome.join(' ');
  }

  async openConfirmationDialog(mensagem: string): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogCustomizadoComponent, {
        width: '300px',
        height: '200px',
        panelClass: 'custom-dialog',
        data: { labelMensagem: mensagem, labelConfirmacao: 'Sim', labelNegacao: 'Não' }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
}
