import { Component, Input, OnInit } from '@angular/core';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemSimplesView } from 'src/app/persistences/item-simples-view';
import { ConfigService } from 'src/app/services/config.service';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';

@Component({
  selector: 'app-lista-itens',
  templateUrl: './lista-itens.component.html',
  styleUrls: ['./lista-itens.component.css']
})
export class ListaItensComponent implements OnInit {

  @Input()
  public listaItens: ItemSimplesView[];
  @Input()
  public ids: Array<number>;
  @Input()
  public page: number;
  @Input()
  public pagesize: number;
  colunas: number;
  public grade = true;

  constructor(
    private cartSingletonService: CartSingletonService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.colunas = (window.innerWidth <= 700) ? 1 : 4;
  }

  getItemDetalhado(item: ItemSimplesView): ItemDetalhado {
    return item.itemDetalhado == undefined ? new ItemDetalhado() : item.itemDetalhado;
  }

  getQtAtual(item: ItemDetalhado): boolean {
    return item.itemDetalhes == undefined ? false : item.itemDetalhes.qtAtual > 0;
  }

  getSimilares(item: ItemDetalhado): boolean {
    return item.itemDetalhes == undefined ? false : item.itemDetalhes.temSimilares == true;
  }

  getFichaTecnica(item: ItemDetalhado): string {
    return item.itemDetalhes == undefined ? "" : item.itemDetalhes.fichaTecnica;
  }

  getExibeEstoque(): boolean {
    return this.configService.getConfig().getExibeEstoque;
  }

  getExibeDesconto(): boolean {
    return this.configService.getConfig().getExibeDesconto;
  }

  getTotal(id: number): number {
    const carrinho = this.cartSingletonService.getCarrinhoEstatico();
    const item: ItemDetalhado = carrinho.getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem == id);
    if (carrinho.getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem == id) != undefined) {
      return item.itemPedido.total;
    }
    return 0;
  }

  getQuantidade(id: number): number {
    const item: ItemDetalhado = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.find(itemCart => itemCart.itemDetalhes.idItem == id);
    if (item != undefined) {
      return item.itemPedido.quantidade;
    }
    return 0;

  }

  onResize(event) {
    const width = event.target.innerWidth;
    if (!this.grade) {
      this.colunas = 1;
    } else {


      if (width >= 1080) {
        this.colunas = 4;
      }
      if (width < 1080 && width > 800) {
        this.colunas = 3;
      }
      if (width < 800 && width > 700) {
        this.colunas = 2;
      }
      if (width < 700) {
        this.colunas = 1;
      }
    }
  }

}
