import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Funcionario } from 'src/app/persistences/funcionario';
import { ConfigService } from 'src/app/services/config.service';
import { FuncionarioManager } from './../../../managers/funcionario-manager';
import { SnackbarManager } from './../../../managers/snackbar-manager';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  funcionario: Funcionario = new Funcionario;
  formGroup: FormGroup;
  public temaPadrao: string;

  constructor(
    private funcionarioManager: FuncionarioManager,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private snackBarManager: SnackbarManager
  ) {
    this.formGroup = this.formBuilder.group({
            nmUsuario: [null],
            cnpjCpf: [null]
    });
  }

  ngOnInit() {
    this.temaPadrao = this.configService.getConfig().getTemaPadrao;     
    this.funcionarioManager.getFuncionario(Number.parseInt(localStorage.getItem('cd_usuario_business'))).then(response => {
        this.funcionario = response;
        this.formGroup.get('nmUsuario').setValue(this.funcionario.nmFuncionario);
        this.formGroup.get('cnpjCpf').setValue(this.funcionario.cnpjCpf);
    });
  }

  atualizarDadosDoUsuario() {
    this.snackBarManager.openSnackBar("Função em desenvolvimento","Ok",3000);
  }
}
