<div [ngClass]="this.temaPadrao" #scrollbar class="modal">
	<mat-toolbar class="toolbar-detalhado" color="primary">
		<mat-toolbar-row>
			<button mat-icon-button (click)="fechar()">
				<mat-icon>keyboard_backspace</mat-icon>
			</button>
			<span class="titulo">Pedido Detalhado </span>
			<mat-chip-listbox class="mat-chip-list-wrapper" #chipList class="trilha">
				<mat-chip-option color="accent" selected>
					<b *ngIf="!isPesquisando">{{ pedido.nrPedido }}</b>
				</mat-chip-option>
			</mat-chip-listbox>
		</mat-toolbar-row>
	</mat-toolbar>
	<div class="conteudo">
		<div *ngIf="!isPesquisando" class="cabecalho">
			<p class="numero-pedido">Número Pedido: {{pedido.nrPedido}}</p>
			<p style="font-weight: bold; margin: 5px 0px 5px 10px;">Situação: {{pedido.nmSituacao}}</p>
			<p style="font-weight: bold; margin: 5px 0px 5px 10px;">Data Emissão: {{dataFormatada}}</p>
		</div>
		<div class="alinhar-itens">
			<mat-spinner class="spinner-align" mode="indeterminate" *ngIf="isPesquisando">
			</mat-spinner>
		</div>
		<table class="tabela tabela-responsive" *ngIf="!isPesquisando">
			<thead>
				<tr>
					<th>Código</th>
					<th>Descrição</th>
					<th>Quantidade</th>
					<th>Preço Liquído</th>
					<th *ngIf="pedido.cdSituacao !== 9"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of pedido.itens">
					<td>{{item.cdItem}}</td>
					<td>{{item.nmItem}}</td>
					<td>{{item.qtVenda}}</td>
					<td>{{item.vlLiquido.toLocaleString('pt-br', {style: "currency", currency: "BRL"})}}</td>
					<td *ngIf="pedido.cdSituacao !== 9">
						<button (click)="openDialogDetalhes(item)" matTooltip="Comprar Novamente" color="primary" style="transform: scale(0.8);" mat-mini-fab>
							<mat-icon>touch_app</mat-icon>
						</button>
					</td> 
				</tr>
			</tbody>
		</table>
	</div>
</div>