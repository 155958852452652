import { Component, Output, EventEmitter, Input, HostListener, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-controlbar',
  templateUrl: './controlbar.component.html',
  styleUrls: ['./controlbar.component.css']
})
export class ControlBarComponent implements OnInit {

  statusFiltro: boolean;
  layoutPadrao: number;
  toolTipFiltro = 'FECHAR FILTRO';
  toolTipLayout = 'CARDS';
  isLayoutMobile: boolean;

  @Output()
  abrirFiltroEmitter = new EventEmitter<boolean>();
  @Output()
  ordenacaoEmitter = new EventEmitter<string>();
  @Input()
  ordenacaoInput: string;
  constructor(
    private configService: ConfigService
  )
  {}
  
  ngOnInit(): void {
    this.isLayoutMobile = window.innerWidth <= 850? true: false;
    if(!this.isLayoutMobile && this.configService.getConfig().getIsLayoutCards === true){
      this.listaOuCards(1);
    }else{
      this.listaOuCards(2);
    }
  }

  abrirFiltro(statusFiltro: boolean) {
    this.statusFiltro = !statusFiltro;
    this.toolTipFiltro = (this.statusFiltro ? 'ABRIR FILTRO' : 'FECHAR FILTRO');
    this.abrirFiltroEmitter.emit(this.statusFiltro);
  }

  listaOuCards(layoutPadrao: number) {
    if (layoutPadrao === 1) {
      this.layoutPadrao = 2;
      this.toolTipLayout = 'LISTA';
      this.configService.configuraLayout(true);
    } else {
      this.layoutPadrao = 1;
      this.toolTipLayout = 'CARDS';
      this.configService.configuraLayout(false);
    }
  }

  getMatIcon(): string {
    return (this.layoutPadrao === 1 || this.layoutPadrao === undefined) ? "border_all" : "list_alt"
  }

  orderBy(order: string) {
    this.ordenacaoEmitter.emit(order);
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (window.innerWidth <= 850) {
      this.layoutPadrao = 1 ;
      this.listaOuCards(this.layoutPadrao)
      this.isLayoutMobile = true;
      this.toolTipLayout = 'LISTA'; 

    } else {
      this.configService.getConfig().getIsLayoutCards ? this.layoutPadrao = 2 : this.layoutPadrao = 1;
      this.layoutPadrao === 1 ? this.toolTipLayout = 'CARDS' : this.toolTipLayout = 'LISTA';  
      this.isLayoutMobile = false
    }
  }

}
