import { ItemDetalhado } from "./item-detalhado";
import { ItemDetalhes } from "./item-detalhes";
import { ItemPedido } from "./item-pedido";

export class ItemSimplesView {
	public idItem: number;
	public cdItem: string;
	public cdFabricante: string;
	public nmItem: string;
	public nmMarca: string;
	public temEstoque: boolean;
	public preco = 0;
	public pcDesconto = 0;
	public pcMarkup = 0;
	public vl_unitario_liquido = 0;
	public idTabelaPrecoProduto = 0;	
	public flVendeFracionado?: string;
	public imagem: string;
	public carregouDetalhes = false;
	public flPromocao = false;
	public itemDetalhado: ItemDetalhado;
	public temSimilares: boolean;
	public defaultIndex: number;
    public compativel: boolean;
    public unMedVenda: string;
	
	constructor() {
		this.itemDetalhado = new ItemDetalhado();
		this.itemDetalhado.itemDetalhes = new ItemDetalhes();
		this.itemDetalhado.itemPedido = new ItemPedido();
	}
}
