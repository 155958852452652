import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormaCondPgto } from '../persistences/forma-cond-pgto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class FormaCondService {

  constructor(private configService: ConfigService,
		private httpClient: HttpClient) { }

  getFormaCondPgto(cdCliente: number): Observable<Array<FormaCondPgto>> {
		let httpParams = new HttpParams();
		httpParams = httpParams
			.append('cdCliente', cdCliente.toString())
		return this.httpClient.get<Array<FormaCondPgto>>(
			this.configService.getEndereco() + '/FormaCondPgto/ValidasWebByCliente',
			{
				headers: this.configService.getHeaders(),
				params: httpParams
			}
		)
	}
}
