import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { Md5 } from 'ts-md5';
import { JwtService } from './../../jwt.service';
import { IDadosSenha } from '../../interfaces/ialterar-senha';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-alterar-senha',
    templateUrl: './alterar-senha.component.html',
    styleUrls: ['./alterar-senha.component.scss'],
})
export class AlterarSenhaComponent implements OnInit {
    public hideSenhaAtual = true;
    public hideSenhaNova = true;
    public hideSenhaConfirmacao = true;
    public hideToken = true;
    public formGroup: UntypedFormGroup;
    public formGroupBearer: UntypedFormGroup;
    public isntMinimo = false;
    public isntMaximo = false;
    public infoSenha = [];
    public isSenhaNovaValid: boolean;
    public isntEqualSenha: boolean;
    public isCliente = parseInt(localStorage.getItem("cd_usuario_business")) === 0 ? true : false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private snackBarManager: SnackbarManager,
        private jwtService: JwtService
    ) {}

    public ngOnInit() {
        this.creativeForm();
        if(!this.isCliente){
            this.jwtService.getBearerToken().subscribe((r: any) => {
                this.formGroupBearer.get('token').setValue(r.token);
            },
            (error) => {
                this.jwtService.validarAcessToken(error);
                this.snackBarManager.openSnackBar(error.error,"Ok",3000);
            })
        }
       
    }

    public creativeForm(): void {
        this.formGroup = this.formBuilder.group({
            senhaAtual: new UntypedFormControl('', []),
            senhaNova: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*[A-Z])(?=.*[!#@$%&?])(?=.*[0-9])(?=.*[a-z]).{6,15}$")]),
            confirmacaoSenhaNova: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*[A-Z])(?=.*[!#@$%&?])(?=.*[0-9])(?=.*[a-z]).{6,15}$")]),
        });
        this.formGroupBearer = this.formBuilder.group({
            token: new UntypedFormControl('', [])
        });
    }

    onInput(){
        const senha = this.formGroup.get('senhaNova').value;
        this.infoSenha = [];
        this.formGroup.get('senhaNova').valid ? this.isSenhaNovaValid = true : this.isSenhaNovaValid = false
        senha.length < 6 ? this.isntMinimo = true : this.isntMinimo = false
        senha.length > 15 ? this.isntMaximo = true : this.isntMaximo = false
        if(!this.contemLetraMaiuscula(senha)){
            this.infoSenha.push('letra maiúscula');
        }
        if(!this.contemLetraMinuscula(senha)){
            this.infoSenha.push('letra minúscula');
        }
        if(!this.contemNumero(senha)){
            this.infoSenha.push('numero');
        }
        if(!this.contemCaracteresEspeciais(senha)){
            this.infoSenha.push('caracteres especiais(!, #, @, $, %, &, ?)');
        }
    }

    onInputConfirmacao(){
        this.formGroup.get('senhaNova').value !== this.formGroup.get('confirmacaoSenhaNova').value ? this.isntEqualSenha = true : this.isntEqualSenha = false
    }

    public alterarSenha(): void {
        if(!this.formGroup.valid){
            const inputLength = this.formGroup.get('senhaNova').value.length;
            const input = this.formGroup.get('senhaNova').value;
            if(this.formGroup.get('senhaAtual').value.length === 0){
                return this.snackBarManager.openSnackBar('Por favor digite a senha atual', 'Fechar', 3000);
            }
            if (this.formGroup.get('senhaNova').value !== this.formGroup.get('confirmacaoSenhaNova').value) {
                return this.snackBarManager.openSnackBar('A nova senha e sua confirmação devem ser iguais', 'Fechar', 3000);
            }else if( inputLength < 6){
                return this.snackBarManager.openSnackBar("A nova senha deve ter ao menos 6 caracteres", "Fechar", 3000)
            } else if( inputLength > 15){
                return this.snackBarManager.openSnackBar("A nova senha deve no máximo 15 caracteres", "Fechar", 3000)
            }else if(!this.contemLetraMaiuscula(input)){
                return this.snackBarManager.openSnackBar("A nova senha deve ter uma letra maiúscula", "Fechar", 3000)
            }else if(!this.contemLetraMinuscula(input)){
                return this.snackBarManager.openSnackBar("A nova senha deve ter uma letra minúscula", "Fechar", 3000)
            }else if(!this.contemNumero(input)){
                return this.snackBarManager.openSnackBar("A nova senha deve ter um número", "Fechar", 3000)
            } else{
                return this.snackBarManager.openSnackBar("A nova senha deve conter ao menos um caractere especial(!, #, @, $, %, &, ?), um número, uma letra maiúscula e uma letra minúscula", "Fechar", 3000);
            }
        } else {
            const dadosSenha = this.formGroup.getRawValue() as IDadosSenha;
            for(const dados in dadosSenha){
                dadosSenha[dados] = Md5.hashStr(dadosSenha[dados]);
            }
            this.jwtService.alterarSenha(dadosSenha).subscribe(
                (resposta) => {
                    if(resposta.status.status){
                        this.snackBarManager.openSnackBar(resposta.status.mensagem, 'Fechar', 3000);
                        this.jwtService.logout();
                        this.jwtService.checkLogin();
                    } else {
                        this.snackBarManager.openSnackBar(resposta.status.mensagem, 'Fechar', 3000);
                    }
                },
                (error) => {
                    this.jwtService.validarAcessToken(error);
                    this.snackBarManager.openSnackBar(error.error,"Ok",3000);
                }
            );
        }
    }

    contemLetraMaiuscula(senha: string){
        for( const char of senha ){
            if(char === char.toUpperCase() && char !== char.toLowerCase()){
                return true;
            }
        }
        return false;
    }

    contemLetraMinuscula(senha: string){
        for( const char of senha ){
            if(char === char.toLowerCase() && char !== char.toUpperCase()){
                return true;
            }
        }
        return false;
    }

    contemNumero(senha: string){
        for( const char of senha ){
            if(!isNaN(Number(char))){
                return true;
            }
        }
        return false;
    }

    contemCaracteresEspeciais(senha: string){
        const caracteresEspeciais = ['!','@', '#', '$', '%', '&', '?']
        for( const char of caracteresEspeciais ){
            if(senha.includes(char)){
                return true;
            }
        }
        return false;
    }

    generateBearerToken(){
        this.jwtService.generateBearerToken().subscribe((r:any) => {
            this.formGroupBearer.get('token').setValue(r.token);
        },
        (error) => {
            this.jwtService.validarAcessToken(error);
            this.snackBarManager.openSnackBar(error.error,"Ok",3000);
        })
    }
}
