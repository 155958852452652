import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { JwtService } from '../jwt.service';
import { AcessoAcao } from './../persistences/acesso-acao';
import { IAcessoAcaoItem } from '../interfaces/iacesso-acao-item';

@Injectable()
export class AcessoAcaoService {

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private jwtService: JwtService,
    ) {
    }

    getIdsConsultaAcessoAcao(acessoAcao: AcessoAcao) {
        this.jwtService.validarAcessToken();
        return this.httpClient.post<IAcessoAcaoItem[]>(
            this.configService.getEndereco() + '/AcessoAcao/getAcessoAcaoPost',
            JSON.stringify(acessoAcao),
            { headers: this.configService.getHeaders() }
        )
    }
}
