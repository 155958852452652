<form [formGroup]="formPesquisa" class="search-form">
  <div class="form-row">
    <mat-form-field class="flex-item">
      <mat-label>Origem pedido</mat-label>
      <mat-select [(ngModel)]="origemDoPedidoSelecionada" formControlName="origemPedido">
        <mat-option class="mat-options" *ngFor="let origem of origensDoPedido" [value]="origem.value">
          {{ origem.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="search-component custom-consulta">
      <app-consulta-customizada
        [labelDaDescricao]="'Situação'"
        [identificador]="'cntSituacaoPedidoVenda'"
        [campoFiltro]="['CD_SITUACAO','NM_SITUACAO']"
        [lookupExibicao]="['CD_SITUACAO', 'NM_SITUACAO']"
        [complementoConsulta]="''"
        (quantidadeItemConsultaEmitter)="null"
        (itemCosulta)="receberItemConsulta($event)"
        [campoRequerido]="false"
        class="larger-consulta"        
      ></app-consulta-customizada>
    </div>

    <mat-form-field class="flex-item">
      <mat-label>Data inicial</mat-label>
      <input matInput [matDatepicker]="dataInicial" formControlName="dataInicial" />
      <mat-datepicker-toggle matSuffix [for]="dataInicial"></mat-datepicker-toggle>
      <mat-datepicker class="mat-calendar" #dataInicial></mat-datepicker>
      <mat-error *ngIf="formPesquisa.get('dataInicial').invalid">Data inválida</mat-error>
    </mat-form-field>

    <mat-form-field class="flex-item">
      <mat-label>Data final</mat-label>
      <input matInput [matDatepicker]="dataFinal" formControlName="dataFinal" />
      <mat-datepicker-toggle matSuffix [for]="dataFinal"></mat-datepicker-toggle>
      <mat-datepicker #dataFinal></mat-datepicker>
      <mat-error *ngIf="formPesquisa.get('dataFinal').invalid">Data inválida</mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      (click)="confirmar()"
      [disabled]="formPesquisa.invalid"
      class="flex-item custom-button"
    >
      OK
    </button>
  </div>
</form>
