import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageSingletonService } from "../singletons/local-storage-singleton.service";
import { Config } from "../persistences/config";

@Injectable({
  providedIn: 'root',
})
export class ConfigAux {
  private appConfig;

  constructor(private http: HttpClient,private localStorageSingletonService: LocalStorageSingletonService) {}

  async loadAppConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('/assets/config/config.json').subscribe((data) => {
          this.appConfig = data;
          const config = new Config();
          config._setInitialConfig(this.appConfig);
          this.localStorageSingletonService.atualizarConfig(config);
          resolve(this.appConfig);
        },
        (error) => {
          reject("Erro ao carregar configurações. " + error.error + ' - ' + error);
        }
      );
    });
  }
}
