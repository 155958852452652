export class InfoVeiculo {
	public montadora: string;
	public modelo: string;
	public anoInicio: string;
	public anoFim: string;
	public complemento: string;

	constructor(json?: any) {
        if (json) {
            // Exemplo de inicialização direta
            this.montadora = json.montadora;
            this.modelo = json.modelo;
            this.anoInicio = json.anoInicio;         
			this.anoFim = json.anoFim;         
			this.complemento = json.complemento;         
        } 
    }
}
