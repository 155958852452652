import { Component } from '@angular/core';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';

@Component({
  selector: 'app-carrinho-menu',
  templateUrl: './carrinho-menu.component.html',
  styleUrls: ['./carrinho-menu.component.css']
})
export class CarrinhoMenuComponent  {

  constructor(
    private cartSingletonService: CartSingletonService
  ) {
  }
  
  getQtdItensNoCarrinho(): number {
    return this.cartSingletonService.getQtdItensNoCarrinho();
  }
  
}
 