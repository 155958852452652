import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/jwt.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

	@Input()
	public nomeUsuario: string;
	@Input()
	public isCliente: boolean;	
    public temaPadrao: string;
	public isUsuario = false;
	public isMobileMenu = false;
	public routerLink: string;

	constructor(
		public configService: ConfigService,
        private router: Router,
		private jwtService: JwtService,
	) {	}

	ngOnInit() {
		this.temaPadrao = this.configService.getConfig().getTemaPadrao;
		this.isCliente = parseInt(localStorage.getItem('cd_usuario_business')) === 0;
		this.isUsuario = parseInt(localStorage.getItem('cd_usuario_business')) !== 0;
		this.configurarLayout();
		this.configurarRotaMinhaConta();
	}

	logout () {
		this.jwtService.logout();
		this.router.navigate(['/login']);
	}

	configurarRotaMinhaConta() {
		this.routerLink = !this.isMobileMenu ? "/minha-conta" : 
        this.isCliente ? "/minha-conta/perfil-cliente" : "/minha-conta/perfil-usuario";
	}

	configurarLayout() {
		this.isMobileMenu = window.innerWidth <= 768;
	}

	@HostListener('window:resize', ['$event'])
	onResize(){
        this.configurarLayout();
		this.configurarRotaMinhaConta();
	}	
}
