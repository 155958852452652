<div class="login-container">
	<mat-card class="transparent-card">
		<mat-card-content class="mat-card-conteudo">
			<div class="label label-boa-vindas">
				<mat-label>Olá, seja bem-vindo(a)!</mat-label>
			</div>
			<div>
				<img mat-card-image class="sem-imagem" src="assets/images/login.png">
				<div class="label label-info">
					<mat-label>Insira seus dados ou solicite acesso.</mat-label>
				</div>
				<form>
					<div class="input-container">
						<mat-form-field appearance="fill">
							<mat-label>Usuario</mat-label>
							<input class="input" matInput #usuario [(ngModel)]="this.cpf_cnpj"
								[ngModelOptions]="{standalone: true}" (ngModelChange)="this.getCpfCnpjMask()">
							<mat-icon matSuffix>person</mat-icon>
						</mat-form-field>
					</div>
					<div class="input-container">
						<mat-form-field appearance="fill">
							<mat-label>Senha</mat-label>
							<input class="input" matInput type="password" #senha
								(keyup.enter)="login(usuario.value, senha.value)">
							<mat-icon matSuffix>lock</mat-icon>
						</mat-form-field>
					</div>
				</form>
			</div>
			<mat-card-actions>
				<button class="button-login" mat-flat-button color="primary" [disabled]="btnLoginDesabilado"
					(click)="login(usuario.value, senha.value)">
					Login
				</button>
			</mat-card-actions>
		</mat-card-content>
	</mat-card>
</div>