import { EventEmitter, Injectable, Output } from '@angular/core';
import { PesquisaService } from '../services/pesquisa.service';
import { PesquisaManager } from './pesquisa-manager';

@Injectable({
    providedIn: 'root',
})
export class FiltroLateralManager {

    isFiltroAberto: boolean;
    @Output()
    isFiltroAbertoEmitter = new EventEmitter<boolean>()

    constructor(
        private pesquisaService: PesquisaService,
        private pesquisaManager: PesquisaManager
    ) {

     }

     iniciarMap(obj: Map<string, string[]>) {
        obj.set('marca', new Array<string>());
        obj.set('linha', new Array<string>());
        obj.set('grupo', new Array<string>());
        obj.set('subgrupo', new Array<string>());
        obj.set('cadastrosRecentes', new Array<string>());
        return obj;
      }

    preencherListaDeFiltros(idsPesquisados: number[]): Promise<number[]> {
        return new Promise((resolve, reject) => {
            try {
                let idsOrdenados: number[] = [];
                this.pesquisaService
                    .carregarFiltrosByIds(idsPesquisados)
                    .subscribe((retornoItems) => {
                        idsOrdenados = this.reordenarIds(idsPesquisados, this.pesquisaManager.listaOrdenada(retornoItems));
                        resolve(idsOrdenados);
                    })
            } catch (error) {
                console.log(error.error);
                reject(0);
            }
        })
    }

    preencherListaDeFiltrosDetalhaCompleta(idsPesquisados: number[]): Promise<number[]> {
        return new Promise((resolve, reject) => {
            try {
                let idsOrdenados: number[] = [];
                idsOrdenados = this.reordenarIds(idsPesquisados, this.pesquisaManager.listaOrdenada(this.pesquisaService.getProdutosPesquisados()));
                resolve(idsOrdenados);            
            } catch (error) {
                console.log(error.error);
                reject(0);
            }
        })        
    }    

    reordenarIds(IdsPesquisados: number[], IdsRetornados: number[]): number[]{
        const idsOrdenados = [...IdsRetornados];
 
        idsOrdenados.sort((a, b) => {
         const indexA = IdsPesquisados.indexOf(a);
         const IndexB = IdsPesquisados.indexOf(b);
         return indexA - IndexB;
        });
        
     return idsOrdenados;
    }

    setIsFiltroAberto(boolean){
        this.isFiltroAberto = boolean;
        this.isFiltroAbertoEmitter.emit(this.isFiltroAberto);
      }

    getIsFiltroAberto(){
        return this.isFiltroAberto;
    }
}