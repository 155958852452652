import { Injectable } from '@angular/core';
import { Cliente } from '../persistences/cliente';
import { ClienteFilial } from '../persistences/cliente-filial';
import { LocalStorageSingletonService } from './local-storage-singleton.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormaCondPgto } from '../persistences/forma-cond-pgto';

@Injectable({
  providedIn: 'root'
})
export class ClienteSingletonService {

	private clienteSubject = new BehaviorSubject<Cliente>(this.getCliente());

	constructor(private localStorageSingletonService: LocalStorageSingletonService) {
		window.addEventListener('storage', this.syncClienteChanges.bind(this));
	}
	
	private syncClienteChanges(event: StorageEvent) {	
		if (event.key === 'cliente') {
			/* Recurso Multi Abas */
			const cliente = this.localStorageSingletonService.buscarCliente();
			this.setCliente(cliente);
		}
	}
	
	novoCliente() {
		const cliente: Cliente =  new Cliente();
		this.setCliente(cliente);
	}

	getClienteObservable(): Observable<Cliente> {
		return this.clienteSubject.asObservable();
	}

	getClienteEstatico(): Cliente {
		return this.getCliente();
	}
	
	/*Somente buscar o Cliente implementando o getClienteObservable para garantir atualização */
	private getCliente(): Cliente {
		const cliente : Cliente = this.localStorageSingletonService.buscarCliente();    
		if(cliente == null){
			return new Cliente();
		}
		return cliente; 
	}
  
	/*Somente o ClienteSingletonService pode atualizar o carrinho, nunca remover private */
	private setCliente(cliente : Cliente) {
		this.localStorageSingletonService.atualizarCliente(cliente);
		this.clienteSubject.next(cliente);
	}  

	atualizarCliente(cliente: Cliente): void {
		this.setCliente(cliente);
	}

	atualizaFormaCodPadrao(formasCond: FormaCondPgto[]): void{
		let formaPadrao = formasCond.find(item => item.flPadrao);
		if  (formaPadrao == undefined) {
			formaPadrao = formasCond[0];
		}
		let condPadrao = formaPadrao.condicoes.find(item => item.flPadrao);
		if (condPadrao == undefined){
			condPadrao = formaPadrao.condicoes[0];
		}
		const cliente = this.getCliente();
		cliente._setFormCondPadrao(formaPadrao.cdFormaPgto, condPadrao.cdCondPgto);
		this.atualizarCliente(cliente);
	}

	atualizarClienteFilial(clienteFilial: ClienteFilial):void {
		const cliente: Cliente = this.getCliente();
		cliente._setClienteFilial(clienteFilial);
		this.setCliente(cliente);
	}

	atualizarImgClienteFilial(imgStr: string): void {
		const cliente: Cliente = this.getCliente();
		cliente.getClienteFilial._setImgStr(imgStr);
		this.atualizarCliente(cliente);
	}

	atualizarPcMarkup(pcMarkup: number): void {
		const cliente: Cliente = this.getCliente();
		cliente._setPcMarkup(pcMarkup);
		this.atualizarCliente(cliente);
	}

	atualizarCdCliente(cdCliente: number): void {
		const cliente: Cliente = this.getCliente();
		cliente._setCdCliente(cdCliente);
		this.atualizarCliente(cliente);
	}

}
