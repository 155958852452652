<div class="semelhantes" (window:resize)="onResize($event)">
	<div *ngIf="imageObject.length > 0">
		<h4 class="titulo">Similares</h4>
		<mat-divider class="divider-margin"></mat-divider>
		<br>
		<ng-image-slider class="img-slider" [images]="imageObject" [infinite]="false" [animationSpeed]="0.4"
			[imageSize]="{width: '200px', height: '125px', space: 4}" [manageImageRatio]="true" [autoSlide]="false"
			[imagePopup]="false" [slideImage]="colunas" (imageClick)="onClickImagem($event)" #nav>
		</ng-image-slider>
	</div>
</div>