export class ItemPedido {
    public idItem = 0;
    public quantidade = 0;
    public quantidadeEstoque = 0;
    public preco = 0;
    public idTabelaPreco = 0;
    public vlPrecoTabelaNovo = 0;
    public vlPrecoTabelaAntigo = 0;
    public vlIpi = 0;
    public vlIcmsSt = 0;
    public total = 0;
    public flPromocao = false;
    public valorLiquido = 0;
    public valorLiquidoIpiSt = 0;
    public pcDesconto = 0;
    public pcMarkup = 0;
	public unMedVenda: string;
    public visualizouPrecoAntigo = false;

    constructor(json?: any) {
        if (json) {
            this.idItem = json.idItem || 0;
            this.quantidade = json.quantidade || 0;
            this.quantidadeEstoque = json.quantidadeEstoque;
            this.preco = json.preco;
            this.idTabelaPreco = json.idTabelaPreco;
            this.vlPrecoTabelaNovo = json.vlPrecoTabelaNovo;
            this.vlPrecoTabelaAntigo = json.vlPrecoTabelaAntigo;
            this.vlIpi = json.vlIpi;
            this.vlIcmsSt = json.vlIcmsSt;
            this.total = json.total;
            this.flPromocao = json.flPromocao;
            this.valorLiquido = json.valorLiquido;
            this.valorLiquidoIpiSt = json.valorLiquidoIpiSt;
            this.pcDesconto = json.pcDesconto;
            this.pcMarkup = json.pcMarkup;
            this.unMedVenda = json.unMedVenda;
            this.visualizouPrecoAntigo = json.visualizouPrecoAntigo;
        }  else {
            this.idItem = 0;
            this.quantidade = 0;
            this.quantidadeEstoque = 0;
            this.preco = 0;
            this.idTabelaPreco = 0;
            this.vlPrecoTabelaNovo = 0;
            this.vlPrecoTabelaAntigo = 0;
            this.vlIpi = 0;
            this.vlIcmsSt = 0;
            this.total = 0;
            this.flPromocao = false;
            this.valorLiquido = 0;
            this.valorLiquidoIpiSt = 0;
            this.pcDesconto = 0;
            this.pcMarkup = 0;
            this.unMedVenda = "";
            this.visualizouPrecoAntigo = false;
        }
    }
}



