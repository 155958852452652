<mat-card>
  <mat-toolbar color="primary">Meu Perfil</mat-toolbar>
  <mat-card-content>
    <form [formGroup]="formGroup">
      <mat-form-field class="full-width">
        <mat-label>Nome Usuário</mat-label>
        <input matInput placeholder="Nome Usuário" formControlName="nmUsuario"
        [(ngModel)]="funcionario.nmFuncionario" readonly>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>CNPJ / CPF</mat-label>
        <input matInput placeholder="CNPJ / CPF" formControlName="cnpjCpf"
        [(ngModel)]="funcionario.cnpjCpf" readonly>
      </mat-form-field>    
    </form>
  </mat-card-content>

  <mat-card-actions class="button-container">
    <button mat-raised-button color="primary" (click)="atualizarDadosDoUsuario()"  disabled="true">Atualizar Dados</button>
  </mat-card-actions>
</mat-card>