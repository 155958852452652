import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogCustomizado } from 'src/app/interfaces/idialog-customizado';

@Component({
  selector: 'app-dialog-customizado',
  templateUrl: './dialog-customizado.component.html',
  styleUrls: ['./dialog-customizado.component.css'],
})
export class DialogCustomizadoComponent {

  constructor(
    private dialogRef: MatDialogRef<DialogCustomizadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogCustomizado
    ) {}
}
