import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from './../../services/config.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { PedidoService } from 'src/app/services/pedido.service';
import { ItemDetalhes } from 'src/app/persistences/item-detalhes';
import { ItemManager } from 'src/app/managers/item-manager';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { JwtService } from 'src/app/jwt.service';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ItemDetalhadoComponent } from '../item-detalhado/item-detalhado.component';
import { Subject } from 'rxjs';
import { PesquisaService } from 'src/app/services/pesquisa.service';

@Component({
  selector: 'app-pedido-detalhado',
  templateUrl: './pedido-detalhado.component.html',
  styleUrls: ['./pedido-detalhado.component.css']
})
export class PedidoDetalhadoComponent implements OnInit{
  temaPadrao: string;
  pedido;
  isPesquisando: boolean;
  dataFormatada: string;
  item: ItemDetalhado;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
		@Inject(MAT_DIALOG_DATA) public idPedido,
		public dialogRef: MatDialogRef<PedidoDetalhadoComponent>,
    private configService: ConfigService,
    public dialog: MatDialog,
    private pedidoService: PedidoService,
    public itemManager: ItemManager,
    private cartSingletonService: CartSingletonService,
    private jwtService: JwtService,
    private snackBarManager: SnackbarManager,
    private pesquisaService: PesquisaService
  ){}

  ngOnInit(): void {
    this.isPesquisando = true;
    this.temaPadrao = this.configService.getConfig().getTemaPadrao;
    this.pedidoService.getPedidoVendaByIdPedido(this.idPedido.idPedido).subscribe((resultado)=>{
      this.pedido = resultado
      this.isPesquisando = false;
      this.dataFormatada = this.formatarData(this.pedido.dtEmissao);
    });
  }

  fechar() {
		this.dialogRef.close();
	}

  itensNoCarrinho(item: ItemDetalhes) {
    const itemDoCarrinho = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.find(
      (itemCarrinho) => itemCarrinho.itemDetalhes.idItem == item.idItem
    );
    if (itemDoCarrinho !== undefined) {
      this.item.itemPedido.idTabelaPreco = itemDoCarrinho.itemPedido.idTabelaPreco;
      this.item.itemPedido.unMedVenda = itemDoCarrinho.itemPedido.unMedVenda;      
      this.item.itemPedido.valorLiquido = itemDoCarrinho.itemPedido.valorLiquido;
      this.item.itemPedido.valorLiquidoIpiSt = itemDoCarrinho.itemPedido.valorLiquidoIpiSt;
      this.item.itemPedido.unMedVenda = itemDoCarrinho.itemPedido.unMedVenda;
      this.itemManager.getValor(this.item);
    }
  }

  openDialogDetalhes(item): void {
    this.pesquisaService
    .carregaItensByIds([item.idItem])
    .subscribe((res) => {
      const itemDetalhado = this.itemManager.converterItensParaItensSimples(res);
      this.itensNoCarrinho(itemDetalhado[0].itemDetalhado.itemDetalhes);
      const dictItem: Map<number, ItemDetalhado> = new Map();
      dictItem.set(item.idItem, new ItemDetalhado());
      const idItem = itemDetalhado[0].itemDetalhado.itemDetalhes.idItem;
      const dialogRef = this.dialog.open(ItemDetalhadoComponent, {
        width: '95%',
        height: '95%',
        data: {
          trilhaItens: dictItem,
          itemDetalhado: itemDetalhado[0].itemDetalhado,
          id: idItem,
        },
      });
  
      dialogRef.afterClosed().subscribe(
        () => {
          itemDetalhado[0].itemDetalhado.itemDetalhes.idItem = idItem;
        },
        (error) => {
          this.jwtService.validarAcessToken(error);
          this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
        }
      );

    });
  }

  formatarData(data: string){
    const dataFormatada = data.split(" ");
    return dataFormatada[0];
  }
}
