import { Component, OnInit } from '@angular/core';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'business';
  temaPadrao: string = this.configService.getConfig().getTemaPadrao;

  constructor(
    private configService: ConfigService
  ){ }

  ngOnInit(): void {         
    this.temaPadrao = this.configService.getConfig().getTemaPadrao;
  }
}
