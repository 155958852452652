export class VendaPerdida {
	public idPedidoVenda: number;
	public idItem: number;
	public qtItem: number;
	public cdMotivo: number;
	public idItemSubstituido: number;
	public unMedida: string;
	public cdCliente: number;
	public nmMotivo: string;
	public flOrigemCorte: string;
	public cdUsuario: number;
	public idTabelaPrecoProduto: number;
	public vlVenda: number;
	public cdFilial: number;
	public dtLancamento: string;
}