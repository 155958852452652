<div color="primary">
  <div>
    <button mat-button [matMenuTriggerFor]="minhaConta">
      <div class="two-lines">
        <div class="line">
          <mat-icon class="icone">account_circle</mat-icon>
        </div>
        <div *ngIf="!isMobileMenu">Olá, {{nomeUsuario}}</div>
      </div>
    </button>
    <mat-menu #minhaConta="matMenu">
      <div>        
        <button mat-menu-item style="background-color: white" [routerLink]="routerLink"><b>Minha Conta</b></button>
        <mat-divider></mat-divider>
        <div *ngIf="isCliente">
          <button mat-menu-item style="background-color: white" routerLink="/minha-conta/meus-pedidos"><b>Meus
              Pedidos</b></button>
              <mat-divider></mat-divider>
        </div>
        <div *ngIf="isUsuario && isMobileMenu">
          <button mat-menu-item style="background-color: white" routerLink="/minha-conta/painel-cadastro"><b>Cadastro de
              Paineis</b></button>
        </div>
        <div *ngIf="isCliente">
          <button mat-menu-item style="background-color: white" routerLink="/minha-conta/meus-titulos"><b>Meus títulos</b></button>
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="isMobileMenu">
          <mat-divider></mat-divider>
          <button mat-menu-item style="background-color: white" routerLink="/minha-conta/alterar-senha"><b>Alterar
              Senha</b></button>
        </div>
        <button mat-menu-item style="background-color: white" (click)="logout()"><b
            style="color: red;">Sair</b></button>
      </div>
    </mat-menu>
  </div>
</div>