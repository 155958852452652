import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { PedidoVenda } from 'src/app/persistences/pedido-venda';
import { PedidoFlex } from '../persistences/pedido-flex';
import { PedidoItemFlex } from '../persistences/pedido-item-flex';
import { ConfigService } from '../services/config.service';
import { CartSingletonService } from '../singletons/cart-singleton.service';
import { ClienteSingletonService } from '../singletons/cliente-singleton.service';
import { NumberUtils } from '../utils/utils/number-utils';
import { Carrinho } from '../persistences/carrinho';
import { Config } from '../persistences/config';
import { Cliente } from '../persistences/cliente';

@Injectable({
    providedIn: 'root',
})

export class ConverterCarrinhoManager {

    public pedidoVenda: PedidoVenda;

    constructor(
        private cartSingletonService: CartSingletonService,
        private configService: ConfigService,
        private clienteSingleton: ClienteSingletonService,
    ) { }

    converterCarrinhoParaPedido(
        pedidoCotacao: boolean,
        obs: string,
        notaFiscal: boolean
    ): PedidoFlex {
        const pedidoFlex: PedidoFlex = new PedidoFlex();
        const pedidoItensFlex: PedidoItemFlex[] = [];
        const pedidoVenda: PedidoVenda = this.cartSingletonService.getPedidoVenda();
        const carrinho: Carrinho = this.cartSingletonService.getCarrinhoEstatico();
        const config: Config = this.configService.getConfig();
        const cliente: Cliente = this.clienteSingleton.getClienteEstatico();

        let versaoRegistro = pedidoVenda.getVersaoRegistro;

        if (
            pedidoVenda.getIdGeral !== undefined ||
            pedidoVenda.getIdGeral !== 0
        ) {
            pedidoFlex.idGeral = pedidoVenda.getIdGeral;
        }

        pedidoFlex.tpFrete = carrinho.getTpFrete;
        pedidoFlex.tpDocFatura = cliente.getTpDocFatura;
        pedidoFlex.cdFilial = cliente.getClienteFilial.getCdFilial;
        pedidoFlex.cdCliente = cliente.getCdCliente;

        pedidoFlex.cdVendedor = null;
        if (cliente.getCdFuncionario > 0) {
            pedidoFlex.cdVendedor = cliente.getCdFuncionario;
        }

        pedidoFlex.cdSituacao =
            pedidoCotacao == true ? 10 : config.getCdSituacao;
        pedidoFlex.cdFormaPgto = carrinho.getCdFormaPgto;
        pedidoFlex.cdCondPgto = carrinho.getCdCondPgto;
        pedidoFlex.cdTpEntrega = carrinho.getCdTpEntrega;
        pedidoFlex.cdTransportador = carrinho.getCdTransportador;
        pedidoFlex.vlTotal = carrinho.getTotal;
        pedidoFlex.vlFrete = carrinho.getFrete;
        pedidoFlex.vlDesconto = 0;
        pedidoFlex.vlOutrasDespesas = 0;
        pedidoFlex.dtPrevisaoEnvio = formatDate(
            new Date(),
            'dd/MM/yyyy hh:mm:ss',
            'en-US'
        );
        pedidoFlex.dtEmissao = formatDate(
            new Date(),
            'dd/MM/yyyy hh:mm:ss',
            'en-US'
        );
        if (notaFiscal) pedidoFlex.ObservacoesNota = obs;
        else pedidoFlex.ObservacoesInterna = obs;

        if (versaoRegistro == undefined) {
            versaoRegistro = 0;
        }
        
        ++versaoRegistro; 
        this.cartSingletonService.atualizarControlePedido(pedidoVenda.getIdGeral, versaoRegistro);
        pedidoFlex.versaoRegistro = versaoRegistro;

        carrinho.getItensCarrinho.forEach((item) => {
            const pedidoItemFlex = new PedidoItemFlex();

            pedidoItemFlex.idGeral = pedidoVenda.getIdGeral;
            pedidoItemFlex.idItem = item.itemDetalhes.idItem;
            pedidoItemFlex.qtVenda = item.itemPedido.quantidade;
            pedidoItemFlex.unVenda = item.itemPedido.unMedVenda;
            pedidoItemFlex.vlBruto = item.itemPedido.preco;
            pedidoItemFlex.pcDesconto = item.itemPedido.pcDesconto;
            pedidoItemFlex.idTabelaPrecoProduto = item.itemPedido.idTabelaPreco;
            if (item.itemPedido.visualizouPrecoAntigo)
                item.itemPedido.vlPrecoTabelaAntigo = NumberUtils.roundTo(
                    item.itemPedido.preco *
                    (1 + this.clienteSingleton.getClienteEstatico().getPcMarkup / 100) *
                    (1 - item.itemPedido.pcDesconto / 100),
                    2
                );
            pedidoItensFlex.push(pedidoItemFlex);
        });
        pedidoFlex.itens = pedidoItensFlex;
        return pedidoFlex;
    }
}