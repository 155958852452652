export class Preco {
    public idGeral?: number;
    public nmTabela?: string;
    public cdTabelaPreco?: number;
    public preco?: number;
    public pcMaxDescontoAut?: number;
    public pcMaxAcrescimoAut?: number;
    public pcMaxDescontoFlex?: number;
    public qtMinima?: number;
    public flPromocao?: boolean;
    public pcMarckupEcommerce?: number;
    public precoLiquido?: number;
    public precoLiquidoComIpiIcmsSubstituicao?: number;
    public unMedida: string;
    public fatorConversao?: number;    
    public vlIcmsST?: number;
    public vlIPI?: number;
    public vlIcms?: number;    

    constructor(json?: any) {
        if (json) {            
            this.idGeral = json.idGeral;
            this.nmTabela = json.nmTabela;
            this.cdTabelaPreco = json.cdTabelaPreco;
            this.preco = json.preco;
            this.pcMaxDescontoAut = json.pcMaxDescontoAut;
            this.pcMaxAcrescimoAut = json.pcMaxAcrescimoAut;
            this.pcMaxDescontoFlex = json.pcMaxDescontoFlex;
            this.qtMinima = json.qtMinima;
            this.flPromocao = json.flPromocao;
            this.pcMarckupEcommerce = json.pcMarckupEcommerce;
            this.precoLiquido = json.precoLiquido;
            this.precoLiquidoComIpiIcmsSubstituicao = json.precoLiquidoComIpiIcmsSubstituicao;
            this.unMedida = json.unMedida;
            this.fatorConversao = json.fatorConversao;
            this.vlIcmsST = json.vlIcmsST;
            this.vlIPI = json.vlIPI;
            this.vlIcms = json.vlIcms;
        } 
    }
}
