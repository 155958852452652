import {
  AfterViewInit, Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtService } from 'src/app/jwt.service';
import { ItemManager } from 'src/app/managers/item-manager';
import { SnackbarManager } from 'src/app/managers/snackbar-manager';
import { ItemDetalhado } from 'src/app/persistences/item-detalhado';
import { ItemDetalhes } from 'src/app/persistences/item-detalhes';
import { ItemPedido } from 'src/app/persistences/item-pedido';
import { ItemSimplesView } from 'src/app/persistences/item-simples-view';
import { PcMarkupEcommerce } from 'src/app/persistences/pc-markup-ecommerce';
import { CartSingletonService } from 'src/app/singletons/cart-singleton.service';
import { ClienteSingletonService } from 'src/app/singletons/cliente-singleton.service';
import { NumberUtils } from '../../utils/utils/number-utils';
import { ItemDetalhadoComponent } from '../item-detalhado/item-detalhado.component';
import { ListaPrecoComponent } from '../lista-preco/lista-preco.component';

@Component({
  selector: 'app-card-item-pesquisa',
  templateUrl: './card-item-pesquisa.component.html',
  styleUrls: ['./card-item-pesquisa.component.css'],
})
export class CardItemPesquisaComponent implements AfterViewInit {
  @Input()
  public itemPesquisa: ItemSimplesView;
  @Input()
  public quantidadeItem;
  @Input()
  public valorTotalItem: number;
  @Input()
  public valorLiquido;
  @Input()
  public fichaTecnica;
  @Input()
  public item: ItemDetalhado;
  @Input()
  public temEstoque: boolean;
  @Input()
  public temSimilares: boolean;
  @Input()
  public itemPedido: ItemPedido;
  public msgadd: number;
  public novaLista = false;
  public promocao = false;
  @Input()
  public exibeDesconto: boolean;
  @Input()
  public exibeEstoque: boolean;
  public markup: PcMarkupEcommerce;
  @Output()
  public addItemCarrinho = new EventEmitter<ItemDetalhado>();
  @Output()
  public deleteItemCarrinho = new EventEmitter<ItemDetalhado>();

  public exibe = false;

  public temaPadrao: string;

  constructor(
    public dialog: MatDialog,
    public itemManager: ItemManager,
    private cartSingletonService: CartSingletonService,
    private clienteSingleton: ClienteSingletonService,
    private jwtService: JwtService,
    private snackBarManager: SnackbarManager
  ) {}

  ngAfterViewInit() {
    (this.itemPesquisa.pcDesconto == this.itemPesquisa.pcDesconto) == undefined
      ? 0
      : this.itemPesquisa.pcDesconto;
    setTimeout(() => {
      this.exibe =
        (this.itemPesquisa.pcDesconto <= 0 &&
          this.itemPesquisa.pcDesconto == undefined) ||
        !this.temEstoque ||
        this.itemPesquisa.preco <= 0;
      this.itensNoCarrinho(this.item.itemDetalhes);
    }, 1000);
  }

  imgClick() {
    this.openDialogDetalhes();
  }

  itensNoCarrinho(item: ItemDetalhes) {
    const itemCarrinho = this.cartSingletonService.getCarrinhoEstatico().getItensCarrinho.find(
      (itemCart) => itemCart.itemDetalhes.idItem == item.idItem
    );
    if (itemCarrinho !== undefined) {
      this.quantidadeItem = itemCarrinho.itemPedido.quantidade;
      this.valorTotalItem = itemCarrinho.itemPedido.total;
      this.item.itemPedido.total = NumberUtils.roundTo(this.valorTotalItem, 2);
      this.item.itemPedido.quantidade = this.quantidadeItem;
      this.itemManager.getValor(this.item);
    }
  }

  openDialogDetalhes(): void {
    const dictItem: Map<number, ItemDetalhado> = new Map();
    dictItem.set(this.itemPesquisa.idItem, new ItemDetalhado());
    const idItem = this.item.itemDetalhes.idItem;
    const dialogRef = this.dialog.open(ItemDetalhadoComponent, {
      width: '95%',
      height: '95%',
      data: {
        trilhaItens: dictItem,
        itemDetalhado: this.item,
        id: idItem,
      },
    });

    dialogRef.componentInstance.addItem.subscribe(
      (item: ItemDetalhado) => {
        this.addItemCarrinho.emit(item);
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
      }
    );

    dialogRef.afterClosed().subscribe(
      () => {
        this.item.itemDetalhes.idItem = idItem;
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
      }
    );
  }

  listaNova() {
    this.novaLista = true;
  }

  receberQuantidadeInput(quantidade: number) {
    console.trace();
    this.item.itemPedido.quantidade = quantidade;
    this.itemManager.atualizarQuantidadeItem(this.item);
  }

  listarPrecosDoItem(): void {
    const dialogRef = this.dialog.open(ListaPrecoComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        itemDetalhado: this.item,
      },
    });

    dialogRef.afterClosed().subscribe(
      (tabelaPreco) => {
        if (tabelaPreco !== null) {
          this.atualizarPrecoItem(this.itemManager.selecionarTabelaDePrecoDoItem(tabelaPreco, this.item));
          this.itemManager.atualizarTabelaDePrecoDoItem(this.item);
        }
      },
      (error) => {
        this.jwtService.validarAcessToken(error);
        this.snackBarManager.openSnackBar(error.error, 'Ok', 3000);
      }
    );
  }

  atualizarPrecoItem(itemPedido: ItemPedido) {    
    this.itemPesquisa.itemDetalhado.itemPedido.unMedVenda = itemPedido.unMedVenda;
    this.itemPesquisa.itemDetalhado.itemPedido.idTabelaPreco = itemPedido.idTabelaPreco;
    this.itemPesquisa.idTabelaPrecoProduto = itemPedido.idTabelaPreco;
    this.itemPesquisa.itemDetalhado.itemPedido.idTabelaPreco = itemPedido.idTabelaPreco;
    this.itemPesquisa.itemDetalhado.itemPedido.quantidade = itemPedido.quantidade !== 0 ? itemPedido.quantidade : 1;
    this.itemPesquisa.itemDetalhado.itemPedido.vlPrecoTabelaAntigo = this.itemPesquisa.itemDetalhado.itemPedido.preco;
    this.itemPesquisa.itemDetalhado.itemPedido.preco = itemPedido.preco;
    this.itemPesquisa.itemDetalhado.itemPedido.valorLiquido = itemPedido.valorLiquido;
    this.itemPesquisa.itemDetalhado.itemPedido.valorLiquidoIpiSt = itemPedido.valorLiquidoIpiSt;
    this.itemPesquisa.itemDetalhado.itemPedido.vlPrecoTabelaNovo = itemPedido.preco;
    this.itemPesquisa.itemDetalhado.itemPedido.total = this.itemPesquisa.itemDetalhado.itemPedido.valorLiquidoIpiSt * this.itemPesquisa.itemDetalhado.itemPedido.quantidade;

    this.quantidadeItem = this.itemPesquisa.itemDetalhado.itemPedido.quantidade;
    this.valorTotalItem = this.itemPesquisa.itemDetalhado.itemPedido.total;
  }

  outrosPrecosDisponivel(): boolean {        
    const result = this.itemManager.removerTabelasDePrecoDuplicas(this.itemPesquisa.itemDetalhado);
    return result === null || result.length <= 1; 
  } 
}
